import React, {useEffect, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {LoadingOutlined} from '@ant-design/icons';
import pageLogo from "../../assets/images/page-logo.svg";
import pageLogoWhite from "../../assets/images/logo-dark.svg";
import {Button, Modal, Radio, Spin} from 'antd';
import {useAuth} from '../../context/AuthContext';
import SettingHeader from "../../components/Setting/SettingHeader";
import amoveIcon from "../../assets/images/icons/checkout/amove-logo-icon.svg";
import performIcon from "../../assets/images/icons/checkout/perform-icon.svg";
import globalIcon from "../../assets/images/icons/checkout/global-icon.svg";
import scaleIcon from "../../assets/images/icons/checkout/scale-icon.svg";
import cloudIcon from "../../assets/images/icons/checkout/cloud-connections.svg";
import usersIcon from "../../assets/images/icons/checkout/users.svg";
import powerIcon from "../../assets/images/icons/checkout/power-user-icon.svg";
import standardIcon from "../../assets/images/icons/checkout/standard-user-icon.svg";

const QuoteStatus = () => {
    const navigate = useNavigate();
    const {
        isDark,
    } = useAuth();
    const [changeSubscriptionModal, setChangeSubscriptionModal] = useState(false);


    return (
        <>
            <div id="main-content">
                <div className="container-fluid">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                        {/*<div className="login billingStatus email-verification">*/}
                        <div className="no-fixed billingStatus email-verification">
                            <div className="sec-checkout-payment setting-checkout">
                                <div className="setting-checkout-wrapper">
                                    <div className="cart-wrapper" id="amove-invoice">
                                        <div className="users-count">
                                            <span className="quote-number d-none">Quote# Amove-1051968_112024</span>
                                            <ul className="users-count grid-icon grid-4">
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={amoveIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title plan-title">Click For BackUp</span>
                                                            <span className="small-text" id="small-text">
                                                                    12 Admin Users, 12 Cloud Connections, 11TB Of Global Storage, 13TB Of Scale Storage
                                        </span>
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={performIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Amove Perform Storage</span>
                                                            <span className="perform-count">1</span>TB of Perform Cloud Storage
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={globalIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Amove Global Storage</span>
                                                            <span className="global-count">2</span>TB of Global Cloud Storage
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={scaleIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Amove Scale Storage</span>
                                                            <span className="scale-count">1</span>TB of Scale Cloud Storage
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li className="connection-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={cloudIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Connections</span>
                                                            <span className="connection-count">2</span> Connections to Any Cloud or Local Storage
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li className="admin-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={usersIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Admin User</span>
                                                            <span className="admin-count">4</span> Admin Users
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li className="power-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={powerIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Power User</span>
                                                            <span className="power-count">2</span> Power Users
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                                <li className="standard-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                         <img src={standardIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Standard User</span>
                                                            <span className="standard-count">2</span> Standard Users
                                                        </p>
                                                    </div>
                                                    <strong className="start-date">Start Date: Tue Aug 27 2024 <br/>
                                                        Next Payment Date: Wed Aug 27 2025</strong>
                                                    <strong className="status">Paid</strong>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style orange-btn">Unsubscribe</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="discount-div ignore d-none">
                                        <input type="text" placeholder="Discount or Partner Code" className="form-control"/>
                                            <button className="btn-style" id="apply-discount">Apply</button>
                                        </div>
                                        <div className="sub-total">
                                            <div className="subtotal-wrapper">
                                                <span className="small">Subtotal</span>
                                                <span className="small subtotal-price"></span>
                                            </div>
                                            <div className="subtotal-wrapper">
                                                <span className="small">Discount</span>
                                                <span className="small discount-price"></span>
                                            </div>
                                        </div>

                                        <div className="total-wrapper d-none">
                                            <h3 className="small">Total</h3>
                                            <div>
                                                <h3 className="small total-price"></h3>
                                                <span className="plan-subscription"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="strip-form d-none">
                                        <div className="form">

                                            <div className="form-group submit">
                                                <ul className="btn-list">
                                                    <li><a href="#" className="btn-style">Pay Now</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default QuoteStatus
