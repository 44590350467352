//Live
/*  "MonthlyPlanId": "price_1OLqEEFIS02f0YTqR9Cto5lH",
      "YearlyPlanId": "price_1OLqEaFIS02f0YTqVYnH0A4u"
      GoogleClientId: "275561682044-2h2h4rhmkml51i2pglthorasljkuv9tr.apps.googleusercontent.com"


      */

//App 2
/*
Amove Everywhere
$18/monthly/user  =>  price_1OXTfaGjtdaDcihGh4EBIc1S
$12/annual/user ($144 paid upfront)  =>  price_1OXTg7GjtdaDcihGNkH53yZU
Amove Premium
$30/monthly/user   => price_1OXTgmGjtdaDcihGwvfd6jSR
$20/annual/user ($240 paid upfront)   =>  price_1OXTh8GjtdaDcihGUPdsAYnd
*/


/*
ZAP URL Dev = https://hooks.zapier.com/hooks/catch/14342253/3q88hy3/?
ZAP URL POC = https://hooks.zapier.com/hooks/catch/14342253/3qznzg3/?

environment: "Dev"
environment: "POC"
environment: "Prod"


callbackURL: "https://dev.amove.io",
callbackURL: "http://localhost:3000",



https://dev-api.amove.io/api/sso/saml_acs
Single sign-on URL
The location where the SAML assertion is sent with a HTTP POST. This is often referred to as the SAML Assertion Consumer Service (ACS) URL for your application.

https://dev.amove.io/Saml2
Audience URI (SP Entity ID)
The application-defined unique identifier that is the intended audience of the SAML assertion. This is most often the SP Entity ID of your application.

    callbackURL: "https://dev.amove.io",
* */
/*const config = {
    apiURL: "http://localhost:29123",
    apiURLWeb: "https://dev-api.amove.io",
    auth: "https://dev-auth.amove.io",
    aiSearchBaseURL: "https://2om5th367a.execute-api.us-east-1.amazonaws.com/amove/api/v1",
    callbackURL: "http://localhost:3000",
    EverywhereMonthlyPlanId: "price_1OXTfaGjtdaDcihGh4EBIc1S",
    EverywhereYearlyPlanId: "price_1OXTg7GjtdaDcihGNkH53yZU",
    PremiumMonthlyPlanId: "price_1OXTgmGjtdaDcihGwvfd6jSR",
    PremiumYearlyPlanId: "price_1OXTh8GjtdaDcihGUPdsAYnd",
    GoogleClientId: "275561682044-2h2h4rhmkml51i2pglthorasljkuv9tr.apps.googleusercontent.com",
    sizeLimitForAmoveDrive: 25, // drive limit in GB default 25
    reTryCheckLimitForAmoveDrive: 900000, // retry time in seconds 900000 for 15 minutes
    zapURL: "https://hooks.zapier.com/hooks/catch/14342253/3q88hy3/?",
    zapURLActivate: "https://hooks.zapier.com/hooks/catch/14342253/2t3wfeo/?",
    SSOURL: "https://dev-api.amove.io/api/sso/saml_acs",
    SPEntityID: "https://dev.amove.io/Saml2",
    showChangeLocalCacheButton:true,
    environment: "Dev"
}*/
// const config = {
//     apiURL: "http://localhost:29123",
//     apiURLWeb: "https://stg-api.amove.io",
//     auth: "https://stg-auth.amove.io",
//     aiSearchBaseURL: "https://2om5th367a.execute-api.us-east-1.amazonaws.com/amove/api/v1",
//     callbackURL: "https://stg.amove.io",
//     EverywhereMonthlyPlanId: "price_1OXTfaGjtdaDcihGh4EBIc1S",
//     EverywhereYearlyPlanId: "price_1OXTg7GjtdaDcihGNkH53yZU",
//     PremiumMonthlyPlanId: "price_1OXTgmGjtdaDcihGwvfd6jSR",
//     PremiumYearlyPlanId: "price_1OXTh8GjtdaDcihGUPdsAYnd",
//     GoogleClientId: "275561682044-2h2h4rhmkml51i2pglthorasljkuv9tr.apps.googleusercontent.com",
//     sizeLimitForAmoveDrive: 25, // drive limit in GB default 25
//     reTryCheckLimitForAmoveDrive: 900000, // retry time in seconds 900000 for 15 minutes
//     zapURL: "https://hooks.zapier.com/hooks/catch/14342253/3q88hy3/?",
//     zapURLActivate: "https://hooks.zapier.com/hooks/catch/14342253/2t3wfeo/?",
//     SSOURL: "https://stg-api.amove.io/api/sso/saml_acs",
//     SPEntityID: "https://stg.amove.io/Saml2",
//     showChangeLocalCacheButton:true,
//     environment: "STG"
// }

const config = {
    apiURL: "http://localhost:29123",
    apiURLWeb: "https://api.amove.io",
    auth: "https://auth.amove.io",
    aiSearchBaseURL: "https://2om5th367a.execute-api.us-east-1.amazonaws.com/amove/api/v1",
    callbackURL: "https://app.amove.io",
    EverywhereMonthlyPlanId: "price_1P081UFIS02f0YTqezZMDYAt",
    EverywhereYearlyPlanId: "price_1P080jFIS02f0YTqMBvufotb",
    PremiumMonthlyPlanId: "price_1Ob9BLFIS02f0YTqKDFzWRDH",
    PremiumYearlyPlanId: "price_1Ob9BLFIS02f0YTqUkbSqq6G",
    GoogleClientId: "275561682044-2h2h4rhmkml51i2pglthorasljkuv9tr.apps.googleusercontent.com",
    sizeLimitForAmoveDrive: 25, // drive limit in GB default 25
    reTryCheckLimitForAmoveDrive: 900000, // retry time in seconds 900000 for 15 minutes
    zapURL: "https://hooks.zapier.com/hooks/catch/14342253/3q88hy3/?",
    zapURLActivate: "https://hooks.zapier.com/hooks/catch/14342253/2t3wfeo/?",
    SSOURL: "https://api.amove.io/api/sso/saml_acs",
    SPEntityID: "https://app.amove.io/Saml2",
    showChangeLocalCacheButton:false,
    environment: "Prod"
}


export default config;
