import {React, useEffect, useState, useRef} from 'react';
import {Navigate, useSearchParams, useNavigate} from "react-router-dom";
import {Button, Dropdown, Modal, Radio, Select, Tooltip, notification, Spin, Switch} from "antd";
import objectBucketIcon from "../assets/images/icons/object-bucket-icon.svg";
import objectCircleIcon from "../assets/images/icons/object-usage-icon.svg";
import objectFolderIcon from "../assets/images/icons/object-folder-icon.svg";
import usFlagIcon from "../assets/images/icons/us-flag-icon.svg";
import copyIcon from "../assets/images/icons/copy-white-icon.svg";
import trashIcon from "../assets/images/icons/trash-white-icon.svg";
import settingIcon from "../assets/images/icons/setting-white-icon.svg";
import {useAuth} from "../context/AuthContext";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {LoadingOutlined} from "@ant-design/icons";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import pageLogo from "../assets/images/logo.svg";
import caFlag from "../assets/images/flags/tn_ca-flag.gif";
import eiFlag from "../assets/images/flags/tn_ei-flag.gif";
import frFlag from "../assets/images/flags/tn_fr-flag.gif";
import gmFlag from "../assets/images/flags/tn_gm-flag.gif";
import ukFlag from "../assets/images/flags/tn_uk-flag.gif";
import usFlag from "../assets/images/flags/tn_us-flag.gif";
import snFlag from "../assets/images/flags/tn_sn-flag.gif";
import logoIcon from "../assets/images/icons/logo-icon.svg";
import bucketModalIcon from "../assets/images/icons/bucket-modal-img.svg";
import bucketModalIconBlack from "../assets/images/icons/bucket-modal-img-black.svg";
import horizontalDotsIcon from "../assets/images/icons/horizontal-dots-icon.svg";
import {tr} from "react-date-range/dist/locale";
import ProvisionAmoveStorage from "../components/ProvisionAmoveStorage"

const {Option} = Select;
const StorageDashboard = () => {
    const {
        cloudAccounts,
        deleteDataWeb,
        setEndpoint,
        setShowPopupSupport,
        setPopup500,
        setError499,
        setPopup499,
        getDataWithTokenPOST,
        isDark,
        getDataWithToken,
        setLoading,
        loading,
        logout,
        getCloudAccounts,
        regions,
        getRegions,
        cloudAccountsDone,
        prettySize,
        limitExceeded,
        sendDataToMixPanel,
        user,
        userStatus,
        token,
        limitExceed,
        updateDataWithToken,
        setCloudAccounts
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const dataFetchedRef1 = useRef(false);
    const [api, contextHolder] = notification.useNotification();
    const [regionsFilter, setRegionsFilter] = useState([]);
    const [storageFilter, setStorageFilter] = useState([]);
    const [amoveStorage, setAmoveStorage] = useState([]);
    const [amoveStorageFiltered, setAmoveStorageFiltered] = useState([]);
    const [selectedRegionsFilter, setSelectedRegionsFilter] = useState();
    const [selectedStorageFilter, setSelectedStorageFilter] = useState();
    const [selectedRegions, setSelectedRegions] = useState();
    const [selectedProvision, setSelectedProvision] = useState();
    const [createProvision, setCreateProvision] = useState(false);
    const [createRegion, setCreateRegion] = useState(false);
    const [totalBuckets, setTotalBuckets] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [deleteRegion, setDeleteRegion] = useState(false);
    const [regionToDelete, setRegionToDelete] = useState({});
    const [loadingAmove, setLoadingAmove] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showPageData, setShowPageData] = useState(false);
    const [iDriveUsage, setIDriveUsage] = useState(0);
    const [iDriveUsageAll, setIDriveUsageAll] = useState(0);
    const [toggleVersioning, setToggleVersioning] = useState(false);
    const [toggleDefaultEncryption, setToggleDefaultEncryption] = useState(false);
    const [toggleObjectLocking, setToggleObjectLocking] = useState(false);
    const [newBucketName, setNewBucketName] = useState("");
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const [optionsRegions, setOptionsRegions] = useState([]);

    useEffect(() => {
        if (limitExceeded) {
            navigate("/subscribe");
        }
    }, [limitExceeded]);
    const getTierName = (value) => {
        if(value === 1){
            return "Perform"
        }
        if(value === 2){
            return "Global"
        }
        if(value === 4){
            return "Scale"
        }
    };

    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);

    useEffect(() => {
        if (token !== undefined && token !== null && userStatus.status !== undefined) {
            if (dataFetchedRef1.current) return;
            dataFetchedRef1.current = true;
            getRegions();
            getCloudAccounts();
        }
    }, [token, userStatus]);
    const idrive_get_daily_usage = (tier, setAll = false) => {
        getDataWithToken(`/api/cloudaccount/idrive_get_daily_usage?storageTier=${tier}`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_get_daily_usage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoadingAmove(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoadingAmove(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(response)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                } else {
                    setIDriveUsage(response)
                    if(setAll){
                        setIDriveUsageAll(response)
                    }
                }
            })
    };

    useEffect(() => {
        setTimeout(function () {
            if (cloudAccountsDone) {
                setShowPageData(true)
            }
        }, 1500)
    }, [cloudAccountsDone]);
    useEffect(() => {
        console.log(cloudAccounts)
        if (cloudAccounts.length > 0) {
            setAmoveStorage(cloudAccounts.sort((a, b) => a.storageTier > b.storageTier ? 1 : -1).filter((item) => (item.internalStorage)))
        } else {
            setAmoveStorage([])
            setAmoveStorageFiltered([])
        }
    }, [cloudAccounts]);


    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        getRegions();
        idrive_get_daily_usage(0,true)
    }, []);
    useEffect(() => {
        if (amoveStorage.length > 0) {
            var tBuckets = 0;
            var regionName = [{
                label: "All Regions",
                value: "all",
            }];
            amoveStorage.map((item) => {
                tBuckets += item.buckets.length;
                if (!regionName.some(obj => obj.label === item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)))) {
                    regionName.push({
                        label: item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)),
                        value: item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)),
                    })
                }
            })
            var storages = [{
                label: "All Storage Tiers",
                value: "all",
            }];
            amoveStorage.map((item) => {
                if (!storages.some(obj => obj.value === item.storageTier)) {
                    storages.push({
                        label: item.name.substring(item.name.indexOf("-")+1, item.name.indexOf("(")),
                        value: item.storageTier,
                    })
                }
            })
            setTotalBuckets(tBuckets)
            setRegionsFilter(regionName)
            setStorageFilter(storages)
            setAmoveStorageFiltered(amoveStorage)
            console.log(amoveStorage)

        } else {
            setAmoveStorageFiltered([])
        }
    }, [amoveStorage]);
    const regionDeleteCancel = () => {
        setDeleteRegion(false);
        setRegionToDelete({})
    };
    const regionDeleteOpen = () => {
        setDeleteRegion(true);
    };
    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };
    const onChangeProvisions = (value) => {
        setSelectedProvision(value);
        setSelectedRegions();
        setToggleVersioning(false)
        setToggleDefaultEncryption(false)
        setToggleObjectLocking(false)

        console.log(value)
        var filtered = []
        amoveStorage.map((item) => {
            if (item.storageTier === parseInt(value)) {
                filtered.push(item.credentialsData)
            }
        })
        setOptionsRegions(filtered)
    };

    const manageRegionCancel = () => {
        setCreateRegion(false)
    };


    const onChangeRegoinFilter = (value) => {
        setSelectedStorageFilter();
        setSelectedRegionsFilter(value);
        console.log(value)
        if (value !== "all") {
            var totalBuckets1 = 0
            var filtered = []
            amoveStorage.map((item) => {
                console.log(item)
                console.log(item.name.indexOf(value))
                if (item.name.indexOf(value) > -1) {
                    totalBuckets1 += item.buckets.length
                    filtered.push(item)
                }
            })
            setTotalBuckets(totalBuckets1);
            setAmoveStorageFiltered(filtered)
        } else {
            var tBuckets = 0;
            amoveStorage.map((item) => {
                tBuckets += item.buckets.length;
            })
            setTotalBuckets(tBuckets)
            setAmoveStorageFiltered(amoveStorage)
        }
    };
    const onChangeStorageFilter = (value) => {

        setSelectedRegionsFilter();
        setSelectedStorageFilter(value);
        console.log(value)
        if (value !== "all") {
            var totalBuckets1 = 0
            var filtered = []
            amoveStorage.map((item) => {
                if (item.storageTier === value) {
                    totalBuckets1 += item.buckets.length
                    filtered.push(item)
                }
            })
            setTotalBuckets(totalBuckets1);
            setAmoveStorageFiltered(filtered)

            idrive_get_daily_usage(value)
        } else {
            var tBuckets = 0;
            amoveStorage.map((item) => {
                tBuckets += item.buckets.length;
            })
            setTotalBuckets(tBuckets)
            setAmoveStorageFiltered(amoveStorage)
            idrive_get_daily_usage(0)
        }
    };

    function deleteAmoveRegion(item) {
        var id = item.id;
        deleteDataWeb('/api/cloudaccount/idrive_delete_storage?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_delete_storage")
                    setShowPopupSupport(true)
                    //  logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    let newArr = [...amoveStorageFiltered];
                    newArr = newArr.filter((i) => i.id !== id);
                    console.log(newArr)
                    setAmoveStorageFiltered(newArr);
                    var tBuckets = 0;
                    newArr.map((item) => {
                        tBuckets += item.buckets.length;
                    })
                    setTotalBuckets(tBuckets)
                    setDeleteRegion(false);
                    setRegionToDelete({})
                    api.open({
                        message: 'Amove storage region removed successfully.',
                        description: `Your Amove storage region has been removed.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });
                    getCloudAccounts(false, false, false)
                }
            });

    }

    const setUpNewIDrive = () => {

        var dataToPost =
            {
                "firstname": "",
                "userEmail": "",
                "cloudAccountName": "Amove",
                "bucketTitle": "amovebucket",
                "region": selectedRegions,
                "defaultPassword": "",
                "shared": false,
                "storageTier": parseInt(selectedProvision)
            }
        setLoading(true)
        getDataWithTokenPOST("/api/cloudaccount/idrive_add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoading(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    setLoading(false);
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    sendDataToMixPanel('New Amove Storage Created.', user.username, {
                        'App': 'Web App'
                    })
                    setLoading(false);
                    setSelectedProvision();
                    setSelectedRegions();
                    setCreateRegion(false)
                    getCloudAccounts(false, false, false)
                }
            })

    };


    function actionSettingUpdate(e, data) {
        var dataToPost = {
            "id": data.id,
            "accountId": data.accountId,
            "userId": data.userId,
            "cloudType": data.cloudType,
            "name": data.name,
            "accessKey": data.accessKey,
            "secretKey": data.secretKey,
            "credentialsData": data.credentialsData,
            "serviceUrl": data.serviceUrl,
            "active": data.active,
            "shared": e,
            "internalStorage": data.internalStorage,
            "storageTier": data.storageTier,
            "deleted": data.deleted
        }
        updateDataWithToken("/api/v1/cloudaccount/manage_share", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, res]) => {
                console.log(res)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = res.type;
                    setError499(res.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: e ? data.name + " is now set to Public. Other users can access it." : data.name + " is now set to Private. Only you can access it.",
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                    let newArr = [...cloudAccounts];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id == data.id) {
                            newArr[i].shared = e;
                        }
                    }
                    setCloudAccounts(newArr)
                }
            });
    }
    return (
        <>
            {contextHolder}
            <div id="main-content">
                <div className="container-fluid">
                    <div className="storage-dashboard-main storage-page">
                        <ProvisionAmoveStorage/>
                        <div className="storage-region grid-2">
                            <h3>Dashboard</h3>
                            <div className="storage-region-select">
                                {amoveStorage.length > 1 &&
                                    <Select
                                        className={` ${regionsFilter !== '' && regionsFilter !== null ? "selected" : ''}`}
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={onChangeStorageFilter}
                                        placeholder="All Storage Tiers"
                                        value={selectedStorageFilter}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={storageFilter}
                                    />
                                }
                                {amoveStorage.length > 1 &&
                                    <Select
                                        className={` ${regionsFilter !== '' && regionsFilter !== null ? "selected" : ''}`}
                                        showSearch
                                        optionFilterProp="children"
                                        onChange={onChangeRegoinFilter}
                                        placeholder="All Regions"
                                        value={selectedRegionsFilter}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={regionsFilter}
                                    />
                                }
                            </div>
                        </div>
                        <div className="dashboard-object-main">
                            <div className="object-wrapper">
                                <div className="object-card">
                                    <div className="header">
                                        <h3>All Buckets</h3>
                                        <div className="icon-wrapper">
                                            <img src={objectBucketIcon} alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        {showPageData &&
                                            <strong>{totalBuckets}</strong>
                                        }
                                    </div>
                                    <div className="footer">
                                        <a href="#" className="btn-style btn-30" onClick={(e) => {
                                            e.preventDefault()
                                            navigate("/buckets?create-bucket=true")
                                        }}>Create a Bucket</a>
                                    </div>
                                </div>
                                <div className="object-card">
                                    <div className="header">
                                        <h3>Size</h3>
                                        <div className="icon-wrapper">
                                            <img src={objectCircleIcon} alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        {showPageData &&
                                            <strong>{prettySize(iDriveUsage)}</strong>
                                        }
                                    </div>
                                </div>
                                {/*<div className="object-card">
                                    <div className="header">
                                        <h3>Usage</h3>
                                        <div className="icon-wrapper">
                                            <img src={objectFolderIcon} alt="icon"/>
                                        </div>
                                    </div>
                                    <div className="body">
                                        <strong>40</strong>
                                    </div>
                                </div>*/}
                            </div>
                            <p>Storage usage and object count are not real-time and may take up to 24 hours to update.</p>
                        </div>
                        <div className="enable-region-wrapper">
                            <h3>Enabled Regions</h3>
                            {amoveStorageFiltered.length > 0 &&
                                <div className="table-wrapper">
                                    <div className="table-header d-md-grid grid-5 shared d-none">
                                        <p>Storage Name</p>
                                        <p>Region</p>
                                        <p>Tiers</p>
                                        <p>S3 Endpoint</p>
                                        <p>Shared</p>
                                        <p></p>
                                    </div>
                                    <div className="table-body">
                                        {amoveStorageFiltered.map((item) => (
                                            <div className="table-row grid-5 shared">
                                                <div className="table-item">
                                                    <p>{item.name.substring(0, item.name.indexOf("("))}</p>
                                                </div>
                                                <div className="table-item">
                                                    <div className="name-wrapper">
                                                        {(item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Oregon" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Los Angeles" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Virginia" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Dallas" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Phoenix" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Chicago" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "San Jose") &&
                                                            <img src={usFlag} alt="icon"/>
                                                        }

                                                        {(item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "London" ||
                                                        item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "London-2") &&
                                                            <img src={ukFlag} alt="icon"/>
                                                        }

                                                        {(item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Frankfurt-2" ||
                                                                item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Frankfurt" )&&
                                                            <img src={gmFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Paris" &&
                                                            <img src={frFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Ireland" &&
                                                            <img src={eiFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Montreal" &&
                                                            <img src={caFlag} alt="icon"/>
                                                        }

                                                        {item.name.substring(item.name.indexOf("(") + 1, (item.name.length - 1)) === "Singapore" &&
                                                            <img src={snFlag} alt="icon"/>
                                                        }

                                                        <p>{item.name.substring(item.name.indexOf("(") + 1, item.name.length - 1)}</p>


                                                    </div>
                                                </div>
                                                <div className="table-item">
                                                    <div className="name-wrapper">
                                                        <p>{getTierName(item.storageTier)}</p>
                                                    </div>
                                                </div>
                                                <div className="table-item">
                                                    <div className="copy-wrapper">
                                                        <p>{item.serviceUrl}</p>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            navigator.clipboard.writeText(item.serviceUrl);
                                                            api.open({
                                                                message: 'S3 Endpoint Copied.',
                                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                                duration: 3,
                                                            });
                                                        }}><img src={copyIcon} alt="icon"/></a>
                                                    </div>
                                                </div>
                                                <div className="table-item">
                                                    <ul>
                                                        <li className="dont-open">
                                                            <Switch onChange={(e) => {
                                                                if (!limitExceed) {
                                                                    actionSettingUpdate(e, item)
                                                                }
                                                            }} defaultChecked={item.shared} className={`dont-open ${item.userId !== user.userId ? "disabled" : ""}`}/>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div className="table-item">
                                                    <ul className="options storage-options">
                                                        <li className="delete-icon"><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setRegionToDelete(item)
                                                            regionDeleteOpen(true)
                                                        }}><img src={trashIcon} alt="icon"/></a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            }
                            <div className="new-region-wrapper long-select">
                                <a href="#" className="btn-style btn-30" onClick={() => {
                                    setCreateRegion(true)
                                }}>Add new Region</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Add New Region" className="bucket-main-modal" centered open={createRegion} onOk={manageRegionCancel}
                   onCancel={manageRegionCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-9">
                            <div className="name-wrapper">
                                <img src={logoIcon} alt="icon"/>
                                <h3>Add New Region</h3>
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="cross">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    manageRegionCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="form mb-4">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Select a Storage</label>
                                    <Select
                                        placeholder="Choose Amove Storage"
                                        className="user-select-role"
                                        onChange={onChangeProvisions}
                                        value={selectedProvision}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        {cloudAccounts.filter(account => account.storageTier === 1).length > 0 &&
                                            <Option value="1">Amove-Perform</Option>
                                        }
                                        {cloudAccounts.filter(account => account.storageTier === 2).length > 0 &&
                                            <Option value="2">Amove-Global</Option>
                                        }
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="form-group">
                                    <label htmlFor="bucket-name">Select a Region</label>
                                    <Select
                                        className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                        showSearch
                                        optionFilterProp="children"
                                        placeholder="Select a Region"
                                        onChange={onChangeRegoin}
                                        value={selectedRegions}
                                        filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                        options={regions.filter((item) => {
                                            if (!optionsRegions.includes(item.code)) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.code,
                                        }))}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button className={`btn-style btn-30 ${selectedProvision === undefined || selectedRegions === undefined ? "disabled" : ""}`} loading={loading} onClick={() => {
                                setUpNewIDrive()
                            }}>Add New Region</Button></li>
                        <li>
                            <Button className="btn-style transparent btn-30" onClick={manageRegionCancel}>Cancel</Button>
                        </li>
                        {/*<li>
                            <Button className="btn-style transparent" onClick={() => {
                                console.log(regions.filter((item) => {
                                    var selectedTierAccounts = cloudAccounts.filter(account => (account.storageTier === parseInt(selectedProvision)))
                                    console.log(item.code)
                                    console.log(selectedTierAccounts)
                                    selectedTierAccounts.map(account => {
                                        if(account.credentialsData !== item.code){

                                        }
                                    })
                                    console.log(cloudAccounts.filter(account => (account.storageTier === parseInt(selectedProvision) && account.credentialsData !== item.code)).length)
                                    if (cloudAccounts.filter(account => (account.storageTier === parseInt(selectedProvision) && account.credentialsData !== item.code)).length < 0) {
                                        console.log(item)
                                    }
                                }))
                            }}>tttt</Button>
                        </li>*/}
                    </ul>
                </div>
            </Modal>

            <Modal title="Delete Region" className="bucket-main-modal" centered open={deleteRegion} onOk={regionDeleteCancel}
                   onCancel={regionDeleteCancel}>
                <div className="bucket-modal-header">
                    <div className="row">
                        <div className="col-8">
                            <div className="name-wrapper">
                                <img src={trashIcon} alt="icon"/>
                                <h3>Delete Region</h3>
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="cross">
                                <a href="#" onClick={(e) => {
                                    e.preventDefault();
                                    regionDeleteCancel()
                                }}>
                                    <img src={crossIcon} alt="icon"/></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="bucket-modal-body">
                    <div className="trash-wrapper">
                        <p>Are you sure you want to delete this region?
                            This action cannot be undone, and all data within
                            the region will be permanently lost. Please confirm
                            if you wish to proceed.</p>
                    </div>
                </div>
                <div className="bucket-modal-footer">
                    <ul>
                        <li>
                            <Button className={`btn-style trash btn-30`} loading={loading} onClick={() => {
                                deleteAmoveRegion(regionToDelete)
                            }}>Delete Region</Button></li>
                        <li>
                            <Button className="btn-style transparent btn-30" onClick={regionDeleteCancel}>Cancel</Button>
                        </li>
                    </ul>
                </div>
            </Modal>
        </>
    )
}

export default StorageDashboard;
