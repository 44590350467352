import React, {useEffect, useState, useRef} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button, Checkbox, Modal, notification, Select, Tooltip, Radio, Switch} from 'antd';
import {useAuth} from "../context/AuthContext";

import affrimLogo from "../assets/images/pay-with-affrim.svg";

import amoveIcon from "../assets/images/icons/checkout/amove-logo-icon.svg";
import cloudIcon from "../assets/images/icons/checkout/cloud-connections.svg";
import globalIcon from "../assets/images/icons/checkout/global-icon.svg";
import performIcon from "../assets/images/icons/checkout/perform-icon.svg";
import powerIcon from "../assets/images/icons/checkout/power-user-icon.svg";
import scaleIcon from "../assets/images/icons/checkout/scale-icon.svg";
import standardIcon from "../assets/images/icons/checkout/standard-user-icon.svg";
import usersIcon from "../assets/images/icons/checkout/users.svg";

import pageLogoWhite from "../assets/images/logo-dark.svg";
import priceCardIcon1 from "../assets/images/price-card-icon-1.svg";
import priceCardIcon2 from "../assets/images/price-card-icon-2.svg";
import priceCardIcon3 from "../assets/images/price-card-icon-3.svg";
import storageCardIcon1 from "../assets/images/storage-price-icon-1.svg";
import storageCardIcon2 from "../assets/images/storage-price-icon-2.svg";
import storageCardIcon3 from "../assets/images/storage-price-icon-3.svg";
import config from "../config";
import pageLogo from "../assets/images/page-logo.svg";

const AmovePackages = () => {
    const {
        isDark,
    } = useAuth();
    const navigate = useNavigate();
    const [viewCount, setViewCount] = useState(0);
    const [quoteNumber, setQuoteNumber] = useState('');
    const [selectedPackage, setSelectedPackage] = useState('');
    const [monthly, setMonthly] = useState(true);
    const [amovePerformCount, setAmovePerformCount] = useState(0);
    const [amoveGlobalCount, setAmoveGlobalCount] = useState(0);
    const [amoveScaleCount, setAmoveScaleCount] = useState(0);
    const [connectionsCount, setConnectionsCount] = useState(0);
    const [adminUserCount, setAdminUserCount] = useState(0);
    const [standardUserCount, setStandardUserCount] = useState(0);
    const [powerUserCount, setPowerUserCount] = useState(0);
    const [total, setTotal] = useState(0);


    useEffect(() => {
        calculate()
    }, [monthly, amovePerformCount, amoveGlobalCount, amoveScaleCount, connectionsCount, adminUserCount, standardUserCount, powerUserCount, selectedPackage]);

    const calculate = () => {
        var t = 0;
        if (monthly) {
            t += amovePerformCount * 40;
            t += amoveGlobalCount * 15;
            t += amoveScaleCount * 10;
            t += connectionsCount * 10;
            t += adminUserCount * 15;
            t += powerUserCount * 10;
            t += standardUserCount * 5;

            if (selectedPackage === "Click For BackUp") {
                t += 50;
            }
            if (selectedPackage === "Click For Creatives") {
                t += 100;
            }
            if (selectedPackage === "Click For Business") {
                t += 200;
            }

        } else {
            t += amovePerformCount * 430;
            t += amoveGlobalCount * 160;
            t += amoveScaleCount * 75;
            t += connectionsCount * 10 * 12;
            t += adminUserCount * 15 * 12;
            t += powerUserCount * 10 * 12;
            t += standardUserCount * 5 * 12;

            if (selectedPackage === "Click For BackUp") {
                t += 540;
            }
            if (selectedPackage === "Click For Creatives") {
                t += 1080;
            }
            if (selectedPackage === "Click For Business") {
                t += 2160;
            }
        }


        setTotal(t)
    };

    return (
        <>
            <div id="main-content" className="checkout-payment-main">
                <div className="container-fluid">
                    <div className="main-content-body">

                        <section className="sec-click-everyone first-price extra-padding border-bottom-1px-white">
                            <div className="container">
                                <div className="price-tab-wrapper">
                                    <h1>Time for
                                        <img src={isDark ? pageLogoWhite : pageLogo} alt="logo"/></h1>
                                    <h3 className="h3 orange-color bold">Unlock Immediate Savings With A Click Starter Bundle</h3>
                                    <nav className="click-nav">
                                        <div className="nav nav-tabs border-0 choose-plan-wrapper second-nav" role="tablist">
                                            {/*<span className="check-btn price-toggle active"></span>*/}
                                            <div className="nav-item" onClick={() => {
                                                setMonthly(!monthly)
                                            }}>
                                                <label
                                                    className={`nav-link p bg-transparent border-0 ${monthly ? "active" : ""}`}
                                                > <span className="nav-toggle"></span>
                                                    Monthly /</label>
                                                <label
                                                    className="nav-link p bg-transparent border-0"
                                                >Annual</label>
                                            </div>
                                        </div>
                                    </nav>

                                    <div className="tab-content">
                                        <div
                                            className="tab-pane fade show active"
                                            id="monthly-tab"
                                            role="tabpanel"
                                            aria-labelledby="nav-price-tab"
                                        >
                                            <div className="price-wrapper">
                                                <div className="price-card price-card-click">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Click For <br/>Everyone</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={priceCardIcon1}
                                                                 alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <ul className="list">
                                                        <li><h3 className="small bold">2 Admin Users</h3></li>
                                                        <li>
                                                            <h3 className="small bold">2 Cloud Connections</h3>
                                                            <span>(Connect Your Existing Storage)</span>
                                                        </li>
                                                        <li><h3 className="small bold">1TB Of Global Storage</h3></li>
                                                        <li><h3 className="small">Unlimited Shared Drives To Users</h3></li>
                                                        <li><h3 className="small">Sync Local Drives To Cloud</h3></li>
                                                        <li><h3 className="small">Easy File Sharing To External</h3></li>
                                                        <li><h3 className="small">Storage Management</h3></li>
                                                        <li><h3 className="small">Google Sign-In And MFA</h3></li>

                                                    </ul>
                                                    <div className="btn-wrapper">
                                                        <a href="#" className="btn-style small r-10 mb-0">Coming Soon</a>
                                                    </div>
                                                </div>
                                                <div className="price-card price-card-click">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Click For <br/>BackUp</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={priceCardIcon1}
                                                                 alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <ul className="list">
                                                        <li><h3 className="small bold">2 Admin Users</h3></li>
                                                        <li>
                                                            <h3 className="small bold">2 Cloud Connections</h3>
                                                            <span>(Connect Your Existing Storage)</span>
                                                        </li>
                                                        <li><h3 className="small bold">1TB Of Global Storage</h3></li>
                                                        <li><h3 className="small bold">3TB Of Scale Storage</h3></li>
                                                        <li><h3 className="small">Unlimited Shared Drives To Users</h3></li>
                                                        <li><h3 className="small">Sync Local Drives To Cloud</h3></li>
                                                        <li><h3 className="small">Storage Management</h3></li>
                                                        <li><h3 className="small">Easy File Sharing To External Users</h3></li>
                                                        <li><h3 className="small">Storage Management</h3></li>
                                                        <li><h3 className="small">3TB Cloudflyer Services</h3></li>
                                                        <li><h3 className="small">Google Sign-In And MFA</h3></li>
                                                    </ul>
                                                    <div className="btn-wrapper">
                                                        {monthly &&
                                                            <a href="#storage-tiers" className={`btn-style small r-10 price-btn btn-discount ${selectedPackage === "Click For BackUp" ? "active" : ""}`} data-price="50" data-title="Click For BackUp" onClick={(e) => {
                                                                if (selectedPackage === "Click For BackUp") {
                                                                    setSelectedPackage("")
                                                                    e.preventDefault()
                                                                } else {
                                                                    setSelectedPackage("Click For BackUp")
                                                                }
                                                            }}>$50/Month <span>Save $135</span></a>
                                                        }
                                                        {!monthly &&
                                                            <a href="#storage-tiers" className={`btn-style small r-10 price-btn btn-discount ${selectedPackage === "Click For BackUp" ? "active" : ""}`} data-price="540" data-title="Click For BackUp" onClick={(e) => {
                                                                if (selectedPackage === "Click For BackUp") {
                                                                    setSelectedPackage("")
                                                                    e.preventDefault()
                                                                } else {
                                                                    setSelectedPackage("Click For BackUp")
                                                                }
                                                            }}>$540/Annually <span>Save 10%</span></a>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="price-card price-card-click">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Click For <br/>Creatives</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={priceCardIcon2}
                                                                 alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <ul className="list">
                                                        <li><h3 className="small bold">2 Admin Users</h3></li>
                                                        <li><h3 className="small bold">2 Standard Users</h3></li>
                                                        <li><h3 className="small bold">2 Cloud Connections</h3>
                                                            <span>(Connect Your Existing Storage)</span></li>
                                                        <li><h3 className="small bold">3TB Of Perform Storage</h3></li>
                                                        <li><h3 className="small bold">3TB Of Scale Storage</h3></li>
                                                        <li><h3 className="small">Unlimited Shared Drives</h3><span>(Users, Teams & Projects)</span>
                                                        </li>
                                                        <li><h3 className="small">Sync Local Drives To Cloud</h3></li>
                                                        <li><h3 className="small">Easy File Sharing To External Users</h3></li>
                                                        <li><h3 className="small">Storage Management</h3></li>
                                                        <li><h3 className="small">5TB Cloudflyer Services</h3></li>
                                                        <li><h3 className="small">Google Sign-In & MFA</h3></li>
                                                    </ul>
                                                    <div className="btn-wrapper">

                                                        {monthly &&
                                                            <a href="#storage-tiers" className={`btn-style small r-10 price-btn btn-discount ${selectedPackage === "Click For Creatives" ? "active" : ""}`} data-price="100" data-title="Click For Creatives" onClick={(e) => {

                                                                if (selectedPackage === "Click For Creatives") {
                                                                    setSelectedPackage("")
                                                                    e.preventDefault()
                                                                } else {
                                                                    setSelectedPackage("Click For Creatives")
                                                                }
                                                            }}>$100/Month <span>Save $241</span></a>
                                                        }
                                                        {!monthly &&
                                                            <a href="#storage-tiers" className={`btn-style small r-10 price-btn btn-discount ${selectedPackage === "Click For Creatives" ? "active" : ""}`} data-price="1080" data-title="Click For Creatives" onClick={(e) => {

                                                                if (selectedPackage === "Click For Creatives") {
                                                                    setSelectedPackage("")
                                                                    e.preventDefault()
                                                                } else {
                                                                    setSelectedPackage("Click For Creatives")
                                                                }
                                                            }}>$1080/Annually <span>Save 10%</span></a>
                                                        }

                                                    </div>
                                                </div>
                                                <div className="price-card price-card-click">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Click For <br/>Business</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={priceCardIcon3}
                                                                 alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <ul className="list">
                                                        <li><h3 className="small bold">2 Admin Users</h3></li>
                                                        <li><h3 className="small bold">8 Power/Standard Users</h3></li>
                                                        <li><h3 className="small bold">2 Cloud Connections</h3><span>(Connect Your Existing Storage)</span>
                                                        </li>
                                                        <li><h3 className="small bold">5 TB Of Global Storage</h3></li>
                                                        <li><h3 className="small bold">5 TB Of Scale Storage</h3></li>
                                                        <li><h3 className="small">Unlimited Shared Drives</h3>
                                                            <span>(Users, teams & projects)</span></li>
                                                        <li><h3 className="small">Sync Local Drives To Cloud</h3></li>
                                                        <li><h3 className="small">Easy File Sharing To External Users</h3></li>
                                                        <li><h3 className="small">Storage Management</h3></li>
                                                        <li><h3 className="small">10TB Cloudflyer Services</h3></li>
                                                        <li><h3 className="small">SSO, Google Sign-In & MFA</h3></li>
                                                        <li><h3 className="small">Optional SLA</h3></li>
                                                    </ul>
                                                    <div className="btn-wrapper">

                                                        {monthly &&
                                                            <a href="#storage-tiers" className={`btn-style small r-10 price-btn btn-discount ${selectedPackage === "Click For Business" ? "active" : ""}`} data-price="200" data-title="Click For Business" onClick={(e) => {

                                                                if (selectedPackage === "Click For Business") {
                                                                    setSelectedPackage("")
                                                                    e.preventDefault()
                                                                } else {
                                                                    setSelectedPackage("Click For Business")
                                                                }
                                                            }}>$200/Month <span>Save $440</span></a>
                                                        }
                                                        {!monthly && <a href="#storage-tiers" className={`btn-style small r-10 price-btn btn-discount ${selectedPackage === "Click For Business" ? "active" : ""}`} data-price="2160" data-title="Click For Business" onClick={(e) => {

                                                            if (selectedPackage === "Click For Business") {
                                                                setSelectedPackage("")
                                                                e.preventDefault()
                                                            } else {
                                                                setSelectedPackage("Click For Business")
                                                            }

                                                        }}>$2160/Annually <span>Save 10%</span></a>
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="price-footer">
                                    <h3 className="h3 white">Already Have An Idea Of What You Need? Or Need To Add To A Bundle? <br/> We've Got You Covered Below <a href="#storage-tiers"> 👇</a></h3>

                                    <p>For Yearly Plans & Volume Purchases Email: <a href="mailto:Sales@amove.io" target="_blank">Sales@amove.io</a></p>
                                </div>
                            </div>
                            <span id="storage-tiers"></span>
                        </section>

                        <section className="sec-click-everyone extra-padding">
                            <div className="container">
                                <div className="price-tab-wrapper">
                                    <h1>Storage, Users & Connections</h1>
                                    <h3 className="white h3">Build Your Cloud Storage Network By Adding The Capacity, <br/> Users & Cloud Connection You Need</h3>
                                    <nav className="click-nav">
                                        <div className="nav nav-tabs border-0 choose-plan-wrapper first-nav" role="tablist">
                                            {/*<span className="check-btn price-toggle active"></span>*/}
                                            <div className="nav-item" onClick={() => {
                                                setMonthly(!monthly)
                                            }}>
                                                <label
                                                    className={`nav-link p bg-transparent border-0 ${monthly ? "active" : ""}`}
                                                >Monthly /
                                                    <span className="nav-toggle purple"></span>
                                                </label>
                                                <label
                                                    className="nav-link p bg-transparent border-0"
                                                >Annual</label>
                                            </div>
                                        </div>
                                    </nav>

                                    <div className="tab-content">
                                        <div
                                            className="tab-pane fade show active"
                                            id="individual-tab"
                                            role="tabpanel"
                                            aria-labelledby="nav-home-tab"
                                        >
                                            <div className="price-wrapper">
                                                <div className="price-card cyan">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Amove <br/>Perform</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={storageCardIcon1}
                                                                 alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <h3 className="small white">Cloud storage that works to like a local SSD.</h3>
                                                    <ul className="list">
                                                        <li><h3 className="small">Collaborate at the speed of
                                                            thought with high res video in
                                                            the cloud.</h3></li>
                                                        <li><h3 className="small">Local SSD style responsiveness
                                                            without needing to download</h3>
                                                        </li>
                                                        <li><h3 className="small">Perfect for content teams and
                                                            real time deadlines</h3></li>
                                                        <li><h3 className="small">Similar performance for for less
                                                            cost than the competition.</h3>
                                                        </li>
                                                        <li><h3 className="small">Click compatible with no egress
                                                            fees.</h3></li>
                                                    </ul>
                                                    <div className="btn-wrapper">
                                                        {monthly &&
                                                            <a href="#" className="btn-style small r-10">$40TB/Month</a>
                                                        }
                                                        {!monthly &&
                                                            <a href="#" className="btn-style small r-10">$430TB/Annually</a>
                                                        }
                                                        <div className="counter-container">
                                                            <button className="minus-btn" onClick={() => {
                                                                if (amovePerformCount > 0) {
                                                                    setAmovePerformCount(amovePerformCount - 1)
                                                                }
                                                            }}>−
                                                            </button>
                                                            <input type="number" className="counter" id="amove-perform-monthly-price" data-price="40" onChange={(e) => {
                                                                setAmovePerformCount(parseInt(e.target.value))
                                                            }} value={amovePerformCount}/>
                                                            <button className="plus-btn" onClick={() => {
                                                                setAmovePerformCount(amovePerformCount + 1)
                                                            }}>+
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="price-card blue">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Amove <br/>Global</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={storageCardIcon2}
                                                                 alt="icon"/>>
                                                        </div>
                                                    </div>
                                                    <h3 className="small white">Objectively ... the best S3
                                                        storage out there.</h3>
                                                    <ul className="list">
                                                        <li><h3 className="small">Stop overpaying for AWS S3
                                                            and Azure, use Amove
                                                            Global instead.</h3></li>
                                                        <li><h3 className="small">Class leading Hot cloud
                                                            performance for a fraction of
                                                            the cost.</h3></li>
                                                        <li><h3 className="small">As fast if not faster than your
                                                            current provider.</h3></li>
                                                        <li><h3 className="small">Zero egress costs. Only pay for
                                                            your capacity.</h3></li>
                                                        <li><h3 className="small">Perfect for sharing files across
                                                            locations, teams, and projects.</h3></li>
                                                    </ul>
                                                    <div className="btn-wrapper">

                                                        {monthly &&
                                                            <a href="#" className="btn-style small r-10">$15TB/Month</a>
                                                        }
                                                        {!monthly &&
                                                            <a href="#" className="btn-style small r-10">$160TB/Annually</a>
                                                        }

                                                        <div className="counter-container">
                                                            <button className="minus-btn" onClick={() => {
                                                                if (amoveGlobalCount > 0) {
                                                                    setAmoveGlobalCount(amoveGlobalCount - 1)
                                                                }
                                                            }}>−
                                                            </button>
                                                            <input type="number" className="counter" id="amove-global-monthly-price" data-price="15" onChange={(e) => {
                                                                setAmoveGlobalCount(parseInt(e.target.value))
                                                            }} value={amoveGlobalCount}/>
                                                            <button className="plus-btn" onClick={() => {
                                                                setAmoveGlobalCount(amoveGlobalCount + 1)
                                                            }}>+
                                                            </button>
                                                        </div>

                                                    </div>
                                                </div>
                                                <div className="price-card purple">
                                                    <div className="title-wrapper">
                                                        <h3 className="h3">Amove <br/>Scale</h3>
                                                        <div className="icon-wrapper">
                                                            <img src={storageCardIcon3}
                                                                 alt="icon"/>
                                                        </div>
                                                    </div>
                                                    <h3 className="small white">The perfect storage for basic
                                                        documents and data.</h3>
                                                    <ul className="list">
                                                        <li><h3 className="small">Significantly more affordable
                                                            than Dropbox.</h3></li>
                                                        <li><h3 className="small">Fast access and movement of
                                                            data at half the monthly cost of
                                                            hot storage.</h3></li>
                                                        <li><h3 className="small">Best value for basic cloud
                                                            storage on the market.</h3></li>
                                                        <li><h3 className="small">Perfect for sharing .pdfs, basic
                                                            documents, and everyday
                                                            assets.</h3></li>
                                                        <li><h3 className="small">Mix and match with faster tiers
                                                            to maximize performance and
                                                            savings.</h3></li>
                                                    </ul>

                                                    <div className="btn-wrapper">

                                                        {monthly &&
                                                            <a href="#" className="btn-style small r-10">$7TB/Month</a>
                                                        }
                                                        {!monthly &&
                                                            <a href="#" className="btn-style small r-10">$75TB/Annually</a>
                                                        }
                                                        <div className="counter-container">
                                                            <button className="minus-btn" onClick={() => {
                                                                if (amoveScaleCount > 0) {
                                                                    setAmoveScaleCount(amoveScaleCount - 1)
                                                                }
                                                            }}>−
                                                            </button>
                                                            <input type="number" className="counter" id="amove-scale-monthly-price" data-price="7" onChange={(e) => {
                                                                setAmoveScaleCount(parseInt(e.target.value))
                                                            }} value={amoveScaleCount}/>
                                                            <button className="plus-btn" onClick={() => {
                                                                setAmoveScaleCount(amoveScaleCount + 1)
                                                            }}>+
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="connection-wrapper">
                                                    <div className="connection-card">
                                                        <h3 className="white">Connections</h3>
                                                        <p className="white">Add additional cloud connections to mount any folder and bucket on your desktop.
                                                            Egress may apply depending on your storage provider agreement.</p>
                                                        <div className="connection-count">
                                                            <p className="white">Connection $10</p>

                                                            <div className="counter-container">
                                                                <button className="minus-btn" onClick={() => {
                                                                    if (connectionsCount > 0) {
                                                                        setConnectionsCount(connectionsCount - 1)
                                                                    }
                                                                }}>−
                                                                </button>
                                                                <input type="number" className="counter" id="connection-monthly-price" data-price="10" onChange={(e) => {
                                                                    setConnectionsCount(parseInt(e.target.value))
                                                                }} value={connectionsCount}/>
                                                                <button className="plus-btn" onClick={() => {
                                                                    setConnectionsCount(connectionsCount + 1)
                                                                }}>+
                                                                </button>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="connection-card">
                                                        <h3 className="white">Users</h3>
                                                        <ul className="connection-count">
                                                            <li><p className="white">Admin $15 <span data-bs-toggle="tooltip" data-bs-placement="right" title="Has access to bill paying functions such as adding / removing users, adding storage or setting data tiering policies.">
                                                 (i)
                                            </span></p>

                                                                <div className="counter-container">
                                                                    <button className="minus-btn" onClick={() => {
                                                                        if (adminUserCount > 0) {
                                                                            setAdminUserCount(adminUserCount - 1)
                                                                        }
                                                                    }}>−
                                                                    </button>
                                                                    <input type="number" className="counter" id="admin-monthly-price" data-price="15" onChange={(e) => {
                                                                        setAdminUserCount(parseInt(e.target.value))
                                                                    }} value={adminUserCount}/>
                                                                    <button className="plus-btn" onClick={() => {
                                                                        setAdminUserCount(adminUserCount + 1)
                                                                    }}>+
                                                                    </button>
                                                                </div>


                                                            </li>
                                                            <li><p className="white">Power $10 <span data-bs-toggle="tooltip" data-bs-placement="right" title="Can create and manage shares, provide access to existing users and move storage between connections. Can request additional storage and to invite new users that require admin approval.">
                                                 (i)
                                            </span></p>

                                                                <div className="counter-container">
                                                                    <button className="minus-btn" onClick={() => {
                                                                        if (powerUserCount > 0) {
                                                                            setPowerUserCount(powerUserCount - 1)
                                                                        }
                                                                    }}>−
                                                                    </button>
                                                                    <input type="number" className="counter" id="power-monthly-price" data-price="10" onChange={(e) => {
                                                                        setPowerUserCount(parseInt(e.target.value))
                                                                    }} value={powerUserCount}/>
                                                                    <button className="plus-btn" onClick={() => {
                                                                        setPowerUserCount(powerUserCount + 1)
                                                                    }}>+
                                                                    </button>
                                                                </div>
                                                            </li>
                                                            <li><p className="white">Standard $5 <span data-bs-toggle="tooltip" data-bs-placement="right" title="Has read write access storage thats been provisioned to them by Power or Admin users. Can provision or request more storage or invite users.">
                                                 (i)
                                            </span></p>

                                                                <div className="counter-container">
                                                                    <button className="minus-btn" onClick={() => {
                                                                        if (standardUserCount > 0) {
                                                                            setStandardUserCount(standardUserCount - 1)
                                                                        }
                                                                    }}>−
                                                                    </button>
                                                                    <input type="number" className="counter" id="standard-monthly-price" data-price="5" onChange={(e) => {
                                                                        setStandardUserCount(parseInt(e.target.value))
                                                                    }} value={standardUserCount}/>
                                                                    <button className="plus-btn" onClick={() => {
                                                                        setStandardUserCount(standardUserCount + 1)
                                                                    }}>+
                                                                    </button>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="connection-card">
                                                        <ul className="connection-count">
                                                            <li>
                                                                <div className="total-wrapper">
                                                                    <h3 className="white">Total</h3>
                                                                    <h3 className="white text-start" id="monthly-total">${total}</h3>
                                                                    <NavLink to="/checkout/#?cardTitle=Click%20For%20BackUp&cardPrice=540&plan=Annually&amovePerformCount=3&amovePerformPrice=1290&amoveGlobalCount=3&amoveGlobalPrice=480&amoveScaleCount=2&amoveScalePrice=150&amoveConnectionCount=5&amoveAdminCount=2&amovePowerCount=2&amoveStandardCount=2&monthlyTotal=2570" className="btn-style small get-quote">Save & Email <br/>Quote</NavLink>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="price-footer">
                                    <h3 className="h3 white">Not Sure What You Need? No Worries, Account Owners Can Easily Add <br/> These On The Fly In Click!</h3>
                                    <p className="gray">For Yearly Plans & Volume Purchases Email: <a href="mailto:Sales@amove.io" target="_blank">Sales@amove.io</a></p>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AmovePackages;

