import React, {useEffect, useRef, useState} from "react";
import {Button, Checkbox, Modal, notification, Pagination, Popover, Radio, Select, Tooltip} from 'antd';
import plusIcon from '../assets/images/icons/add-user-plus-icon.svg'
import crossIcon from '../assets/images/icons/cross-black-icon.svg'
import filterIcon from '../assets/images/icons/filter-black-icon.svg'
import searchIcon from '../assets/images/icons/search-gray-icon.svg'
import verticleDotsIcon from '../assets/images/icons/verticle-dots-black-icon.svg'
import horizontalDotsIcon from '../assets/images/icons/horizontal-dots-icon.svg'
import downArrowIcon from '../assets/images/icons/down-arrow-icon.svg'
import toastIcon from "../assets/images/icons/toast-icon.svg";
import config from "../config";
import {useAuth} from "../context/AuthContext";
import {ConfigurationsMenu} from "../components/ConfigurationsMenu";
import {DeleteModal} from "../components/DeleteModal";
import {useNavigate} from "react-router-dom";
import {ReactMultiEmail} from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import {BillingPopup} from "../components/BillingPopup";
import warning from "../assets/images/icons/warning-sign.svg";
import info from "../assets/images/info.svg";

const {Option} = Select;
const Users = () => {
    const {
        token,
        getDataWithToken,
        updateDataWithToken,
        logout,
        cancelRequests,
        user,
        userStatus,
        agentIsRunning,
        setBillingPopupOpen,
        reloadUsers,
        setReloadUsers,
        sendDataToWebsocket,
        getDataWithTokenSSO,
        selectedDriveToInviteUser,
        openInviteUserPopup,
        setOpenInviteUserPopup,
        setPopup500,
        deleteDataWeb,
        setEndpoint,
        setShowPopupSupport,
        omnisend,
        sendDataToMixPanel,
        setError499,
        setPopup499
    } = useAuth();
    const dataFetchedRef = useRef(false);
    const dataFetchedRef2 = useRef(false);
    const dataFetchedRef1 = useRef(false);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [isSelectUser, setSelectUser] = useState(false);
    const [isOktaUser, setOktaUser] = useState(false);
    const [isManualUser, setManualUser] = useState(false);
    const [editUser, setEditUser] = useState(false);
    const [isAssignDrive, setAssignDrive] = useState(false);
    const [isAssignGroup, setAssignGroup] = useState(false);
    const [isAssignProjects, setAssignProjects] = useState(false);
    const [emailAddress, setEmailAddress] = useState("");
    const [selectedUserRole, setSelectedUserRole] = useState();
    const [authProvider, setAuthProvider] = useState(0);
    const [selectedDrive, setSelectedDrive] = useState();

    const [usersData, setUsersData] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(false);
    const [loadingData, setLoadingData] = useState(false);
    const [allUsersDataDone, setAllUsersDataDone] = useState(false);
    const [usersStatus, setUsersStatus] = useState(0);
    const [userType, setUserType] = useState(0);
    const [drivesData, setDrivesData] = useState([]);
    const [selectedCloudDrive, setSelectedCloudDrive] = useState();
    const [newDrivePermissionValue, setNewDrivePermissionValue] = useState(2);
    const [driveList, setDriveList] = useState([]);
    const [newDriveList, setNewDriveList] = useState([]);
    const [searchValForSearch, setSearchValForSearch] = useState("");
    const [isAssignDeleteUser, setAssignDeleteUser] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [descending, setDescending] = useState(false);
    const [perPage, setPerPage] = useState(20);
    const [projectsData, setProjectsData] = useState([]);
    const [groupsData, setGroupsData] = useState([]);
    const [selectedUserToUpdate, setSelectedUserToUpdate] = useState("");
    const [selectedGroup, setSelectedGroup] = useState("");
    const [selectedProject, setSelectedProject] = useState("");
    const [newGroupList, setNewGroupList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [projectsList, setProjectsList] = useState([]);
    const [newProjectsList, setNewProjectsList] = useState([]);
    const [newProjectPermissionValue, setNewProjectPermissionValue] = useState(2);
    const [spinner, setSpinner] = useState(false);
    const [dataLoaded, setDataLoaded] = useState(false);
    const [emails, setEmails] = useState([]);
    const [focused, setFocused] = useState(false);
    const [importUsersDataWithoutFilter, setImportUsersDataWithoutFilter] = useState([]);
    const [importUsersData, setImportUsersData] = useState([]);
    const [validationError, setValidationError] = useState('');
    const [searchValue, setSearchValue] = useState('');
    const [open, setOpen] = useState(false);
    const [adError, setAdError] = useState('');

    const [authProviderDropdown, setAuthProviderDropdown] = useState([
        {value: 0, label: 'Amove'}
    ]);
    var allUsersData = [];

    var assignedGroupsCount = 0;


//SSO------------------------
    const [SSOAvailable, setSSOAvailable] = useState(false);
    const [azureAD, setAzureAD] = useState(false);
    const [okta, setOkta] = useState(false);
    const [saml, setSaml] = useState(false);


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (reloadUsers) {
                setReloadUsers(false)
                getAllUsers()
            }
        }
    }, [reloadUsers]);
    const handleOpenChange = (newOpen) => {
        setOpen(newOpen);
    };
    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (openInviteUserPopup && allUsersDataDone) {
            setOpenInviteUserPopup(false)
            if (userStatus.status === "active") {
                showSelectUser()
            } else {
                if (usersData.length < 3) {
                    showSelectUser()
                } else {
                    setBillingPopupOpen(true);
                }
            }
        }
    }, [openInviteUserPopup, allUsersDataDone]);

    async function postDataReturnText(url = "") {
        const response = await fetch(config.apiURL + url, {
            method: "POST", headers: {
                "accept": "text/plain",
                "X-App-Origin": "WebApp"
            }
        })
        return response;
    }

    const onChangeCloudDrive = (value) => {
        let newArr = [...newDriveList];
        for (var i = 0; i < drivesData.length; i++) {
            if (drivesData[i].id === value) {
                drivesData[i].permissionType = newDrivePermissionValue;
                newArr.push(drivesData[i])
            }
        }
        setNewDriveList(newArr);
    };
    const onChangePermissionDrive = (e, id, newProject = false) => {

        if (newProject) {
            let newArr = [...newDriveList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].id === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewDriveList(newArr)
        } else {
            let newArr = [...driveList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].id === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setDriveList(newArr)
        }
    };

    const AssignCloudDrive = () => {
        setLoading(true)
        if (newDriveList.length > 0) {
            var dataToPost = [];
            var newArray = [...newDriveList];
            for (var i = 0; i < newArray.length; i++) {
                if (newArray[i].name !== undefined) {
                    dataToPost.push({
                        "userId": selectedUserToUpdate.id,
                        "sharedCloudDriveId": newArray[i].id,
                        "permissionType": newArray[i].permissionType
                    })
                }
            }
            postDataResponseText("/api/permission/users_sharedclouddrive_permision", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(bucket)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setAssignDrive(false);
                    api.open({
                        message: 'Drive successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Drive successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    if (agentIsRunning) {
                        postDataReturnText('/Management/sync_shareddrive')
                            .then((res) => Promise.all([res.status, res.text()]))
                            .then(([status, bucket]) => {
                                if (status === 401) {
                                    logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    var data = JSON.parse(bucket)
                                    var errorType = data.type;
                                    setError499(data.errors[errorType][0])
                                    setPopup499(true)
                                    return;
                                }
                            });
                    }
                    setNewDriveList([]);
                    setSelectedUserToUpdate("");
                    if (driveList.length < 1) {
                        allUsersData = usersData;
                        assignedGroupsCount = 0;
                        getUserCloudDrives(selectedUserToUpdate.id, true);
                    }
                })
        }
        if (driveList.length > 0) {
            dataToPost = [];
            newArray = [...driveList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userId": selectedUserToUpdate.id,
                    "sharedCloudDriveId": newArray[i].sharedCloudDriveId,
                    "permissionType": newArray[i].permissionType,
                    "id": newArray[i].id,
                })
            }
            updateDataWithToken("/api/permission/users_sharedclouddrive_permision", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    setLoading(false)
                    setAssignDrive(false);
                    if (newProjectsList.length < 1) {
                        api.open({
                            message: 'Drive successfully updated.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5,
                        });
                        sendDataToMixPanel('Drive successfully updated.', user.username, {
                            'App': 'Web App'
                        })
                    }
                    if (agentIsRunning) {
                        postDataReturnText('/Management/sync_shareddrive')
                            .then((res) => Promise.all([res.status, res.text()]))
                            .then(([status, bucket]) => {
                                if (status === 401) {
                                    logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    var data = JSON.parse(bucket)
                                    var errorType = data.type;
                                    setError499(data.errors[errorType][0])
                                    setPopup499(true)
                                    return;
                                }

                            });
                    }
                    setDriveList([]);
                    setSelectedUserToUpdate("");
                    allUsersData = usersData;
                    assignedGroupsCount = 0;
                    getUserCloudDrives(selectedUserToUpdate.id, true);
                })
        }
    };
    const removeDriveFromList = (index, drive, newDrive = false) => {


        if (newDrive) {
            let newArr = [...newDriveList];
            newArr.splice(index, 1);
            setNewDriveList(newArr)
        } else {
            let newArr = [...driveList];
            newArr.splice(index, 1);
            setDriveList(newArr)
            deleteDataWeb('/api/permission/users_sharedclouddrive_permision?id=' + drive)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Drive removed successfully.',
                            description: `Your Drive has been removed from team.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });

                        sendDataToMixPanel('Drive removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        if (agentIsRunning) {
                            postDataReturnText('/Management/sync_shareddrive')
                                .then((res) => Promise.all([res.status, res.text()]))
                                .then(([status, bucket]) => {
                                    if (status === 401) {
                                        logout()
                                        return;
                                    }
                                    if (status === 500) {
                                        setPopup500(true)
                                        return;
                                    }
                                    if (status === 499) {
                                        var data = JSON.parse(bucket)
                                        var errorType = data.type;
                                        setError499(data.errors[errorType][0])
                                        setPopup499(true)
                                        return;
                                    }

                                });
                        }
                        let newArrG = [...usersData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedUserToUpdate.id) {
                                newArrG[i].drivesData.splice(index, 1);
                                break;
                            }
                        }
                        setUsersData(newArrG);
                    }
                });
        }

    };

    const AssignDriveCancel = () => {
        setAssignDrive(false);
        setSelectedUserToUpdate("")
    };

    const onChangeGroup = (value) => {
        let newArr = [...newGroupList];
        for (var i = 0; i < groupsData.length; i++) {
            if (groupsData[i].id === value) {
                newArr.push(groupsData[i])
            }
        }
        setNewGroupList(newArr);
    };
    const onChangeProject = (value) => {
        let newArr = [...newProjectsList];
        for (var i = 0; i < projectsData.length; i++) {
            if (projectsData[i].id === value) {
                projectsData[i].permissionType = newProjectPermissionValue;
                newArr.push(projectsData[i])
            }
        }
        setNewProjectsList(newArr);
    };


    const AssignUserToProject = () => {
        setLoading(true)
        if (newProjectsList.length > 0) {
            var dataToPost = [];
            var newArray = [...newProjectsList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "userId": selectedUserToUpdate.id,
                    "projectId": newArray[i].id,
                    "permissionType": newArray[i].permissionType
                })
            }
            postDataResponseText("/api/permission/users_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, bucket]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(bucket)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLoading(false)
                    setAssignProjects(false);
                    api.open({
                        message: 'Projects successfully assigned.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    sendDataToMixPanel('Projects successfully assigned.', user.username, {
                        'App': 'Web App'
                    })
                    setNewProjectsList([]);
                    setSelectedProject("");
                    if (projectsList.length < 1) {
                        allUsersData = usersData;
                        assignedGroupsCount = 0;
                        getUserProjects(selectedUserToUpdate.id, true);
                    }
                })
        }

        if (projectsList.length > 0) {
            dataToPost = [];
            newArray = [...projectsList];
            for (var i = 0; i < newArray.length; i++) {
                dataToPost.push({
                    "id": newArray[i].permission.id,
                    "userId": selectedUserToUpdate.id,
                    "projectId": newArray[i].project.id,
                    "permissionType": newArray[i].permission.permissionType
                })
            }
            updateDataWithToken("/api/permission/users_project_permission", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var data = JSON.parse(response)
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setAssignProjects(false);
                    if (newProjectsList.length < 1) {
                        api.open({
                            message: 'Project successfully updated.',
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5,
                        });
                        sendDataToMixPanel('Project successfully updated.', user.username, {
                            'App': 'Web App'
                        })
                    }
                    setProjectsList([]);
                    setSelectedCloudDrive();
                    allUsersData = usersData;
                    assignedGroupsCount = 0;
                    getUserProjects(selectedUserToUpdate.id, true);
                })
        }
    };
    const onChangePermission = (e, id, newProject = false) => {
        if (newProject) {
            let newArr = [...newProjectsList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].id === id) {
                    newArr[i].permissionType = e.target.value;
                }
            }
            setNewProjectsList(newArr)
        } else {
            let newArr = [...projectsList];
            for (var i = 0; i < newArr.length; i++) {
                if (newArr[i].permission.id === id) {
                    newArr[i].permission.permissionType = e.target.value;
                }
            }
            setProjectsList(newArr)
        }
    };

    const removeProjectFromList = (index, project, newProject = false) => {

        if (newProject) {
            let newArr = [...newProjectsList];
            newArr.splice(index, 1);
            setNewProjectsList(newArr)
        } else {
            let newArr = [...projectsList];
            newArr.splice(index, 1);
            setProjectsList(newArr)
            deleteDataWeb('/api/permission/users_project_permission?id=' + project)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Project removed successfully.',
                            description: `Your project has been removed from team.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Project removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...usersData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedUserToUpdate.id) {
                                newArrG[i].projectsData.splice(index, 1);
                                break;
                            }
                        }
                        setUsersData(newArrG);
                    }
                });
        }

    };
    const removeGroupFromList = (index, project, newProject = false) => {

        if (newProject) {
            let newArr = [...newGroupList];
            newArr.splice(index, 1);
            setNewGroupList(newArr)
        } else {
            let newArr = [...groupList];
            newArr.splice(index, 1);
            setGroupList(newArr)
            deleteDataWeb('/api/usergroup/unassign_user?id=' + project)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var dataNew = JSON.parse(data)
                        var errorType = dataNew.type;
                        setError499(dataNew.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    if (status === 200) {
                        api.open({
                            message: 'Team removed successfully.',
                            description: `Your team has been removed from user.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3
                        });
                        sendDataToMixPanel('Team removed successfully.', user.username, {
                            'App': 'Web App'
                        })
                        let newArrG = [...usersData];
                        for (var i = 0; i < newArrG.length; i++) {
                            if (newArrG[i].id === selectedUserToUpdate.id) {
                                newArrG[i].groups.splice(index, 1);
                                break;
                            }
                        }
                        setUsersData(newArrG);
                    }
                });
        }
    };
    const addGroupSave = () => {
        setLoading(true)
        var dataToPost = [];
        var newArray = [...newGroupList];
        for (var i = 0; i < newArray.length; i++) {
            dataToPost.push({
                "userGroupId": newArray[i].id, "userId": selectedUserToUpdate.id,
            })
        }
        postDataResponseText("/api/usergroup/assign_users", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, bucket]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var data = JSON.parse(bucket)
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLoading(false)
                setAssignGroup(false);
                setGroupList([])
                setNewGroupList([])
                api.open({
                    message: 'User added to teams.', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                });
                sendDataToMixPanel('User added to teams.', user.username, {
                    'App': 'Web App'
                })
                allUsersData = usersData;
                assignedGroupsCount = 0;
                getUserGroups(selectedUserToUpdate.id, true);
            })
    };
    // Select User Modal
    const showSelectUser = () => {
        //setSelectUser(true);
        setManualUser(true);
        setSelectUser(false)
    };

    const selectUserOk = () => {
        setSelectUser(false);
    };

    const selectUserCancel = () => {
        setSelectUser(false);
    };

    // Okta User Modal
    const showOktaUser = () => {
        postData('/api/sso/sso_url_import_user', {
            "callbackUrl": config.callbackURL + "/usercallback"
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                window.location.href = response.url;
            })
    };
    const showADUser = () => {
        setImportUsersData([])
        setLoadingData(true);
        setAdError("")
        getDataWithToken(`/api/sso/get_user_usergroups`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    setAdError("Access Denied.")
                    setLoadingData(false);
                } else {
                    for (var i = 0; i < response.length; i++) {
                        response[i].selected = false;
                    }
                    setLoadingData(false);
                    setImportUsersDataWithoutFilter(response)
                    setImportUsersData(response)
                }
            })
        setOktaUser(true);
        setSelectUser(false)
    };

    // Manual User Modal
    const showManualUser = () => {
        setManualUser(true);
        setSelectUser(false)
    };

    const onChangeUserRole = (value) => {
        setSelectedUserRole(value);
    };
    const onChangeAuthProvider = (value) => {
        setAuthProvider(value);
    };
    const onChangeDrive = (value) => {
        setSelectedDrive(value);
    };


    const selectManualCancel = () => {
        setManualUser(false);
        setEditUser(false);
        let newArr1 = [...emails];
        while (newArr1.length > 0) {
            newArr1.pop();
        }
        setEmails(newArr1);
        setEmailAddress("")
        setValidationError('');
        setLoading(false)
        setSelectedUserRole();
        setSelectedDrive();
    };


    async function postData(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });

        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    async function postDataResponseText(url = "", data = {}) {
        const response = await fetch(config.apiURLWeb + url, {
            method: "POST", headers: {
                "Content-Type": "application/json", 'Authorization': 'Bearer ' + token,
                "X-App-Origin": "WebApp"
            }, body: JSON.stringify(data),
        });
        sendDataToWebsocket(config.apiURLWeb + url, "POST")
        return response;
    }

    async function postDataZapier(email) {
        try {
            var dataTosend = {
                email: email
            }

            if(config.environment === "STG"){
                dataTosend.environment = "STG";
            }
            if(config.environment === "Dev"){
                dataTosend.environment = "Dev";
            }
            if(config.environment === "Prod"){
                dataTosend.environment = "Prod";
            }

            const params = new URLSearchParams(dataTosend);

            var requestOptions = {
                method: 'POST', redirect: 'follow',
                headers: {
                    "X-App-Origin": "WebApp"
                },
                mode: "no-cors"
            };
            const response = await fetch(config.zapURL + params.toString(), requestOptions);
            return response;
        } catch (error) {
            console.error(`Could not signup: ${error}`);
        }
    }

    function SendZap(email) {
        postDataZapier(email)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/add_storage");
                    setShowPopupSupport(true)

                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }
            })
    }

    const insertUser = () => {
        var added = 0;
        emails.forEach((email, index) => {
            var dataToPost = {
                "userType": parseInt(selectedUserRole),
                "firstname": email,
                "lastname": email,
                "username": email,
                "email": email,
                "authProvider": authProvider
            }
            setLoading(true)
            let existed = usersData.filter(user => user.email === email).length > 0;
            if (!existed) {
                postData("/api/user/insert_user", dataToPost)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }

                        if (status === 200) {
                            added++;
                            api.open({
                                message: `New ${emails.length > 1 ? "users" : "user"} added.`,
                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                duration: 3
                            });


                            console.log(index)
                            console.log(emails.length - 1)

                            getAllUsers(true);
                            setManualUser(false)
                            if (index === (emails.length - 1)) {
                                /* if (added > 0) {
                                     api.open({
                                         message: `New ${emails.length > 1 ? "users" : "user"} added.`,
                                         icon: <img src={toastIcon} alt="toastIcon"/>,
                                         duration: 3
                                     });
                                     getAllUsers();
                                 }*/

                                setEmailAddress("")
                                setSelectedUserRole();
                                setSelectedDrive();
                                // setManualUser(false)
                                setLoading(false)
                                let newArr1 = [...emails];
                                while (newArr1.length > 0) {
                                    newArr1.pop();
                                }
                                setEmails(newArr1)
                            }
                        }
                        if (status === 499) {
                            api.open({
                                message: email + " is already in use.",
                                icon: <img src={warning} alt="warning"/>,
                                duration: 3
                            });
                        }
                        omnisend(email)
                        SendZap(email);


                        sendDataToMixPanel('New user added.', user.username, {
                            'App': 'Web App',
                            'User Email': email
                        })
                        if (selectedDrive.length > 0) {
                            var dataToPost = [];
                            for (var i = 0; i < selectedDrive.length; i++) {
                                dataToPost.push({
                                    "userId": response.id,
                                    "sharedCloudDriveId": selectedDrive[i],
                                    "permissionType": 2
                                })
                            }
                            postDataResponseText("/api/permission/users_sharedclouddrive_permision", dataToPost)
                                .then((res) => Promise.all([res.status, res.text()]))
                                .then(([status, bucket]) => {
                                    if (status === 401) {
                                        logout()
                                        return;
                                    }
                                    if (status === 500) {
                                        setPopup500(true)
                                        return;
                                    }
                                })
                        }


                    }).catch(() => {
                    setLoading(false)
                })
            } else {
                api.open({
                    message: email + " is already in use.",
                    icon: <img src={warning} alt="warning"/>,
                    duration: 3
                });
                setEmailAddress("")
                setSelectedUserRole();
                setSelectedDrive();
                setManualUser(false)
                setLoading(false)
                let newArr1 = [...emails];
                while (newArr1.length > 0) {
                    newArr1.pop();
                }
                setEmails(newArr1)
                if (added > 1) {
                    if (index === (emails.length - 1)) {
                        getAllUsers();
                    }
                }
            }
        })
    }
    const updateUser = () => {
        setLoading(true)
        var dataToPost = {
            "userType": parseInt(selectedUserRole),
            "id": selectedUserToUpdate.id,
            "firstname": selectedUserToUpdate.firstname,
            "lastname": selectedUserToUpdate.lastname,
            "username": selectedUserToUpdate.username,
            "email": selectedUserToUpdate.email,
        }
        updateDataWithToken("/api/user/edit_user", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }


                let newArrG = [...usersData];
                for (var i = 0; i < newArrG.length; i++) {
                    if (newArrG[i].id === selectedUserToUpdate.id) {
                        newArrG[i].userType = parseInt(selectedUserRole);
                        break;
                    }
                }
                api.open({
                    message: 'User role updated', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 3
                });

                sendDataToMixPanel('User role updated.', user.username, {
                    'App': 'Web App'
                })
                setUsersData(newArrG);
                setEmailAddress("")
                setSelectedUserRole();
                setSelectedDrive();
                setLoading(false)
                setManualUser(false)
            })
    };


    const resendEmail = (event, email) => {
        var dataToPost = {
            "firstname": email, "lastname": email, "username": email, "email": email
        }
        setSpinner(true);
        postDataResponseText("/api/user/resend_user_email", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setSpinner(false)
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (response === '') {
                    api.open({
                        message: 'Email sent successfully', icon: <img src={toastIcon} alt="toastIcon"/>, duration: 5,
                    });

                    sendDataToMixPanel('Email sent successfully', user.username, {
                        'App': 'Web App'
                    })
                }

            })
    };


    // Assign Share Modal
    const showAssignShare = (user) => {
        setAssignDrive(true);

        setSelectedUserToUpdate(user)
        var newArr = []
        for (var i = 0; i < user.drivesData.length; i++) {
            newArr.push({
                id: user.drivesData[i].permission.id,
                name: user.drivesData[i].sharedClouDrive.name,
                permissionType: user.drivesData[i].permission.permissionType,
                sharedCloudDriveId: user.drivesData[i].sharedClouDrive.id
            })
        }
        setDriveList(newArr)
    };


    // Assign Team Modal
    const showEditUser = (user) => {
        setManualUser(true);
        setEditUser(true);
        setSelectedUserToUpdate(user)
        setEmailAddress(user.email)
        setSelectedUserRole(user.userType);
    };
    const showAssignGroup = (user) => {
        setAssignGroup(true);
        setSelectedUserToUpdate(user)
        setGroupList(user.groups);

    };

    const assignGroupOk = () => {
        setAssignGroup(false);
        setSelectedUserToUpdate("")
    };

    const AssignGroupCancel = () => {
        setAssignGroup(false);
        setSelectedUserToUpdate("")
        setGroupList([])
        setNewGroupList([])
    };


    // Assign Projects Modal
    const showAssignProjects = (user) => {
        setAssignProjects(true);
        setSelectedUserToUpdate(user);
        setProjectsList(user.projectsData);
    };


    const AssignProjectsCancel = () => {
        setAssignProjects(false);
        setSelectedUserToUpdate("")
        setProjectsList([])
        setNewProjectsList([])
    };


    // Delete User Modal
    const showAssignDelete = (id) => {
        setAssignDeleteUser(true);
        setSelectedUserToUpdate(id)
    };

    const AssignDeleteCancel = () => {
        setAssignDeleteUser(false);
        setSelectedUserToUpdate("")
    };
    const assignDeleteOkUser = () => {
        var id = selectedUserToUpdate;
        deleteDataWeb('/api/user/delete_user?id=' + id)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 200) {
                    setUsersData((current) => current.filter((item) => item.id !== id));
                    setLoading(false)
                    setAssignDeleteUser(false);
                    setSelectedUserToUpdate("")
                    setTotalUsers((current) => current - 1);
                    api.open({
                        message: 'User removed successfully.',
                        description: `Your user has been removed.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                    sendDataToMixPanel('User removed successfully.', user.username, {
                        'App': 'Web App'
                    })

                }
            });
    };


    const getRole = (type) => {
        if (type === 64) {
            return 'Standard User';
        }
        if (type === 32) {
            return 'Power User';
        }
        if (type === 16) {
            return 'Admin';
        }
    };
    const getStatus = (type) => {
        if (type === 1) {
            return 'Active';
        }
        if (type === 2) {
            return 'Disabled';
        }
        if (type === 4) {
            return 'Pending';
        }
    };

    const getAllUsers = (insert = false) => {
        loadSSO()
        setDataLoaded(false)
        setLoading(true);
        allUsersData = [];
        if(!insert){
            cancelRequests();
        }

        getDataWithToken(`/api/user/get_all_users?page=${currentPage}&pagesize=${perPage}&sortfield=CreateDate&descending=${descending}${usersStatus != 0 ? `&userStatus=${usersStatus}` : ''}${userType != 0 ? `&userType=${userType}` : ''}&deleted=false${searchValForSearch !== "" ? `&username=${(encodeURIComponent(searchValForSearch.trim()))}` : ''}`)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                assignedGroupsCount = 0;
                var data = response.data;
                for (var i = 0; i < data.length; i++) {
                    data[i].groups = [];
                    data[i].projectsData = [];
                    data[i].drivesData = [];
                }
                if (data.length < 1) {
                    setDataLoaded(true)
                }
                let newArr = [...usersData]; // copying the old datas array
                while (newArr.length > 0) {
                    newArr.pop();
                }
                for (var i = 0; i < data.length; i++) {
                    newArr.push(data[i]); // replace e.target.value with whatever you want to change it to
                }
                setUsersData(newArr);
                allUsersData = data;
                setTotalUsers(response.total)
                setLoading(false);
                setAllUsersDataDone(true);

                if (data.length > 0) {
                    setTimeout(function () {
                        getUserGroups(0);
                    }, 800)
                }

            })
    };


    const getUserGroups = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allUsersData[count].id;
        }

        getDataWithToken(`/api/usergroup/get_assigned_usergroups?userId=${id}&page=1&pagesize=10000000&sortfield=UserGroup.Name&descending=true`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                var res = JSON.parse(response);
                if (single) {
                    let newArr = [...usersData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].groups = res.data;
                        }
                    }
                    setDataLoaded(true)
                } else {
                    if (res.data.length > 0) {
                        allUsersData[count].groups = res.data;
                    }
                    assignedGroupsCount++;
                    if (assignedGroupsCount < allUsersData.length) {
                        getUserGroups(assignedGroupsCount, single);
                    } else {
                        let newArr = [...usersData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allUsersData.length; i++) {
                            newArr[i] = allUsersData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setUsersData(newArr);
                        assignedGroupsCount = 0;
                        getUserProjects(0)
                    }
                }
            })
    }

    const getUserProjects = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allUsersData[count].id;
        }

        getDataWithToken(`/api/permission/get_projects_assigned_to_user?userId=${id}&page=1&pagesize=50&sortfield=Project.Name&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                var res = JSON.parse(response);
                if (single) {
                    let newArr = [...usersData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].projectsData = res.data;
                        }
                    }
                    setDataLoaded(true)
                } else {
                    allUsersData[count].projectsData = res.data;
                    assignedGroupsCount++;
                    if (assignedGroupsCount < allUsersData.length) {
                        getUserProjects(assignedGroupsCount, single);
                    } else {
                        let newArr = [...usersData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allUsersData.length; i++) {
                            newArr[i] = allUsersData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setUsersData(newArr);
                        allUsersData = newArr;
                        assignedGroupsCount = 0;
                        getUserCloudDrives(0)
                    }
                }

            })
    };
    const getUserCloudDrives = (count, single = false) => {
        var id = "";
        if (single) {
            setDataLoaded(false);
            id = count;
        } else {
            id = allUsersData[count].id;
        }
        getDataWithToken(`/api/permission/get_sharedclouddrive_assigned_to_user?userId=${id}&page=1&pagesize=50&sortfield=SharedClouDrive.Name&descending=false`)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                var res = JSON.parse(response);
                if (single) {
                    let newArr = [...usersData]; // copying the old datas array
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id === count) {
                            newArr[i].drivesData = res.data;
                        }
                    }
                    setDataLoaded(true)
                } else {
                    allUsersData[count].drivesData = res.data;
                    assignedGroupsCount++;
                    if (assignedGroupsCount < allUsersData.length) {
                        getUserCloudDrives(assignedGroupsCount, single);
                    } else {
                        let newArr = [...usersData]; // copying the old datas array
                        while (newArr.length > 0) {
                            newArr.pop();
                        }
                        for (var i = 0; i < allUsersData.length; i++) {
                            newArr[i] = allUsersData[i]; // replace e.target.value with whatever you want to change it to
                        }
                        setUsersData(newArr);
                        setDataLoaded(true)
                        assignedGroupsCount = 0;
                    }
                }
            })

    };

    // Pagination and Search
    const onChangePage = (value) => {
        setCurrentPage(value)
        setTimeout(function () {
            getAllUsers()
        }, 800)
    };
    const onChangeSort = () => {
        if (!loading) {
            setDescending(!descending);
            setTimeout(function () {
                getAllUsers()
            }, 800)
        }
    };


    const getAllProjects = () => {
        if (projectsData.length < 1) {
            getDataWithToken("/api/project/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }


                    setProjectsData(response.data)
                })
        }
    }
    const getAllGroups = () => {
        if (groupsData.length < 1) {
            getDataWithToken("/api/usergroup/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false")
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }


                    setGroupsData(response.data)
                })
        }
    }
    const getAllDrives = () => {
        if (drivesData.length < 1) {
            if (user.userType === 16) {
                console.log("/api/sharedclouddrive/get_all")
                console.log(user)
                getDataWithToken(`/api/sharedclouddrive/get_all?page=1&pagesize=10000000&sortfield=Name&descending=true&deleted=false`)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            //logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }

                        var data = response.data;
                        setDrivesData(data)
                        if (selectedDriveToInviteUser.sharedDriveId !== undefined) {
                            setSelectedDrive(selectedDriveToInviteUser.sharedDriveId)
                        }
                    })
            }
        }
    }

    useEffect(() => {
        if (user.userType === 32 || user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);
    useEffect(() => {
        if (user.userType === 16) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            getAllUsers();
            getDropdowns()
        }
    }, [user]);
    const getDropdowns = () => {
        getAllDrives();
        getAllProjects();
        getAllGroups();
    }


    const loadSSO = () => {
        getDataWithTokenSSO("/api/sso/get_entraId")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }

                if (status === 200) {
                    setAzureAD(true)
                    setSSOAvailable(true);

                    const exists = authProviderDropdown.findIndex(element => element.value === 2) > -1;

                    if (!exists) {

                        setAuthProviderDropdown(prevState => [...prevState, {value: 2, label: 'EntraID'}]);
                    }
                }
            })
        getDataWithTokenSSO("/api/sso/get_okta")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }

                if (status === 200) {
                    setOkta(true)
                    setSSOAvailable(true)
                    const exists = authProviderDropdown.findIndex(element => element.value === 4) > -1;

                    if (!exists) {
                        setAuthProviderDropdown(prevState => [...prevState, {value: 4, label: 'Okta'}]);
                    }
                }
            })
        getDataWithTokenSSO("/api/sso/get_saml")
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true)
                    return;
                }

                if (status === 200) {
                    setSaml(true)
                    setSSOAvailable(true)
                    const exists = authProviderDropdown.findIndex(element => element.value === 8) > -1;

                    if (!exists) {
                        setAuthProviderDropdown(prevState => [...prevState, {value: 8, label: 'Saml'}]);
                    }
                }
            })
    }

    return (<>{contextHolder}
        <div id="main-content">
            <div className="container-fluid mb-4">
                <div className="add-new-user-wrapper">
                    <div className="add-user-header">
                        <div className="row">
                            <div className="col-lg-3 col-md-12">
                                <div className="content-wrapper">
                                    <h2>Users</h2>
                                    <p>Manage product access for all <br/> the users in your organization.</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <ConfigurationsMenu/>
                            </div>
                            <div className="col-lg-3 col-md-12">
                                <div className="btn-wrapper message-popup">
                                    <a href="#" className={`btn-style btn-30 small-btn icon`} onClick={() => {
                                        if (userStatus.status === "active") {
                                            showSelectUser()
                                        } else {
                                            if (usersData.length < 3) {
                                                showSelectUser()
                                            } else {
                                                setBillingPopupOpen(true);
                                            }
                                        }
                                    }}>
                                        <img src={plusIcon} alt="icon"/>
                                        Add New User
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="add-user-body">
                        <div className="user-table-wrapper">
                            <div className="user-table-header">
                                <div className="row">
                                    <div className="col-md-4 align-self-center">
                                        <div className="total">
                                            <p>{totalUsers} members</p>
                                        </div>
                                    </div>
                                    <div className="col-md-8 align-self-center">
                                        <ul className={`filter ${!dataLoaded ? "disabled" : ""}`}>
                                            <li>
                                                <Popover
                                                    content={<div className="filter-wrapper">
                                                        <div className="filter">
                                                            <p>Status</p>
                                                            <Radio.Group onChange={(e) => {
                                                                setUsersStatus(e.target.value);
                                                            }} value={usersStatus}>
                                                                <Radio value="0">All</Radio>
                                                                <Radio value="1">Active</Radio>
                                                                <Radio value="2">Inactive</Radio>
                                                                <Radio value="4">Pending</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                        <div className="filter no-border">
                                                            <p>Role</p>
                                                            <Radio.Group onChange={(e) => {
                                                                setUserType(e.target.value);
                                                            }} value={userType}>
                                                                <Radio value="0">All</Radio>
                                                                <Radio value="16">Admin</Radio>
                                                                <Radio value="32">Power User</Radio>
                                                                <Radio value="64">Standard User</Radio>
                                                            </Radio.Group>
                                                        </div>
                                                        <a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            setOpen(false);
                                                            getAllUsers();
                                                        }} className="btn-style blue">Filter</a>
                                                    </div>}
                                                    trigger="click"
                                                    open={open && (userStatus.status === "active" || userStatus.status === "trial")}
                                                    onOpenChange={handleOpenChange}
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                >
                                                    <a href="#" className="btn-style transparent icon"><img
                                                        src={filterIcon} alt="icon"/> Filter</a>
                                                </Popover>

                                            </li>
                                            <li>
                                                <div className="form">
                                                    <div className="form-group">
                                                        <input type="text" className="form-control"
                                                               onChange={(e) => {
                                                                   e.preventDefault()
                                                                   setSearchValForSearch(e.target.value)
                                                               }} value={searchValForSearch}
                                                               placeholder="Search Name or Email Address"/>
                                                        <a href="#" className={`icon`} onClick={(e) => {
                                                            e.preventDefault();
                                                            if (userStatus.status === "active") {
                                                                setUsersStatus(0)
                                                                setUserType(0)
                                                                setCurrentPage(1);
                                                                setDescending(false)
                                                                getAllUsers();
                                                            }
                                                        }}> <img src={searchIcon} alt="icon"/></a>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="user-table-body">
                                <div className="table-responsive">
                                    <table className="table table-hover user-table grid-seven">
                                        <thead>
                                        <tr>
                                            <th className="flex">Users <a href="#" onClick={(e) => {
                                                e.preventDefault();
                                                onChangeSort();
                                            }}
                                                                          className={`${descending ? "arrow-down" : "arrow-up"} ${!dataLoaded ? "disabled" : ""}`}><img
                                                src={downArrowIcon} alt="icon"/></a></th>
                                            <th>Teams</th>
                                            <th>Projects</th>
                                            <th>Drives</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                        </thead>
                                        <tbody className={usersData.length > 4 ? "more-rows" : ""}>
                                        {usersData.map(userD => (<tr>
                                            <td>
                                                <div className="name-wrapper">
                                                    <div className="profile">
                                                                    <span
                                                                        className="authorName">{userD.email.substring(0, 1).toUpperCase()}</span>
                                                        <div className="content">
                                                            <p>{userD.email}</p>
                                                        </div>
                                                    </div>
                                                    {userD.email !== user.username && (userStatus.status === "active" || userStatus.status === "trial") &&
                                                        <Tooltip
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            trigger="click"
                                                            placement="bottomLeft"
                                                            className={!dataLoaded ? "disabled" : ""}
                                                            title={<ul className="view-list">
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    showEditUser(userD)
                                                                }}>Edit User</a></li>
                                                                <li><a href="#" className="red-color"
                                                                       onClick={(e) => {
                                                                           e.preventDefault();
                                                                           showAssignDelete(userD.id)
                                                                       }}>Delete User</a></li>
                                                            </ul>}
                                                        >
                                                            <a href="#" className="dots">
                                                                <img src={horizontalDotsIcon} alt="icon"/>
                                                            </a>
                                                        </Tooltip>}

                                                </div>
                                            </td>
                                            <td className="more-tooltip width-200">
                                                <p>
                                                    <span className="d-block d-md-none">Teams: </span>
                                                    {userD.groups.length > 0 && <>
                                                                <span>{userD.groups[0].userGroup.name}
                                                                </span>
                                                    {userD.groups.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {userD.groups.map((item, index) => (<>
                                                                {index > 0 &&
                                                                    <li>{item.userGroup.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{userD.groups.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className="more-tooltip width-200">
                                                <p>
                                                    <span className="d-block d-md-none">Projects: </span>
                                                    {userD.projectsData.length > 0 && <>
                                                                <span>{userD.projectsData[0].project.name}
                                                                </span>
                                                    {userD.projectsData.length > 1 && <Tooltip
                                                        getPopupContainer={trigger => trigger.parentNode}
                                                        trigger="hover"
                                                        placement="bottomRight"
                                                        title={<ul className="view-list scroll">
                                                            {userD.projectsData.map((item, index) => (<>
                                                                {index > 0 && <li>{item.project.name}</li>}
                                                            </>))}
                                                        </ul>}
                                                    ><a href="#">+{userD.projectsData.length - 1}</a>
                                                    </Tooltip>}
                                                </>

                                                }
                                                </p></td>
                                            <td className="more-tooltip width-200">
                                                <p>
                                                    <span className="d-block d-md-none">Drives: </span>
                                                    {userD.drivesData.length > 0 && <>
                                                                <span>{userD.drivesData[0].sharedClouDrive.name}
                                                                </span>
                                                        {userD.drivesData.length > 1 && <Tooltip
                                                            getPopupContainer={trigger => trigger.parentNode}
                                                            trigger="hover"
                                                            placement="bottomRight"
                                                            title={<ul className="view-list scroll">
                                                                {userD.drivesData.map((item, index) => (<>
                                                                    {index > 0 &&
                                                                        <li>{item.sharedClouDrive.name}</li>}
                                                                </>))}
                                                            </ul>}
                                                        ><a href="#">+{userD.drivesData.length - 1}</a>
                                                        </Tooltip>}
                                                    </>

                                                    }
                                                </p>
                                            </td>
                                            <td className="width-150">
                                                <span className="d-block d-md-none">Role: </span>{getRole(userD.userType)}</td>
                                            <td className="width-130">
                                                <span className="d-block d-md-none">Status: </span>{getStatus(userD.status)}</td>
                                            <td className={!dataLoaded ? "disabled" : ""}>

                                            {(userStatus.status === "active" || userStatus.status === "trial") && <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    trigger="click"
                                                    placement="bottomLeft"
                                                    title={<ul className="view-list">
                                                        {userStatus.status === "active" &&
                                                            <>
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    getDropdowns();
                                                                    showAssignGroup(userD)
                                                                }}>Add to Teams</a></li>
                                                                <li><a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    getDropdowns();
                                                                    showAssignProjects(userD)
                                                                }}>Assign Projects</a></li>

                                                            </>
                                                        }

                                                        <li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            getDropdowns();
                                                            showAssignShare(userD)
                                                        }}>Assign Drives</a></li>
                                                        {userD.status === 4 && <li>
                                                            <a href="#"
                                                               className={`${spinner ? 'loading-spinner' : ''}`}
                                                               onClick={(event) => {
                                                                   event.preventDefault();
                                                                   resendEmail(event, userD.email)
                                                               }}>
                                                                Resend Email
                                                            </a>
                                                        </li>}
                                                    </ul>}
                                                >
                                                    <a href="#" className="toltip-btn">
                                                        <img src={verticleDotsIcon} alt="icon"/>
                                                    </a>
                                                </Tooltip>}
                                            </td>
                                        </tr>))}

                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={!dataLoaded ? "disabled" : ""}>
                                {totalUsers > perPage &&
                                    <Pagination current={currentPage} total={totalUsers} pageSize={perPage}
                                                onChange={onChangePage}/>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Modal title="Add New User" className="select-user-type" centered open={isSelectUser} onOk={selectUserOk}
               onCancel={selectUserOk}>
            <div className="select-user-modal-wrapper">
                <div className="header  text-center">
                    <h3>Add New User</h3>
                </div>
                <div className="body">
                    <div className="select-wrapper text-center">
                        {azureAD && <a href="#" className="btn-style" onClick={showADUser}>Import users from AD</a>}
                        {okta &&
                            <a href="#" className="btn-style" onClick={showOktaUser}>Import users from Okta</a>}
                        <a href="#" className="btn-style" onClick={showManualUser}>Add users manually</a>
                    </div>
                </div>
            </div>
        </Modal>

        <Modal title="Add New User" className="manual-user-type" centered open={isManualUser}
               onOk={selectManualCancel} onCancel={selectManualCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    {editUser && <h3>Edit User</h3>}
                    {!editUser && <h3>Add New Users</h3>}
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">To:</p>
                                {editUser &&
                                    <input type="email" className={`form-control ${editUser ? "disabled" : ""}`}
                                           onChange={(e) => {
                                           }} value={emailAddress} placeholder="Enter email"/>}
                                {!editUser && <ReactMultiEmail
                                    emails={emails}
                                    onChange={(_emails) => {
                                        if (userStatus.status === "trial") {
                                            if ((emails.length + usersData.length) < 3) {
                                                setEmails(_emails);
                                            }
                                        } else {
                                            setEmails(_emails);
                                        }
                                    }}
                                    autoFocus={true}
                                    onFocus={() => setFocused(true)}
                                    onBlur={() => setFocused(false)}
                                    getLabel={(email, index, removeEmail) => {
                                        return (<div data-tag key={index}>
                                            <div data-tag-item>{email}</div>
                                            <span data-tag-handle
                                                  onClick={() => removeEmail(index)}><i className="fa-solid fa-xmark"></i></span>
                                        </div>);
                                    }}
                                />}
                                <span className="error">{validationError}</span>
                            </div>
                            {emailAddress !== user.username &&

                                <div className="form-group">
                                    <p className="label">Invite as</p>
                                    {/*  options={[
                                    {value: 16, label: `as`},
                                    {value: 32, label: 'Power User'},
                                    {value: 64, label: 'Standard User'}
                                ]}*/}
                                    <Select
                                        placeholder="Select role"
                                        className="user-select-role"
                                        onChange={onChangeUserRole}
                                        value={selectedUserRole}
                                        getPopupContainer={trigger => trigger.parentNode}
                                    >
                                        <Option value={16}>
                                            <div className="role-card">
                                                <p>Admin</p>
                                                <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placement="topLeft"
                                                    title="Has access to bill paying functions such as adding / removing users, adding storage or setting data tiering policies."
                                                >
                                                    <img src={info} alt="Info" className="info-icon"/>
                                                </Tooltip>
                                            </div>
                                        </Option>
                                        <Option value={32}>
                                            <div className="role-card">
                                                <p>Power User</p>
                                                <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placement="topLeft"
                                                    title="Can create and manage shares, provide access to existing users and move storage between connections. Can request additional storage and to invite new users that require admin approval."
                                                >
                                                    <img src={info} alt="Info" className="info-icon"/>
                                                </Tooltip>
                                            </div>
                                        </Option>
                                        <Option value={64}>
                                            <div className="role-card">
                                                <p>Standard User</p>
                                                <Tooltip
                                                    getPopupContainer={trigger => trigger.parentNode}
                                                    placement="topLeft"
                                                    title="Has read write access storage thats been provisioned to them by Power or Admin users. Can provision or request more storage or invite users."
                                                >
                                                    <img src={info} alt="Info" className="info-icon"/>
                                                </Tooltip>
                                            </div>
                                        </Option>
                                    </Select>
                                </div>}
                            {emailAddress !== user.username && !editUser && drivesData.length > 0 &&
                                <div className="form-group ant-select-multiple-wrapper">
                                    <p className="label">Assign Drive</p>
                                    <Select
                                        placeholder="Select a Drive"
                                        onChange={onChangeDrive}
                                        value={selectedDrive}
                                        mode="multiple"
                                        getPopupContainer={trigger => trigger.parentNode}

                                        options={drivesData.map((item) => ({
                                            label: item.name, value: item.id
                                        }))}
                                    />
                                </div>}
                            {emailAddress !== user.username && !editUser && SSOAvailable &&
                                <div className="form-group">
                                    <p className="label">Auth Provider</p>
                                    <Select
                                        defaultValue="Select Auth Provider"
                                        onChange={onChangeAuthProvider}
                                        value={authProvider}
                                        options={authProviderDropdown}
                                    />
                                </div>}
                        </div>
                    </div>
                </div>
                <div className="footer">
                    {/*<a href="#" className="btn-style transparent" onClick={selectManualCancel}>Cancel</a>*/}
                    {editUser && <Button
                        className={`btn-style blue btn-30 ${emailAddress === "" || selectedUserRole === "" || selectedUserRole === undefined ? "disabled" : ""}`}
                        loading={loading} onClick={updateUser}>Save Changes</Button>}
                    {!editUser && <Button
                        className={`btn-style blue btn-30 ${emails.length < 1 || selectedUserRole == undefined ? "disabled" : ""}`}
                        loading={loading} onClick={insertUser}>Invite</Button>}
                </div>
            </div>
        </Modal>
        <Modal title="Assign Drives" className="manual-user-type" centered open={isAssignDrive}
               onOk={AssignDriveCancel} onCancel={AssignDriveCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Assign Drive</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Drive</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a Drive"
                                        onChange={onChangeCloudDrive}
                                        value={selectedCloudDrive}
                                        options={drivesData.filter((item) => {
                                            if (driveList.filter((a) => a.sharedCloudDriveId === item.id).length < 1 && newDriveList.filter((a) => a.id === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(driveList.length > 0 || newDriveList.length > 0) && <div className="added-users">
                                    {driveList.length > 0 && driveList.map((drive, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{drive.name} <a href="#"
                                                                                                      onClick={() => {
                                                                                                          removeDriveFromList(i, drive.id)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionDrive(e, drive.id)
                                                        }} value={drive.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                    {newDriveList.length > 0 && newDriveList.map((drive, i) => (
                                        <div className="added-users-card">
                                            <div className="row">
                                                <div className="col-md-6 align-self-center">
                                                    <div className="tag-wrapper">
                                                                <span className="tag">{drive.name} <a href="#"
                                                                                                      onClick={(e) => {
                                                                                                          e.preventDefault();
                                                                                                          removeDriveFromList(i, drive, true)
                                                                                                      }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                    </div>
                                                </div>
                                                <div className="col-md-6 align-self-center">
                                                    <div className="read-write-radio">
                                                        <Radio.Group onChange={(e) => {
                                                            onChangePermissionDrive(e, drive.id, true)
                                                        }} value={drive.permissionType}>
                                                            <Radio value={1}>
                                                                Read
                                                            </Radio>
                                                            <Radio value={2}>
                                                                Read, Write
                                                            </Radio>
                                                        </Radio.Group>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>))}
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={AssignDriveCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue btn-30 ${newDriveList.length > 0 || driveList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignCloudDrive}>Assign</Button>
                </div>
            </div>
        </Modal>

        <Modal title="Add to Team" className="manual-user-type" centered open={isAssignGroup} onOk={assignGroupOk}
               onCancel={assignGroupOk}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Add to Team</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Team</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a Team"
                                        onChange={onChangeGroup}
                                        value={selectedGroup}
                                        options={groupsData.filter((item) => {
                                            if (groupList.filter((a) => a.userGroup.id === item.id).length < 1 && newGroupList.filter((a) => a.id === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                    {/*  <a href="#" className="add" onClick={addGroup}><img src={pluscon} alt="icon"/></a>*/}
                                </div>
                                {(groupList.length > 0 || newGroupList.length > 0) && <div className="added-users">
                                    <ul className="selected-tags">
                                        {groupList.map((group, i) => (<li>
                                            <div className="tag-wrapper">
                                                            <span className="tag">{group.userGroup.name} <a href="#"
                                                                                                            onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                removeGroupFromList(i, group.userUserGroup.id)
                                                                                                            }}><img
                                                                src={crossIcon} alt="icon"/></a></span>
                                            </div>
                                        </li>))}
                                        {newGroupList.map((group, i) => (<li>
                                            <div className="tag-wrapper">
                                                            <span className="tag">{group.name}
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    removeGroupFromList(i, group.id, true)
                                                                }}><img src={crossIcon} alt="icon"/></a>
                                                            </span>
                                            </div>
                                        </li>))}
                                    </ul>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={AssignGroupCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue btn-30 ${newGroupList.length > 0 || groupList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={addGroupSave}>Add</Button>
                </div>
            </div>
        </Modal>

        <Modal title="Assign Projects" className="manual-user-type" centered open={isAssignProjects}
               onOk={AssignProjectsCancel} onCancel={AssignProjectsCancel}>
            <div className="manual-user-modal-wrapper">
                <div className="header">
                    <h3>Assign Projects</h3>
                </div>
                <div className="body">
                    <div className="search-wrapper">
                        <div className="form">
                            <div className="form-group">
                                <p className="label">Project</p>
                                <div className="add-share">
                                    <Select
                                        showSearch
                                        placeholder="Select a Team"
                                        onChange={onChangeProject}
                                        value={selectedProject}
                                        options={projectsData.filter((item) => {
                                            if (projectsList.filter((a) => a.project.id === item.id).length < 1 && newProjectsList.filter((a) => a.id === item.id).length < 1) {
                                                return item
                                            }
                                        }).map((item) => ({
                                            label: item.name, value: item.id,
                                        }))}
                                    />
                                </div>
                                {(projectsList.length > 0 || newProjectsList.length > 0) &&
                                    <div className="added-users">
                                        {projectsList.length > 0 && projectsList.map((project, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{project.project.name} <a href="#"
                                                                                                                onClick={() => {
                                                                                                                    removeProjectFromList(i, project.permission.id)
                                                                                                                }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="read-write-radio">
                                                            <Radio.Group onChange={(e) => {
                                                                onChangePermission(e, project.permission.id)
                                                            }} value={project.permission.permissionType}>
                                                                <Radio value={1}>
                                                                    Read
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Read, Write
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                        {newProjectsList.length > 0 && newProjectsList.map((project, i) => (
                                            <div className="added-users-card">
                                                <div className="row">
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="tag-wrapper">
                                                                <span className="tag">{project.name} <a href="#"
                                                                                                        onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            removeProjectFromList(i, project, true)
                                                                                                        }}><img
                                                                    src={crossIcon} alt="icon"/></a></span>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6 align-self-center">
                                                        <div className="read-write-radio">
                                                            <Radio.Group onChange={(e) => {
                                                                onChangePermission(e, project.id, true)
                                                            }} value={project.permissionType}>
                                                                <Radio value={1}>
                                                                    Read
                                                                </Radio>
                                                                <Radio value={2}>
                                                                    Read, Write
                                                                </Radio>
                                                            </Radio.Group>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>))}
                                    </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <a href="#" className="btn-style transparent btn-30" onClick={AssignProjectsCancel}>Cancel</a>
                    <Button
                        className={`btn-style blue btn-30 ${newProjectsList.length > 0 || projectsList.length > 0 ? "" : "disabled"}`}
                        loading={loading} onClick={AssignUserToProject}>Assign</Button>
                </div>
            </div>
        </Modal>
        <DeleteModal title="Delete User" text="Are you sure you want to delete this user?"
                     deleteOpen={isAssignDeleteUser} deleteCancel={AssignDeleteCancel}
                     deleteSuccess={assignDeleteOkUser} loading={loading}/>
        <BillingPopup></BillingPopup>
    </>)
}

export default Users;

