import React, {useEffect, useState, useRef} from "react";
import uploadIcon from "../assets/images/icons/upload-icon.svg";
import dotsIcon from "../assets/images/icons/3-dots-icon.svg";
import {useNavigate} from "react-router-dom";
import linkIcon from "../assets/images/icons/link-icon.svg";
import downloadIcon from "../assets/images/icons/download-icon.svg";
import deleteIcon from "../assets/images/icons/delete-icon.svg";
import uploadNewIcon from "../assets/images/icons/upload-icon-new.svg";
import uploadIconNew from "../assets/images/icons/upload-icon-new.svg";
import crossIcon from "../assets/images/icons/cross-icon.svg";
import fileIconFile from "../assets/images/icons/file-icon-file.svg";
import settingIcon from "../assets/images/icons/file-manager-setting-icon.svg";
import pinnedIcon from "../assets/images/icons/file-manager/pinned-item-icon.svg";
import deletedIcon from "../assets/images/icons/file-manager/deleted-files-icon.svg";
import requestIcon from "../assets/images/icons/file-manager/request-files-icon.svg";
import folderInfoIcon from "../assets/images/icons/file-manager/folder-info-icon.svg";
import folderActivityIcon from "../assets/images/icons/file-manager/folder-activity-icon.svg";
import uploadActivityIcon from "../assets/images/icons/file-manager/upload-icon.svg";
import moveActivityIcon from "../assets/images/icons/file-manager/move-icon.svg";
import copyActivityIcon from "../assets/images/icons/file-manager/copy-icon.svg";
import selectedActivityIcon from "../assets/images/icons/file-manager/selected-minus-icon.svg";
import downloadActivityIcon from "../assets/images/icons/download-icon.svg";
import deleteActivityIcon from "../assets/images/icons/file-manager/bascket-icon.svg";
import threeDotsIcon from "../assets/images/icons/3-dots-icon.svg";
import {useLocation} from 'react-router-dom';
import {NavLink} from "react-router-dom";

import {Button, Modal, notification, Popconfirm, Spin, Tooltip, Checkbox} from "antd";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import folderIcon from "../assets/images/icons/file-icon.svg";
import {LoadingOutlined} from "@ant-design/icons";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import syncIcon from "../assets/images/icons/sync-icon.svg";
import editIcon from "../assets/images/icons/edit-white-icon.svg";
import plusIcon from "../assets/images/plus-white-icon.svg";
import {FileUploader} from "react-drag-drop-files";
import leftArrowIcon from "../assets/images/left-arrow-orange-icon.svg";


const FileManagerNew = () => {
    const {
        cloudAccounts,
        logout,
        postDataWebNoResponse,
        isWindows,
        getIcon,
        postData,
        getDataWithTokenPOST,
        postDataNoResponse,
        agentIsRunning,
        prettySize,
        getFormatedDate,
        token,
        limitExceeded,
        activityDataDirects,
        reloadFiles,
        setReloadFiles,
        sendDataToWebsocket,
        user,
        getDataMethodGet,
        setPopup500,
        postDataNewFolderWeb,
        setError499,
        setPopup499,
        backButton,
        setBackButton,
        getRegions,
        getCloudAccounts,
        userStatus
    } = useAuth();
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const clipboardText = useRef("");
    const dataFetchedRefDrives = useRef(false);
    const dataFetchedRef1 = useRef(false);
    const [driveMenu, setDriveMenu] = useState(false);
    const [listView, setListView] = useState(true);
    const [gridView, setGridView] = useState(false);
    const [step, setStep] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [checkedGrid, setCheckedGrid] = useState(false);
    const [buckets, setBuckets] = useState([]);
    const [filteredBuckets, setFilteredBuckets] = useState([]);
    const [showPrefix, setShowPrefix] = useState(false);
    const [showData, setShowData] = useState(false);
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(true);
    const [prefix, setPrefix] = useState("");
    const [path, setPath] = useState("");
    const [bucket, setBucket] = useState("");
    const [continuationToken, setContinuationToken] = useState("");
    const [modalData, setModalData] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [note, setNote] = useState("");
    const [previousPath, setPreviousPath] = useState([]);
    const [previousPathFiles, setPreviousPathFiles] = useState([]);
    const [previousPathIds, setPreviousPathIds] = useState([]);
    const [prevFolderID, setPrevFolderID] = useState("");
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openToolTipID, setOpenToolTipID] = useState(null);
    const [result, setResult] = useState([]);
    const [showErrorEmailWrong, setShowErrorEmailWrong] = useState(false);
    const [loadingCopyLink, setLoadingCopyLink] = useState(false);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const [loadingDelete, setLoadingDelete] = useState(false);
    const [loadingShare, setLoadingShare] = useState(false);
    const [tooptipVal, setTooptipVal] = useState(false);
    const [tooptipIndex, setTooptipIndex] = useState(false);
    const [folderName, setFolderName] = useState("");
    const [api, contextHolder] = notification.useNotification();
    const [localDrives, setLocalDrives] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [selectedPath, setSelectedPath] = useState("");
    const [selectedFile, setSelectedFile] = useState("");
    const [newFolderName, setNewFolderName] = useState("");
    const [totalChecked, setTotalChecked] = useState(0);
    const [limitExceed, setLimitExceed] = useState(false);
    const [file, setFile] = useState(null);
    const [selectedBucketId, setSelectedBucketId] = useState("");
    const [selectedFolderName, setSelectedFolderName] = useState("");
    const [selectedFolderId, setSelectedFolderId] = useState("");
    const [parentID, setParentID] = useState("");
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const location = useLocation();
    const dataFetchedRefPrams = useRef(false);

    useEffect(() => {
        if (user.userType === 64) {
            navigate("/dashboard");
            return;
        }
    }, [user]);

    useEffect(() => {
        if (token !== undefined && token !== null && userStatus.status !== undefined) {
            if (dataFetchedRef1.current) return;
            dataFetchedRef1.current = true;
            getRegions();
            getCloudAccounts();
        }
    }, [token, userStatus]);
    useEffect(() => {
        if (cloudAccounts.length > 0) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            const params = new URLSearchParams(location.search);
            console.log(params.get('cloud-account'))
            console.log(params.get('bucket-name'))
            console.log(params.get('bucket-id'))
            var cAccount = params.get('cloud-account')
            var bName = params.get('bucket-name')
            var bId = params.get('bucket-id')
            if (cAccount !== null && bName !== null) {
                setContinuationToken("");
                setBucket('');
                setPath('');
                setPreviousPathFiles('');
                setPreviousPathIds('');
                setLoading(false);
                setLoadingMore(false)
                setShowData(false)
                let objIndex = cloudAccounts.findIndex(
                    (item) => item.id === cAccount
                );
                setSelectedCloudAccount(cloudAccounts[objIndex])
                setSelectedBucketId(bId !== null ? bId : "")
                setContinuationToken("");
                setPath('');
                setPreviousPathFiles('');
                setPreviousPathIds('');
                setBucket(bName);
                setBackButton(true);
                setStep(3)
                setTimeout(() => {
                    console.log(cloudAccounts[objIndex].buckets)
                    setBuckets(cloudAccounts[objIndex].buckets);
                    setFilteredBuckets(cloudAccounts[objIndex].buckets);
                }, 1500)
            }
        }
    }, [cloudAccounts]);

    useEffect(() => {
        if (reloadFiles) {
            setReloadFiles(false)
            reload()
        }
    }, [reloadFiles]);

    async function postDataReturnTextNew(url = "", data = {}, t = false) {
        var link = config.apiURL + url;
        const response = await fetch(link, {
            method: "POST",
            headers: {
                "X-App-Origin": "WebApp"
            },
            body: data,
        })
        return response;
    }

    const handleChange = (files) => {
        if (!limitExceed) {

            // Convert FileList to Array
            const fileArray = Array.from(files);

            // Log each file to the console
            fileArray.forEach((file, index) => {
                setFile(file);
                setUploadingFiles(oldArray => [...oldArray, file.name]);
                var uploadFilePath = "";
                if (path !== "") {
                    uploadFilePath = path;
                }
                const formData = new FormData();
                formData.append("File", file);
                formData.append("CloudAccountJson", JSON.stringify({
                    "id": selectedCloudAccount.id,
                    "accountId": selectedCloudAccount.accountId,
                    "cloudType": selectedCloudAccount.cloudType,
                    "name": selectedCloudAccount.name,
                    "accessKey": selectedCloudAccount.accessKey,
                    "secretKey": selectedCloudAccount.secretKey,
                    "credentialsData": selectedCloudAccount.credentialsData,
                    "serviceUrl": selectedCloudAccount.serviceUrl,
                    "active": selectedCloudAccount.active,
                    "deleted": selectedCloudAccount.deleted
                }))
                formData.append("StorageName", bucket);
                formData.append("UploadPath", uploadFilePath);
                formData.append("id", parentID);
                postDataReturnTextNew('/Cloud/upload_object_from_form', formData)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var res = JSON.parse(response)
                            var errorType = res.type;
                            setError499(res.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setSelectedFile("");
                        setSelectedPath("");
                        if (isWindows) {
                            if(document.getElementById('select-drive-0') !== null){
                                document.getElementById('select-drive-0').checked = true;
                            }
                        }
                        if(index === fileArray.length - 1) {
                            api.open({
                                message: 'File is uploading.',
                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                duration: 3,
                            });
                        }
                    });
            });
        }
    }


    const [open, setOpen] = useState(false);
    const [newFolderPopup, setNewFolderPopup] = useState(false);
    const antIcon = <LoadingOutlined style={{fontSize: 40}} spin/>;

    const onDriveMenu = () => {
        setDriveMenu(!driveMenu);
    }

    const tooltipOpen = (e, i) => {
        setTooptipVal(e)
        setTooptipIndex(i)
    }

    const onChangeCloud = (value) => {
        setContinuationToken("");
        setBucket('');
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setLoading(false);
        setLoadingMore(false)
        setShowData(false)
        var selectedAccount = cloudAccounts.filter(x => x.id === value)[0];
        setSelectedCloudAccount(selectedAccount);
        if (selectedCloudAccount.id === limitExceeded) {
            setLimitExceed(true)
        } else {
            setLimitExceed(false)
        }
        getBuckets(selectedAccount)
    };


    const selectCloudDrive = (event) => {
        event.preventDefault();
        const id = event.target.value;
        const index = event.target.getAttribute('data-index');
        const logo = event.target.getAttribute('data-name');
        setContinuationToken("");
        setBucket('');
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setLoading(false);
        setLoadingMore(false)
        setShowData(false)
        getBuckets(cloudAccounts[index])
        setSelectedCloudAccount(cloudAccounts[index])
        if (cloudAccounts[index].id === limitExceeded) {
            setLimitExceed(true)
        } else {
            setLimitExceed(false)
        }
        setStep(2)
    }


    const changeCloudDrive = (event) => {
        event.preventDefault();
        setBucket("")
        setBuckets("")
        setFilteredBuckets("")
        setStep(1)
    };

    const changeBucket = (event) => {
        event.preventDefault();
        if (buckets.length === 0) {
            getBuckets(selectedCloudAccount)
        }
        setBucket("")
        setStep(2)
    };

    const onChangeBucket = (event) => {
        const value = event.target.value;
        setSelectedBucketId(event.target.dataset.id)
        setContinuationToken("");
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setBucket(value);
        setStep(3)
    };
    const filterBuckets = (event) => {
        const value = event.target.value;
        setSearchValue(value)
        if (value === "") {
            setFilteredBuckets(buckets)
        } else {
            setFilteredBuckets(buckets.filter((item) => (
                item.name.toLowerCase().includes(value.toLocaleString())
            )))
        }
    };

    const onChange = (value) => {
        setContinuationToken("");
        setPath('');
        setPreviousPathFiles('');
        setPreviousPathIds('');
        setBucket(value);
    };


    function getDirectories(e) {
        setSelectedPath(e.target.value)
        getDataMethodGet('/Management/list_files_directories?path=' + encodeURIComponent(e.target.value))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setPreviousPath(e.target.value)
                setLocalDir(response)
            })
    }

    function getDirectoriesMac() {
        getDataMethodGet('/Management/list_files_directories?path=/')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    function changeDir(e, item) {
        const val = e.target.getAttribute('data-value');
        const elements = document.querySelectorAll('.directory-list .form-group')
        elements.forEach((element) => {
            element.classList.remove('active');
        });
        e.target.parentNode.classList.add('active');
        if (item.objectType == 1) {
            setSelectedFile(item.fullPath)
        } else {
            setSelectedFile('')
            setSelectedPath(val)
            if (e.detail == 2) {
                getDataMethodGet('/Management/list_files_directories?path=' + encodeURIComponent(val))
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setPreviousPath(val)
                        setLocalDir(response)

                        const elements = document.querySelectorAll('.directory-list .form-group')

                        elements.forEach((element) => {
                            element.classList.remove('active');
                        });
                    })
            }
        }
    }

    function getBuckets(selectedAccount) {
        getDataWithTokenPOST("/api/desktop/list_buckets", {
            "cloudAccountId": selectedAccount.id,
            "includeRegion": true
        }).then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                    if (status === 401) {
                        // logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setBuckets(response);
                    setFilteredBuckets(response);
                }
            );
    }

    function getFilename(fullPath) {
        var parts = fullPath.split(/[/\\]/);
        return parts.pop();
    }

    useEffect(() => {
        var aDirects = [...activityDataDirects]
        for (var i = 0; i < aDirects.length; i++) {
            aDirects[i].active = false;
        }

        let uFiles = [...uploadingFiles];
        aDirects.map((item, i) => {
            item.files.map(file => {
                if (uFiles.includes(getFilename(file.path))) {
                    if (file.status === 3) {
                        reload();
                        let newArr = [...uploadingFiles];
                        for (let i = 0; i < newArr.length; i++) {
                            if (newArr[i] === getFilename(file.path)) {
                                newArr.splice(i, 1);
                            }
                        }
                        setUploadingFiles(newArr)
                    }
                }
            })
        })


    }, [activityDataDirects])

    useEffect(() => {
        if (dataFetchedRef.current) return;
        dataFetchedRef.current = true;
        setContinuationToken("")
        if (!isWindows) {
            getDirectoriesMac()
        }
    }, [])
    useEffect(() => {
        if (path !== "") {
            getListObjects()
        }
    }, [path])

    useEffect(() => {
        if (path === "") {
            getListObjects()
        }
    }, [bucket])

    useEffect(() => {
        if (continuationToken === "") {
            setLoading(true);
            getListObjects()
        }
    }, [continuationToken])

    useEffect(() => {
        if (dataFetchedRefDrives.current) return;
        dataFetchedRefDrives.current = true;
        if (localDrives.length < 1) {
            getdrives()
        }
    }, [localDrives])


    function getdrives() {
        getDataMethodGet('/Management/list_drives')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDrives(response)
            })
    }

    function getListObjects(loadmore = false) {

        var pathName = '/'
        if (prefix !== '' && showPrefix) {
            pathName = prefix;
        }

        if (path !== '') {
            pathName = path;
        }
        var ct = ''
        if (continuationToken !== '' && loadmore) {
            ct = continuationToken;
            setLoadingMore(true)
        } else {
            setLoading(true);
        }
        var id = ""
        if (pathName === '/') {
            id = selectedBucketId;
        } else {
            id = selectedFolderId;
        }
        if (prevFolderID !== "" && prevFolderID !== null) {
            if (previousPathIds.length > 0) {
                id = previousPathIds[previousPathIds.length - 1];
            }
            setPrevFolderID("")
        }
        setParentID(id)
        /* if (selectedCloudAccount.cloudIconType === 32) {
             if (pathName === '/') {
                 pathName = selectedBucketId;
             }
             pathName = selectedBucketId;
             if (path !== '') {
                 pathName = path;
             }
             if (pathName === '/' && path === '/') {
                 pathName = selectedBucketId;
             }
         }*/

        if (bucket !== '') {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccount.id,
                "bucketName": bucket,
                "id": id,
                "path": pathName,
                "continuationToken": ct,
                "count": 50
            }
            console.log(dataToPost)
            getDataWithTokenPOST('/api/desktop/list_objects', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    setLoading(false);
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    for (var i = 0; data.data.length > i; i++) {
                        data.data[i].checked = false;
                    }
                    data.data.sort((a, b) => {
                        return b.type - a.type;
                    })
                    if (ct !== '' && ct !== null) {
                        setResult(old => [...old, ...data.data]);

                    } else {
                        setResult(data.data);
                    }
                    setContinuationToken(data.continuationToken);
                    setLoadingMore(false)
                    setShowData(true)
                });
        } else {
            setLoading(false);
            setLoadingMore(false)
        }
    }

    function goBackFiles() {
        var val = ""
        if (isWindows) {
            if (previousPath.lastIndexOf("\\") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("\\") + 1);
            }
        } else {
            if (previousPath.lastIndexOf("/") > 2) {
                val = previousPath.substring(0, previousPath.lastIndexOf("/"));
            } else {
                val = previousPath.substring(0, previousPath.lastIndexOf("/") + 1);
            }
        }
        const elements = document.querySelectorAll('.directory-list .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });


        setPreviousPath(val)
        setSelectedPath(val)
        setSelectedFile("")
        getDataMethodGet('/Management/list_files_directories?path=' + encodeURIComponent(val))
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLocalDir(response)
            })
    }

    function loadMore(e) {
        e.preventDefault()
        getListObjects(true)
    }

    const showModal = (data) => {
        setModalData(data)
        setIsModalOpen(true);
    };

    const showNewFolderPopup = () => {
        setNewFolderPopup(true);
    }
    const showModalFiles = () => {
        if (!limitExceed) {
            setOpen(true);
        }
        if (selectedPath !== '') {
            setPreviousPath(selectedPath)
            getDataMethodGet('/Management/list_files_directories?path=' + encodeURIComponent(selectedPath))
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    setLoading(false);
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);


                    setTimeout(function () {
                        const elements = document.querySelectorAll('.directory-list .form-group')
                        elements.forEach((element) => {
                            element.classList.remove('active');
                            var fName = '';
                            if (isWindows) {
                                fName = selectedFile.substring(selectedFile.lastIndexOf("\\") + 1, selectedFile.length)
                            } else {
                                fName = selectedFile.substring(selectedFile.lastIndexOf("/") + 1, selectedFile.length)
                            }
                            if (element.innerText === fName) {
                                element.classList.add('active');
                            }
                        });
                    }, 200)
                })
        } else {
            var dirListPath = '/';
            if (isWindows) {
                setPreviousPath(localDrives[0])
                dirListPath = encodeURIComponent(localDrives[0]);
            } else {
                setPreviousPath('/');
            }
            getDataMethodGet('/Management/list_files_directories?path=' + dirListPath)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setLocalDir(response);
                    if (isWindows) {
                        document.getElementById('select-drive-0').checked = true;
                    }
                })
        }
    };
    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
        setShowErrorEmailWrong(false);
        setEmailAddress('');
        setNote('');
    };

    const onListView = (e) => {
        e.preventDefault()
        setListView(true);
        setGridView(false);
    };
    const onGridView = (e) => {
        e.preventDefault()
        setGridView(true);
        setListView(false);
    };

    const onCheckbox = (e) => {
        setCheckedGrid(e.target.checked)
    };

    const delectObject = (item, index) => {
        setLoadingDelete(true)
        let newArr = [...result];
        for (let i = 0; i < newArr.length; i++) {
            if (index === i) {
                newArr.splice(i, 1);
            }
        }
        var dataToPost = {
            "cloudAccountId": selectedCloudAccount.id,
            "storageName": bucket,
            "key": item.path,
            "id": item.id
        }
        postDataWebNoResponse('/api/desktop/delete_object', dataToPost, true)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, response]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var res = JSON.parse(response)
                    var errorType = res.type;
                    setError499(res.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setLoadingDelete(false);
                closeAllToolTips();
                setResult(newArr);
            });
    };
    const delectAllObject = () => {
        setLoadingDelete(true)
        var resData = result;
        for (var i = 0; i < resData.length; i++) {
            if (resData[i].checked) {
                var dataToPost = {
                    "cloudAccountId": selectedCloudAccount.id,
                    "storageName": bucket,
                    "key": resData[i].path,
                    "id": resData[i].id
                }
                postDataWebNoResponse('/api/desktop/delete_object', dataToPost, true)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var res = JSON.parse(response)
                            var errorType = res.type;
                            setError499(res.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setLoadingDelete(false);
                        closeAllToolTips();
                    });
            }
        }
        let newArr = [...result];
        setResult(newArr.filter((item) => (!item.checked)));
    };

    const handleOkFiles = () => {
        closePopup()
    };

    const closeFilePopup = () => {
        setNewFolderPopup(false)
    };

    function closePopup() {
        setOpen(false);
        /*setSelectedFile("");
        setSelectedPath("");*/
        const elements = document.querySelectorAll('.directory-list .form-group')

        elements.forEach((element) => {
            element.classList.remove('active');
        });

    }

    const uploadFile = () => {
        closePopup()
        if (!limitExceed) {
            var uploadFilePath = "";
            if (path !== "") {
                uploadFilePath = path;
            }
            var dataToPost = {
                "cloudAccount": {
                    "id": selectedCloudAccount.id,
                    "accountId": selectedCloudAccount.accountId,
                    "cloudType": selectedCloudAccount.cloudType,
                    "name": selectedCloudAccount.name,
                    "accessKey": selectedCloudAccount.accessKey,
                    "secretKey": selectedCloudAccount.secretKey,
                    "credentialsData": selectedCloudAccount.credentialsData,
                    "serviceUrl": selectedCloudAccount.serviceUrl,
                    "active": selectedCloudAccount.active,
                    "deleted": selectedCloudAccount.deleted
                },
                "storageName": bucket,
                "uploadPath": uploadFilePath,
                "localFilePath": selectedFile,
                "id": parentID,
            };
            postDataNoResponse('/Cloud/upload_object', dataToPost, true)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var res = JSON.parse(response)
                        var errorType = res.type;
                        setError499(res.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    api.open({
                        message: 'File is uploading.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3,
                    });
                    setSelectedFile("");
                    setSelectedPath("");
                    if (isWindows) {
                        document.getElementById('select-drive-0').checked = true;
                    }
                });
        }
    };

    const handleCancelFiles = () => {
        closePopup();
    };

    function closeAllToolTips() {
        document.querySelectorAll('.upload-option .menu').forEach(function (item) {
            item.classList.remove('ant-tooltip-open')
        })
        document.querySelectorAll('.upload-option .ant-tooltip').forEach(function (item) {
            item.classList.add('ant-tooltip-hidden')
        })

        setTooptipVal(false)
        setTooptipIndex(false)
    }

    const downloadObject = (item, d = false, downloadAllData = [], index = 0) => {
        if (d) {
            setLoadingDownload(true)
        } else {
            setLoadingCopyLink(true)
        }
        console.log(item)
        var dataToPost = {
            "cloudAccountId": selectedCloudAccount.id,
            "storageName": bucket,
            "key": item.path,
            "expireHours": 24,
            "forceToDownload": true,
            "id": item.id
        }
        var itemsDone = index;
        postDataWebNoResponse('/api/desktop/generate_download_url', dataToPost, true)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                setLoadingDownload(false)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    setLoadingCopyLink(false)
                    return;
                }
                if (status === 499) {
                    var res = JSON.parse(data)
                    var errorType = res.type;
                    setError499(res.errors[errorType][0])
                    setPopup499(true)
                    setLoading(false);
                    setLoadingCopyLink(false)
                    return;
                }
                data = data.replace(/['"]+/g, '')

                if (data !== '') {
                    if (d) {
                        itemsDone++;
                        download(data, item.name, downloadAllData,itemsDone)
                    } else {
                        var dataToPost = {
                            "cloudAccountId": selectedCloudAccount.id,
                            "storageName": bucket,
                            "key": item.path,
                            "expireHours": 24,
                            "forceToDownload": false,
                            "id": item.id
                        }
                        postDataWebNoResponse('/api/desktop/generate_download_url', dataToPost, true)
                            .then((res) => Promise.all([res.status, res.text()]))
                            .then(([status, down]) => {
                                setLoadingCopyLink(false);
                                if (status === 401) {
                                    logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    var res = JSON.parse(down)
                                    var errorType = res.type;
                                    setError499(res.errors[errorType][0])
                                    setPopup499(true)
                                    return;
                                }
                                down = down.replace(/['"]+/g, '')
                                if (down !== '') {
                                    var newLink = 'https://www.amove.io/files/?url=' + encodeURIComponent(down) + '&download=' + encodeURIComponent(data);


                                    if (navigator.userAgent.search("Safari") >= 0 && navigator.userAgent.search("Chrome") < 0)
                                    {
                                        clipboardText.current = newLink;
                                        setTimeout(() => {
                                        var copyText = document.getElementById("myInput");
                                        // Select the text field
                                        copyText.select();
                                        copyText.setSelectionRange(0, 99999); // For mobile devices

                                        console.log(copyText.value)
                                        // Copy the text inside the text field
                                        navigator.clipboard.writeText(copyText.value);
                                            api.open({
                                                message: 'File link is copied.',
                                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                                duration: 3,
                                            });
                                    }, 1000)

                                    }else{
                                        navigator.clipboard.writeText(newLink);
                                        api.open({
                                            message: 'File link is copied.',
                                            icon: <img src={toastIcon} alt="toastIcon"/>,
                                            duration: 3,
                                        });
                                    }
                                }
                            });
                    }
                }
            });
    };

    function downloadAll() {
        var resData = result;
        var dataToDownload = [];
        for (var i = 0; i < resData.length; i++) {
            if (resData[i].checked) {
                dataToDownload.push(resData[i])
            }
        }

        downloadObject(dataToDownload[0], true, dataToDownload, 0)
    }
    function download(url, filename,downloadAllData,itemsDone) {
        const link = document.createElement("a");
        link.href = url;
        link.download = filename;
        link.click();
        if(downloadAllData.length > 0 && itemsDone < downloadAllData.length){
            setTimeout(()=>{
                downloadObject(downloadAllData[itemsDone], true, downloadAllData, itemsDone)
            }, 2000)
        }
    }

    const sendDownloadObject = () => {
        setLoadingShare(true)
        var email = emailAddress;
        var dataToPost = {
            "email": email,
            "cloudAccountId": selectedCloudAccount.id,
            "storageName": bucket,
            "key": modalData.path,
            "expireHours": 24,
            "note": note
        }

        if (checkEmail(email)) {
            postDataWebNoResponse('/api/desktop/send_download_url', dataToPost, true)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var res = JSON.parse(response)
                        var errorType = res.type;
                        setError499(res.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    setEmailAddress('');
                    setModalData('');
                    setIsModalOpen(false);
                    setLoadingShare(false)
                    api.open({
                        message: 'File link is sent to ' + email + '.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3,
                    });
                });
        } else {
            setLoadingShare(false)
        }
    };


    const checkEmail = (email) => {
        var checkEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
        if (checkEmail.test(email) === true) {
            setShowErrorEmailWrong(false)
            return true;
        } else {
            setShowErrorEmailWrong(true)
            return false;
        }
    }


    const onChangeEmailAddress = (event) => {
        const name = event.target.value;
        setEmailAddress(name)
    }
    const onChangeNote = (event) => {
        const name = event.target.value;
        setNote(name)
    }
    const updateCheck = (index, checked) => {
        let newArr = [...result];
        newArr[index].checked = !checked
        setResult(newArr);
    }
    const uncheckAll = () => {
        let newArr = [...result];
        for (var i = 0; i < newArr.length; i++) {
            newArr[i].checked = false
        }
        setResult(newArr);
    }

    useEffect(() => {
        if (result.length > 0) {
            var total = 0;
            for (var i = 0; i < result.length; i++) {
                if (result[i].checked) {
                    total++;
                }
            }
            setTotalChecked(total);
        }
    }, [result])

    function changeObject(e) {
        const val = e.target.getAttribute('data-value');
        const type = e.target.getAttribute('data-folder');
        const folderName = e.target.getAttribute('data-name');
        const folderId = e.target.getAttribute('data-id');

        if (e.detail == 2) {
            if (type == 2) {
                if (path === '') {
                    setPreviousPathFiles(oldArray => [...oldArray, '/']);
                } else {
                    setPreviousPathFiles(oldArray => [...oldArray, path]);
                }
                setPreviousPathIds(oldArray => [...oldArray, folderId]);
                setPath(val);
                setSelectedFolderName(folderName);
                setSelectedFolderId(folderId);
            }
        }
    }

    function goBack() {
        setLoading(true)
        setContinuationToken("")
        setPreviousPathIds((previousArr) => (previousArr.slice(0, -1)));
        setPrevFolderID(previousPathIds[previousPathIds.length - 1])
        setPath(previousPathFiles[previousPathFiles.length - 1])
        setPreviousPathFiles((previousArr) => (previousArr.slice(0, -1)));
    }

    function reload() {
        setContinuationToken("");
    }

    const [isFileDeleteModalOpen, setisFileDeleteModalOpen] = useState(false);

    const showFileDeleteModal = () => {
        setisFileDeleteModalOpen(true);
    };

    const FileDeleteOk = () => {
        setisFileDeleteModalOpen(false);
    };

    const FileDeleteCancel = () => {
        setisFileDeleteModalOpen(false);
    };
    const createNewFolder = () => {
        if (validFolderName(newFolderName)) {
            var dataToPost = {
                "cloudAccountId": selectedCloudAccount.id,
                "storageName": bucket,
                "path": path,
                "folderName": newFolderName,
                "id": parentID,
            };
            setLoading(true)
            postDataNewFolderWeb("/api/desktop/create_folder", dataToPost)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    setLoading(false)
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        closeFilePopup();
                        reload()
                        return;
                    }
                    if (status === 499) {
                        var res = JSON.parse(data)
                        var errorType = res.type;
                        setError499(res.errors[errorType][0])
                        setPopup499(true)
                        closeFilePopup();
                        reload()
                        return;
                    }
                    if (data === "") {
                        setNewFolderName("");
                        closeFilePopup();
                        reload()
                        api.open({
                            message: `${newFolderName} folder created successfully.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 3,
                        });
                    }
                    setLoading(false)
                });
        }

    };
    const validFolderName = (folder) => {

        var regularExp = /^(?!\/)([A-Za-z0-9\-\_\.]+\/)*[A-Za-z0-9\-\_\.]+(?!\/)$/;
        return regularExp.test(folder);
    };


    return (
        <>
            {contextHolder}
            <div id="main-content" className="dashboard-new-page">

                <input type="text" value={clipboardText.current} id="myInput" className="d-none"/>
                <div className="container-fluid">
                    <div className="main-content-body dashboard-home  add-cloud-account-page">
                        <div className="cloud-brands-wrapper choose-cloud-wrapper">
                            <div className="left-content">
                                {/*<div className="files-title-wrapper">
                                     <h3 className="larger mb-3">File Manager</h3>
                                    <input type="text" className="form-control search-icon" placeholder="Search"/>
                                </div>*/}
                                {backButton &&
                                    <div className="back-btn-wrapper">
                                        <NavLink to="/buckets" className="back-btn"><img src={leftArrowIcon} alt="icon"/> Back</NavLink>
                                    </div>
                                }
                                {step === 1 &&
                                    <div className="drive-brands">
                                        <div className="row">
                                            <div className="col-md-4 col-12 align-self-center">
                                                <div className={`choose-cloud-counter`}>
                                                    <div className="content-wrapper">
                                                        <h3>Choose a Cloud</h3>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form">
                                            <div className="choose-cloud-card-wrapper">
                                                {cloudAccounts.length > 0 &&
                                                    cloudAccounts.map((item, i) => (
                                                        <div className="form-group" key={item.id}>
                                                            <label className="choose-cloud-card">
                                                                <input type="radio" id="choose-cloud-1" value={item.id}
                                                                       data-index={i} data-name={item.name}
                                                                       name="choose-cloud" onChange={selectCloudDrive}/>
                                                                <div className="img-wrapper">
                                                                    {getIcon(item.cloudType, item.cloudIconType)}
                                                                </div>
                                                                <div className="content-wrapper">
                                                                    <p className="medium">{item.name}</p>
                                                                </div>
                                                            </label>
                                                        </div>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }


                                {(step === 2 || step > 2) &&
                                    <div className="drive-brands">
                                        <div className="row">
                                            <div className={`new-files-row ${step === 3 || step > 3 ? 'new-files-row-2' : ''}`}>
                                                <div className="change-cloud-wrapper-main">
                                                    <div
                                                        className="change-cloud-wrapper justify-start change-cloud-wrapper-new">
                                                        <div className="choose-cloud-card files-page">
                                                            <div className="img-wrapper">
                                                                {getIcon(selectedCloudAccount.cloudType, selectedCloudAccount.cloudIconType)}
                                                            </div>
                                                            <div className="content-wrapper">
                                                                <p className="medium"
                                                                   title={selectedCloudAccount.name}>{selectedCloudAccount.name}</p>
                                                            </div>
                                                            <span className="title">Cloud Provider</span>
                                                        </div>
                                                        <a href="#" className="btn-style transparent icon"
                                                           onClick={changeCloudDrive}><img src={editIcon} alt="logo"/></a>
                                                    </div>

                                                    {(step === 3 || step > 3) &&
                                                        <div
                                                            className="change-cloud-wrapper  justify-start change-cloud-wrapper-new">
                                                            <div className="choose-cloud-card files-page">
                                                                <div className="content-wrapper">
                                                                    <p className="medium" title={bucket}>{bucket}</p>
                                                                </div>
                                                                <span className="title bucket">Bucket</span>
                                                            </div>
                                                            <a href="#" className="btn-style transparent icon"
                                                               onClick={changeBucket}><img src={editIcon} alt="logo"/></a>
                                                        </div>
                                                    }
                                                </div>
                                                {bucket !== '' &&
                                                    <div className="btn-wrappers">
                                                        {bucket !== '' && agentIsRunning &&
                                                            <div className={`form-group ${limitExceed ? "disabled" : ""}`}>
                                                                <a href="#"
                                                                   className={`btn-style upload-small-btn icon blue ${bucket === '' ? "disabled" : ""}`}
                                                                   onClick={showModalFiles}><img src={uploadIcon}
                                                                                                 alt="icon"/>Upload</a>
                                                            </div>
                                                        }
                                                        <div className={`form-group ${limitExceed ? "disabled" : ""}`} onClick={showNewFolderPopup}>
                                                            <a href="#"
                                                               className={`btn-style upload-small-btn icon blue new-folder-btn`}><img
                                                                src={plusIcon} className="plus" alt="icon" onClick={showNewFolderPopup}/>Create
                                                                Folder</a>
                                                        </div>
                                                    </div>
                                                }
                                                {bucket !== '' && agentIsRunning && result.length > 0 &&
                                                    <div className={`drag-and-drop-wrapper ${limitExceed ? "disabled" : ""}`}>
                                                        <FileUploader label="Drop files here to upload" children={
                                                            <div className="drag-and-drop">
                                                                <img src={uploadIconNew} alt="upload icon"/>
                                                                <p>Drop files here to upload</p>
                                                            </div>
                                                        } handleChange={handleChange} name="file" multiple={true}/>
                                                    </div>
                                                }

                                                {(step === 2) &&
                                                    <div className="buckets-wrapper-files">
                                                        <div className={`choose-cloud-counter ${step === 2 ? 'mt-40' : ''}`}>
                                                            <div className="content-wrapper">
                                                                <h3>Choose a Bucket</h3>
                                                            </div>
                                                            <div className="files-bucket-search-wrapper">
                                                                <input type="text" className="form-control search-icon"
                                                                       value={searchValue}
                                                                       placeholder="Search for bucket"
                                                                       onChange={filterBuckets}/>
                                                            </div>
                                                        </div>
                                                        <ul className="buckets-list-new">
                                                            {filteredBuckets.length > 0 &&
                                                                filteredBuckets.map((item, i) => (
                                                                    <li className="form-group" key={item.id}>
                                                                        <label className="choose-cloud-card">
                                                                            <input type="radio" id="choose-cloud-1"
                                                                                   value={item.name} data-index={i}
                                                                                   data-name={item.name}
                                                                                   data-id={item.id}
                                                                                   name="choose-bucket"
                                                                                   onChange={onChangeBucket}/>
                                                                            <div className="content-wrapper">
                                                                                <p className="medium">{item.name}</p>
                                                                            </div>
                                                                        </label>
                                                                    </li>
                                                                ))
                                                            }
                                                            {filteredBuckets.length === 0 && buckets.length > 0 &&
                                                                <li className="form-group">
                                                                    No Matched Bucket Found.
                                                                </li>
                                                            }
                                                        </ul>
                                                    </div>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                }
                                {/* {(step === 2)  &&
                                <div className={`drive-brands step-2  ${step === 1 ? "disabled" : ""}`}>
                                    <div className="row">
                                        <div className="col-md-11 align-self-center">

                                        </div>
                                        <div className="col-md-12 align-self-center">
                                        </div>
                                    </div>
                                </div>
                                }*/}
                                {/*      {(step === 3 || step > 3) &&
                                    <div className="drive-brands">
                                        <div className="row">
                                            <div className="col-md-12 align-self-center drag-and-drop-wrapper">
                                                <FileUploader label="Drop files here to upload" children={
                                                    <div className="drag-and-drop">
                                                        <img src={uploadIconNew} alt="upload icon"/>
                                                        <p>Drop files here to upload</p>
                                                    </div>
                                                } handleChange={handleChange} name="file"/>
                                            </div>
                                        </div>
                                    </div>
                                }*/}
                            </div>
                        </div>
                    </div>
                </div>
                {step === 3 &&
                    <div className="container-fluid">
                        <div className="file-manager-header">
                            <div className="row">
                                <div className="col-md-6 align-self-center">
                                    <div className="setting">
                                        <h3>All files</h3>
                                    </div>
                                </div>
                                <div className="col-md-6 align-self-center">
                                    {/* {totalChecked > 0 &&
                                        <div className="share-selected">
                                            <a href="#" className="btn-style blue icon invert"><img src={uploadActivityIcon} alt="icon"/>Share all selected</a>
                                        </div>
                                    }*/}
                                </div>
                            </div>
                            {totalChecked > 0 &&
                                <div className="bottom-wrapper">
                                    <div className="row">
                                        <div className="col-md-6 align-self-center">
                                            <ul className="options">
                                                <li><a href="#" onClick={(e) => {
                                                    downloadAll()
                                                    e.preventDefault();
                                                }} className="btn-style download-btn blue icon"><img src={downloadActivityIcon} alt="icon"/>Download</a></li>
                                                <li><a href="#" onClick={(e) => {
                                                    delectAllObject()
                                                    e.preventDefault();
                                                }} className="btn-style blue icon"><img src={deleteActivityIcon} alt="icon"/>Delete</a></li>
                                                <li>
                                                    <Tooltip placement="bottomLeft"
                                                             trigger='click'
                                                             title={
                                                                 <div className={`menu-wrapper`}>
                                                                     <div className="selected-file">
                                                                         <p>{totalChecked} selected</p>
                                                                     </div>
                                                                     <ul className="menu">
                                                                         <li><a href="#" onClick={(e) => {
                                                                             downloadAll()
                                                                             e.preventDefault();
                                                                         }}>
                                                                             <span className="icon">
                                                                                 <img
                                                                                     src={downloadActivityIcon}
                                                                                     alt="icon"/>
                                                                             </span>

                                                                             Download</a>
                                                                         </li>
                                                                         <li><a href="#" onClick={(e) => {
                                                                             delectAllObject()
                                                                             e.preventDefault();
                                                                         }}>
                                                                             <span className="icon">
                                                                                 <img
                                                                                     src={deleteActivityIcon}
                                                                                     alt="icon"/>
                                                                             </span>

                                                                             Delete</a>
                                                                         </li>
                                                                         {/*   <li><a href="#">
                                                                             <span className="icon">
                                                                                 <img
                                                                                     src={copyActivityIcon}
                                                                                     alt="icon"/>
                                                                             </span>

                                                                             Copy</a>
                                                                         </li>*/}
                                                                     </ul>
                                                                 </div>
                                                             }
                                                             getPopupContainer={trigger => trigger.parentNode}>

                                                        <a href="#" className="dots"><img src={threeDotsIcon} alt="icon"/></a>
                                                    </Tooltip>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="col-md-6 align-self-center">
                                            <ul className="selected">
                                                <li>
                                                    <a href="#" className="selected-files">{totalChecked} Selected <img src={selectedActivityIcon} onClick={uncheckAll} alt="icon"/></a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        {/*}*/}
                        <div className="search-file-wrapper">
                            <div className="upload-file-detail-wrapper new-files-table">
                                <div className="upload-body">
                                    {
                                        (listView === true) &&
                                        <div className="table-wrapper">

                                            <div className="sync-back">
                                                {previousPathFiles.length > 0 &&
                                                    <button className="btn-style" type="button" onClick={goBack}><i
                                                        className="fa fa-arrow-left"></i></button>
                                                }
                                                {path.length > 1 &&
                                                    <b className='searchPath'>Path: <span title={path}>{path}</span></b>
                                                }
                                                {!loading &&
                                                    <div className="sync-img">
                                                        <img src={`${syncIcon}`} alt="syncIcon" onClick={reload}/>
                                                    </div>
                                                }
                                            </div>
                                            <div className="table-wrapper-responsive">
                                                <div className="table-wrapper-main">
                                                    {result.length > 0 &&
                                                        <div className="table-header d-md-block d-none">
                                                            <div className="row">

                                                                <div className='col-md-8 col-6'>
                                                                    <span className="name-title">Name</span></div>
                                                                <div className="col-md-1 col-3 text-center">Size</div>
                                                                <div className="col-md-3 col-3 text-end"><span
                                                                    className="date-modified">Date Modified</span></div>
                                                            </div>
                                                        </div>
                                                    }
                                                    <div className={`table-data  ${totalChecked > 0 ? "show-checkboxes" : ""}`}>

                                                        {loading &&
                                                            <div className="loading-wrapper">
                                                                <Spin indicator={antIcon} loading/>
                                                            </div>
                                                        }
                                                        {!loading && result.length === 0 &&
                                                            <div className="loading-error-wrapper no-files">
                                                                {bucket !== '' && agentIsRunning &&
                                                                    <div className={`drag-and-drop-wrapper ${limitExceed ? "disabled" : ""}`}>
                                                                        <FileUploader label="Drop files here to upload" children={
                                                                            <div className="drag-and-drop">
                                                                                <span>There are no files in this {path.length > 0 ? "folder" : "bucket"} .</span>
                                                                                <img src={uploadIconNew} alt="upload icon"/>
                                                                                <p>Drop files here to upload</p>
                                                                            </div>
                                                                        } handleChange={handleChange} name="file" multiple={true}/>
                                                                    </div>
                                                                }
                                                            </div>
                                                        }
                                                        {!loading && result.map((item, index) => (
                                                            <div
                                                                className={`row ${item.checked ? "checked" : ""} ${item.type === 1 ? 'hover-row' : 'folder-row'} ${(tooptipVal === true && tooptipIndex === index) ? 'active' : ''} ${(tooptipVal === true && tooptipIndex !== index) ? 'disabled' : ''}`}
                                                                key={index}>
                                                                <div className="col-md-8 col-12 name">
                                                                    {item.type === 2 &&
                                                                        <label className="row-data" data-value={item.path}
                                                                               data-folder={item.type} data-name={item.name} data-id={item.id} onClick={changeObject}
                                                                               role="button"></label>
                                                                    }
                                                                    <div className="d-flex">
                                                                        <div className="img-wrapper">
                                                                            {item.type === 2 &&
                                                                                <img src={`${folderIcon}`} alt="folderIcon"/>
                                                                            }
                                                                            {item.type === 1 &&
                                                                                <Checkbox checked={item.checked} onChange={() => (updateCheck(index, item.checked))}></Checkbox>
                                                                            }
                                                                            {item.type === 1 &&
                                                                                <img src={`${fileIconFile}`} alt="fileIcon"/>
                                                                            }
                                                                        </div>
                                                                        <span>{item.name}</span>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-1 col-12 text-center align-self-center">
                                                                    {item.type === 1 &&
                                                                        prettySize(item.size)
                                                                    }
                                                                </div>
                                                                <div className="col-md-3 col-12 align-self-center">
                                                                    {item.type === 1 &&
                                                                        <p className="date-modified text-end">{getFormatedDate(item.lastModifiedUtc)}</p>
                                                                    }
                                                                    {item.type === 1 &&
                                                                        <div className="hover-wrapper">
                                                                            <div className="upload-option">

                                                                                <Button loading={loadingCopyLink} type="link"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            downloadObject(item)
                                                                                        }} className="btn-style blue small">Copy
                                                                                    link</Button>

                                                                                <a href="#" onClick={(e) => {
                                                                                    e.preventDefault();
                                                                                    showModal(item)
                                                                                }}><img src={uploadNewIcon} alt="icon"/></a>
                                                                                <Tooltip placement="bottomRight"
                                                                                         trigger='click'
                                                                                         onOpenChange={(e) => {
                                                                                             tooltipOpen(e, index)
                                                                                         }}
                                                                                         title={
                                                                                             <div className={`menu-wrapper`}>
                                                                                                 <ul className="menu">
                                                                                                     <li><a href="#"
                                                                                                            onClick={(e) => {
                                                                                                                e.preventDefault();
                                                                                                                showModal(item)
                                                                                                            }}><img
                                                                                                         src={uploadNewIcon}
                                                                                                         alt="icon"/>Share</a>
                                                                                                     </li>
                                                                                                     <li>
                                                                                                         <li>
                                                                                                             <Button
                                                                                                                 loading={loadingCopyLink}
                                                                                                                 type="link"
                                                                                                                 onClick={(e) => {
                                                                                                                     e.preventDefault();
                                                                                                                     downloadObject(item)
                                                                                                                 }}><img
                                                                                                                 src={linkIcon}
                                                                                                                 alt="icon"/>Copy
                                                                                                                 link</Button>

                                                                                                         </li>
                                                                                                         <Button
                                                                                                             loading={loadingDownload}
                                                                                                             type="link"
                                                                                                             onClick={(e) => {
                                                                                                                 e.preventDefault();
                                                                                                                 downloadObject(item, true)
                                                                                                             }}><img
                                                                                                             src={downloadIcon}
                                                                                                             alt="icon"/>Download</Button>
                                                                                                     </li>
                                                                                                     <li className="delete-wrapper">
                                                                                                         <Popconfirm
                                                                                                             placement="topLeft"
                                                                                                             title="Are you sure you want to delete?"
                                                                                                             okText="Yes"
                                                                                                             cancelText="No"
                                                                                                             getPopupContainer={trigger => trigger.parentNode}
                                                                                                             onConfirm={() => delectObject(item, index)}
                                                                                                         >
                                                                                                             <Button
                                                                                                                 loading={loadingDelete}
                                                                                                                 type="link"
                                                                                                                 onClick={(e, index) => {
                                                                                                                     e.preventDefault();
                                                                                                                 }}>
                                                                                                                 <img
                                                                                                                     src={deleteIcon}
                                                                                                                     alt="icon"/>Delete</Button>
                                                                                                         </Popconfirm></li>
                                                                                                 </ul>
                                                                                             </div>
                                                                                         }
                                                                                         getPopupContainer={trigger => trigger.parentNode}>

                                                                                    <a href="#" className="menu-open"
                                                                                       onClick={(e) => {
                                                                                           e.preventDefault();

                                                                                       }}><img src={dotsIcon} alt="icon"/></a>
                                                                                </Tooltip>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                </div>
                                                            </div>

                                                        ))}

                                                        <div className="text-center">
                                                            {!loading && continuationToken != 0 && continuationToken != null && result.length > 0 && bucket !== '' &&

                                                                <div
                                                                    className={`text-center load-more ${tooptipVal === true ? 'disabled' : ''}`}>
                                                                    <Button className="btn-style btn-30" onClick={loadMore}
                                                                            loading={loadingMore}>
                                                                        Load More
                                                                    </Button>
                                                                </div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
            <Modal title="Basic Modal" className="file-delete-modal" open={isFileDeleteModalOpen} onOk={FileDeleteOk} onCancel={FileDeleteCancel}>
                <div className="file-delete-wrapper">
                    <h3>Delete 2 items?</h3>
                    <p>Are you sure you want to delete 2 items from your Amove?</p>
                    <ul className="action">
                        <li><a href="#" className="btn-style small text" onClick={FileDeleteCancel}>Cancel</a></li>
                        <li><a href="#" className="btn-style small blue">Delete</a></li>
                    </ul>
                </div>
            </Modal>
            <Modal title="Basic Modal" className="upload-file-popup" open={isModalOpen} onOk={handleOk}
                   onCancel={handleCancel}>
                <div className="upload-file-popup-wrapper">
                    <div className="header">
                        <div className="row">
                            <div className="col-9">
                                <div className="uploaded-file-header">
                                    <div className="uploaded-item-image">
                                        <img src={fileIconFile} alt="icon"/>
                                    </div>
                                    <div className="content">
                                        <h3>{modalData.name}</h3>
                                        <p>Only you can access</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-3">
                                <ul className="options">
                                    <li className="cross"><a href="#" onClick={handleCancel}><img src={crossIcon}
                                                                                                  alt="icon"/></a></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="body">
                        <h3>Share this file</h3>
                        <div className="setting">
                            <p>Anyone with this link can <strong>view</strong></p>
                        </div>
                        <div className="form">
                            <div className="form-group">
                                <input type="text" className="form-control" value={emailAddress}
                                       placeholder="Add an email address" onChange={onChangeEmailAddress}/>
                                {showErrorEmailWrong &&
                                    <span className="red-error">Incorrect email address</span>
                                }
                            </div>
                            <div className="form-group">
                                <input type="text" className="form-control" value={note} placeholder="Note"
                                       onChange={onChangeNote}/>
                            </div>

                            <div className="form-group copy-link">
                                <div className="link">
                                    <img src={linkIcon} alt="icon"/>

                                    <Button loading={loadingCopyLink} type="link" onClick={(e) => {
                                        e.preventDefault();
                                        downloadObject(modalData)
                                    }} className="link">Create and copy link</Button>
                                </div>
                                <Button loading={loadingShare} type="link" onClick={(e) => {
                                    sendDownloadObject()
                                    e.preventDefault();
                                }} className="btn-style small btn-30">Share file</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal
                open={open}
                title="Title"
                onOk={handleOkFiles}
                onCancel={handleCancelFiles}
                className={`file-path-modal file-path-modal-wrapper ${!isWindows ? "mac-os" : ""}`}
                footer={[
                    <button className={`btn-style ${selectedFile === "" ? "disabled" : ""}`} onClick={uploadFile}>
                        Upload File
                    </button>,
                    <button className={`btn-style margin-left`} onClick={handleCancelFiles}>
                        Cancel
                    </button>
                ]}
            >
                <div className="form dir-path-wrapper">
                    <div className="form-group choose-your-backup-title">
                        <h3>
                            <button className={`btn-style  ${selectedPath.length < 4 ? "disabled" : ""}`} type="button"
                                    onClick={goBackFiles}><i className="fa fa-arrow-left"></i></button>
                            Select File to Upload
                        </h3>
                    </div>
                </div>
                <div className="modal-body-wrapper">
                    <div className={`select-drive-wrapper  ${!isWindows ? "mac-os" : ""}`}>
                        <div className="row">
                            {isWindows &&
                                <div className="col-md-3">
                                    <p className="medium">Drive</p>
                                    <div className="form">

                                        {localDrives.length > 0 &&
                                            localDrives.map((item, i) => (
                                                <div className="form-group" key={i}>
                                                    <input type="radio" id={`select-drive-` + i} value={item}
                                                           name="select-drive" onClick={getDirectories}/>
                                                    <label htmlFor={`select-drive-` + i}>
                                                        <svg width="22" height="18" viewBox="0 0 22 18" fill="none"
                                                             xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M1.5 9H20.5M5 13H9M7.96656 1H14.0334C15.1103 1 15.6487 1 16.1241 1.16396C16.5445 1.30896 16.9274 1.5456 17.2451 1.85675C17.6043 2.2086 17.8451 2.6902 18.3267 3.65337L20.4932 7.9865C20.6822 8.36449 20.7767 8.55348 20.8434 8.75155C20.9026 8.92745 20.9453 9.10847 20.971 9.29226C21 9.49923 21 9.71053 21 10.1331V12.2C21 13.8802 21 14.7202 20.673 15.362C20.3854 15.9265 19.9265 16.3854 19.362 16.673C18.7202 17 17.8802 17 16.2 17H5.8C4.11984 17 3.27976 17 2.63803 16.673C2.07354 16.3854 1.6146 15.9265 1.32698 15.362C1 14.7202 1 13.8802 1 12.2V10.1331C1 9.71053 1 9.49923 1.02897 9.29226C1.05471 9.10847 1.09744 8.92745 1.15662 8.75155C1.22326 8.55348 1.31776 8.36448 1.50675 7.9865L3.67331 3.65337C4.1549 2.69019 4.3957 2.2086 4.75495 1.85675C5.07263 1.5456 5.45551 1.30896 5.87589 1.16396C6.35125 1 6.88969 1 7.96656 1Z"
                                                                stroke="black" strokeWidth="2" strokeLinecap="round"
                                                                strokeLinejoin="round"/>
                                                        </svg>
                                                        <p className="medium">{item.replace(":\\", "")}</p></label>
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                            <div className="col-md-9">
                                <div className="form">
                                    <div
                                        className={`account-detail radio radio-button  ${isWindows ? "windows-dir-wrapper" : ""}`}>
                                        <div
                                            className={`final-selected-cloud directory-list  ${isWindows ? "windows-dir-list-wrapper" : ""}`}>
                                            {localDir.length > 0 &&
                                                localDir.map((item, i) => (
                                                    <div className="form-group">
                                                        <label data-value={item.fullPath}
                                                               title={item.fullPath.substring(item.fullPath.lastIndexOf("\\") + 1, item.fullPath.length)}
                                                               className={`account-card ${item.objectType === 1 ? 'file-icon' : 'folder-icon'}`}
                                                               key={i} onClick={(e) => {
                                                            changeDir(e, item)
                                                        }}>
                                                            {isWindows &&
                                                                item.fullPath.substring(item.fullPath.lastIndexOf("\\") + 1, item.fullPath.length)
                                                            }
                                                            {!isWindows &&
                                                                item.fullPath.substring(item.fullPath.lastIndexOf("/") + 1, item.fullPath.length)
                                                            }
                                                            <i key={i} className='d-none'></i>
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                            {localDir.length < 1 && selectedPath !== "" &&
                                                <h3>There are no Files</h3>
                                            }
                                        </div>
                                    </div>

                                    <div
                                        className={`folder-path-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">Path:</div>
                                            <div className="col-9">
                                                <div className="folder-name" title={selectedPath}>
                                                    {selectedPath}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className={`selected-folder-wrapper  ${isWindows ? "windows-folder-wrapper" : ""}`}>
                                        <div className="row">
                                            <div className="col-3">File:</div>
                                            <div className="col-9">
                                                <div className="folder-name">
                                                    {isWindows &&
                                                        selectedFile.substring(selectedFile.lastIndexOf("\\") + 1, selectedFile.length)
                                                    }
                                                    {!isWindows &&
                                                        selectedFile.substring(selectedFile.lastIndexOf("/") + 1, selectedFile.length)
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


            <Modal title="Create New Folder" className="manual-user-type" centered open={newFolderPopup} onOk={closeFilePopup} onCancel={closeFilePopup}>
                <div className="manual-user-modal-wrapper">
                    <div className="header">
                        <h3>Create New Folder</h3>
                    </div>
                    <div className="body">
                        <div className="search-wrapper">
                            <div className="form">
                                <div className="form-group">
                                    <p className="label">Folder Name</p>
                                    <div className="add-share">
                                        <input type="email" className={`form-control`} onChange={(e) => {
                                            setNewFolderName(e.target.value);
                                        }} value={newFolderName} placeholder="Name your folder"/>
                                        {newFolderName.length > 0 && !validFolderName(newFolderName) &&
                                            <p className={"error-message"}>No special characters or spaces.</p>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="footer">
                        <a href="#" className="btn-style transparent" onClick={closeFilePopup}>Cancel</a>
                        <Button className={`btn-style blue ${newFolderName.length > 0 && validFolderName(newFolderName) ? "" : "disabled"}`} loading={loading} onClick={createNewFolder}>Create</Button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default FileManagerNew;

