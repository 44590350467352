import React, {useEffect, useState} from "react";
import {useAuth} from "../../context/AuthContext";
import {Button} from 'antd';
import SettingHeader from "../../components/Setting/SettingHeader";

const Settings = () => {
    const {user, setUser, updateDataWithToken, setPopup500, logout, sendDataToMixPanel,checkLength} = useAuth();
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [companyName, setCompanyName] = useState('');
    const [companyError, setCompanyError] = useState("");
    const [errorMsgProfile, setErrorMsgProfile] = useState("");
    const [successMessage, setSuccessMessage] = useState("");
    const [successMessageProfile, setSuccessMessageProfile] = useState("");
    const [loading, setLoading] = useState(false);
    const [showEdit, setShowEdit] = useState(false);
    var checkEmail = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;


    useEffect(() => {
        if (user.username !== user.accountName) {
            setCompanyName(user.accountName)
        }
        if (user.username !== user.firstname) {
            setFirstName(user.firstname)
        }
        if (user.username !== user.lastname) {
            setLastName(user.lastname)
        }

    }, [user, showEdit]);

    const onFirstName = (event) => {
        setFirstName(event.target.value.trim())
        setSuccessMessage('')
        setSuccessMessageProfile('')
        setErrorMsgProfile('')
        checkErrors()
    };
    const onLastName = (event) => {
        setLastName(event.target.value.trim())
        setSuccessMessage('')
        setSuccessMessageProfile('')
        setErrorMsgProfile('')
        checkErrors()
    };

    const onCompanyName = (event) => {
        var value = event.target.value;
        if (value.length <= 50){
            setErrorMsgProfile('')
            console.log(value, "this name is valid");
        }else {
            setErrorMsgProfile('50 characters max allowed for Company Name.')
            console.log("Company name must be 50 characters or less.");
        }
       /* const professionalNameRegex = /^[a-zA-Z0-9&.,'-]+(\s[a-zA-Z0-9&.,'-]+)*$/;
        if (professionalNameRegex.test(value)) {

        }else {
            setCompanyError('Invalid company name.')
            console.log("Invalid company name.");
        }*/
        setCompanyName(value)
        setSuccessMessage('')
        setSuccessMessageProfile('')
        setErrorMsgProfile('')
        checkErrors()
    };
    const checkErrors = () => {
        if(!checkLength(firstName,128)){
            setErrorMsgProfile('128 characters max allowed for First Name.')
        }
        if(!checkLength(lastName,128)){
            setErrorMsgProfile('128 characters max allowed for Last Name.')
        }
        if(!checkLength(companyName,50)){
            setErrorMsgProfile('50 characters max allowed for Company Name.')
        }
    };

    const onUpdateProfile = () => {
        checkErrors()
        if (firstName !== '' && lastName !== '') {
            setLoading(true)
            updateDataWithToken('/api/user/update_user', {
                "accountName": companyName.trim(),
                "firstName": firstName.trim(),
                "lastName": lastName.trim()
            })
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    setUser(prevState => ({
                        ...prevState,
                        firstname: firstName.trim(),
                        lastname: lastName.trim(),
                        accountName: companyName.trim()
                    }));
                    setSuccessMessageProfile('Your profile updated');

                    sendDataToMixPanel('User profile updated.', user.username, {
                        'App': 'Web App'
                    })
                    setLoading(false)
                    setTimeout(() => {
                        setShowEdit(false);
                        setSuccessMessageProfile('');
                    }, 800)
                })
        } else {
            setErrorMsgProfile('All fields are required')
        }
    };
    return (
        <>
            <div id="main-content">
                <div className="container-fluid mb-4">
                    <div className="account-detail-wrapper">
                        <SettingHeader/>
                        <div className="account-detail-body">
                            <div className="account-detail-card">
                                <div className="info-detail">
                                    <div className="row">
                                        <div className="col-md-10 col-6 align-self-center">
                                            <h3>Personal info</h3>
                                        </div>
                                        <div className="col-md-2 col-6 align-self-center">
                                            <div className="icon">
                                                {!showEdit &&
                                                    <a href="#" onClick={() => {
                                                        setShowEdit(true);
                                                    }}>Edit</a>
                                                }
                                                {showEdit &&
                                                    <a href="#" onClick={() => {
                                                        setShowEdit(false);
                                                    }}>Cancel</a>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-detail">
                                    <div className="row">
                                        <div className="col-md-10 align-self-center">
                                            <div className="name-wrapper">
                                                <p className="gray bold">First Name</p>
                                                <div className="name-detail">
                                                    {showEdit &&
                                                        <input type="text" id="first-name" className="form-control small-fields"
                                                               autoComplete="fisrt-name" value={firstName} name="firstname" onChange={onFirstName}/>
                                                    }
                                                    {!showEdit &&
                                                        <p className="bold">{firstName}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 align-self-center">
                                            <div className="icon">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-detail">
                                    <div className="row">
                                        <div className="col-md-10 align-self-center">
                                            <div className="name-wrapper">
                                                <p className="gray bold">Last Name</p>
                                                <div className="name-detail">
                                                    {showEdit &&
                                                        <input type="text" id="last-name" className="form-control small-fields"
                                                               autoComplete="last-name" value={lastName} name="lastname" onChange={onLastName}/>
                                                    }
                                                    {!showEdit &&
                                                        <p className="bold">{lastName}</p>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 align-self-center">
                                            <div className="icon">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-detail">
                                    <div className="row">
                                        <div className="col-md-10 align-self-center">
                                            <div className="name-wrapper">
                                                <p className="gray bold">Email</p>
                                                <div className="name-detail">
                                                    <p className="bold">{user.username}</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 align-self-center">
                                            <div className="icon">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-detail">
                                    <div className="row">
                                        <div className="col-md-10 align-self-center">
                                            <div className="name-wrapper">
                                                <p className="gray bold">Company Name</p>
                                                <div className="name-detail">
                                                    {showEdit && user.userType !== 32 && user.userType !== 64 &&
                                                        <input type="text" id="company-name" className="form-control small-fields"
                                                               autoComplete="company-name" value={companyName} name="companyName" onChange={onCompanyName}/>
                                                    }
                                                    {(!showEdit || user.userType === 32 || user.userType === 64) && !checkEmail.test(companyName.trim()) &&
                                                        <p className="bold">{companyName}</p>
                                                    }
                                                    <span className={`error-msg ${companyError !== ''?'':'d-none'}`}>{companyError}</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 align-self-center">
                                            <div className="icon">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="info-detail">
                                    <div className="row">
                                        <div className="col-md-10 align-self-center">
                                            <div className="name-wrapper">
                                                <p className="gray bold">User Type</p>
                                                <div className="name-detail">
                                                        <p className="bold">
                                                            {(user.userType === 16 && user.owner ) ? "Owner" : ""}
                                                            {(user.userType === 16 && !user.owner ) ? "Admin" : ""}
                                                            {(user.userType === 32) ? "Power User" : ""}
                                                            {(user.userType === 64) ? "Standard User" : ""}
                                                        </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-2 align-self-center">
                                            <div className="icon">

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {showEdit &&
                                    <div className="info-detail">
                                        <div className="row">
                                            <div className="col-md-10 align-self-center">
                                                <div className="name-wrapper">
                                                    <p className="gray bold"></p>
                                                    <div className="name-detail">
                                                        <div className="form-group error-after-metter errors-msg pl-0">
                                                            <span className="red-error">{errorMsgProfile}</span>
                                                            <span className="green-error">{successMessageProfile}</span>
                                                        </div>
                                                        <div className={`form-group submit mt-4`}>
                                                            <Button loading={loading} onClick={onUpdateProfile} className={`btn-style small-btn btn-30 ${user.username === companyName || !checkLength(companyName,128) || !checkLength(firstName,128) || !checkLength(lastName,128) || companyError !== '' ? "disabled" : ""}`}>Save Changes</Button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-2 align-self-center">
                                                <div className="icon">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Settings;

