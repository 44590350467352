import React, {useEffect, useState, useRef} from "react";
import {useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import {Button, Checkbox, Modal, notification, Select, Tooltip, Radio, Switch} from 'antd';
import {useAuth} from "../context/AuthContext";

import affrimLogo from "../assets/images/pay-with-affrim.svg";

import amoveIcon from "../assets/images/icons/checkout/amove-logo-icon.svg";
import cloudIcon from "../assets/images/icons/checkout/cloud-connections.svg";
import globalIcon from "../assets/images/icons/checkout/global-icon.svg";
import performIcon from "../assets/images/icons/checkout/perform-icon.svg";
import powerIcon from "../assets/images/icons/checkout/power-user-icon.svg";
import scaleIcon from "../assets/images/icons/checkout/scale-icon.svg";
import standardIcon from "../assets/images/icons/checkout/standard-user-icon.svg";
import usersIcon from "../assets/images/icons/checkout/users.svg";

import config from "../config";

const AmoveCheckout = () => {

    const navigate = useNavigate();
    const [viewCount, setViewCount] = useState(0);
    const [quoteNumber, setQuoteNumber] = useState('');

    // Function to fetch and update the view count from localStorage or API
    const updateViewCount = () => {
        const currentViewCount = localStorage.getItem('viewCount') || 0;
        const updatedCount = parseInt(currentViewCount, 10) + 1;
        localStorage.setItem('viewCount', updatedCount);
        setViewCount(updatedCount);  // Only update once when it changes
    };

    // Function to generate quote number
    const generateQuoteNumber = () => {
        const currentDate = new Date();
        const formattedDate = currentDate.toISOString().split('T')[0]; // YYYY-MM-DD format
        const dateParts = formattedDate.split('-');
        const quoteNum = `Quote# Amove-10${viewCount}_${dateParts[1]}${dateParts[2]}${dateParts[0].substring(2, 4)}`;
        setQuoteNumber(quoteNum);  // Update the quote number once based on viewCount
    };

    useEffect(() => {
        updateViewCount(); // Only run once when the component is mounted
    }, []);  // Empty dependency array ensures this runs only once

    useEffect(() => {
        generateQuoteNumber();  // Update the quote number when viewCount changes
    }, [viewCount]);  // This effect depends on viewCount, but won't cause an infinite loop



    useEffect(() => {
        // Fetch URL parameters
        const params = new URLSearchParams(window.location.search);

        // Get individual values
        const cardTitle = params.get('cardTitle') || '';
        const cardPrice = params.get('cardPrice') || '';
        const plan = params.get('plan') || '';
        const amovePerformCount = params.get('amovePerformCount') || '0';
        const amovePerformPrice = params.get('amovePerformPrice') || '0';
        const amoveGlobalCount = params.get('amoveGlobalCount') || '0';
        const amoveGlobalPrice = params.get('amoveGlobalPrice') || '0';
        const amoveScaleCount = params.get('amoveScaleCount') || '0';
        const amoveScalePrice = params.get('amoveScalePrice') || '0';
        const amoveConnectionCount = params.get('amoveConnectionCount') || '0';
        const amoveAdminCount = params.get('amoveAdminCount') || '0';
        const amovePowerCount = params.get('amovePowerCount') || '0';
        const amoveStandardCount = params.get('amoveStandardCount') || '0';
        const monthlyTotal = params.get('monthlyTotal') || '0';

        // Set values into the DOM
        document.querySelector('.plan-title').textContent = cardTitle;
        document.querySelector('.plan-price').textContent = `$${cardPrice}`;
        document.querySelector('.plan-subscription').textContent = plan;
        document.querySelector('.perform-count').textContent = amovePerformCount;
        document.querySelector('.amove-perform').textContent = `$${amovePerformPrice}`;
        document.querySelector('.global-count').textContent = amoveGlobalCount;
        document.querySelector('.amove-global').textContent = `$${amoveGlobalPrice}`;
        document.querySelector('.scale-count').textContent = amoveScaleCount;
        document.querySelector('.amove-scale').textContent = `$${amoveScalePrice}`;
        document.querySelector('.connection-count').textContent = amoveConnectionCount;
        document.querySelector('.connection-price').textContent = `$${amovePerformPrice}`;
        document.querySelector('.admin-count').textContent = amoveAdminCount;
        document.querySelector('.admin-price').textContent = `$${amovePerformPrice}`;
        document.querySelector('.power-count').textContent = amovePowerCount;
        document.querySelector('.power-price').textContent = `$${amovePerformPrice}`;
        document.querySelector('.standard-count').textContent = amoveStandardCount;
        document.querySelector('.standard-price').textContent = `$${amovePerformPrice}`;
        document.querySelector('.subtotal-price').textContent = `$${monthlyTotal}`;
        document.querySelector('.total-price').textContent = `$${monthlyTotal}`;

        // Update #small-text based on cardTitle with the detailed plan information
        let smallText = '';

        if (cardTitle === "Click For BackUp") {
            smallText = `${amoveAdminCount + 2} Admin Users, ${amoveConnectionCount + 2} Cloud Connections, ` +
                `${amoveGlobalCount + 1}TB Of Global Storage, ${amoveScaleCount + 3}TB Of Scale Storage`;
        } else if (cardTitle === "Click For Creatives") {
            smallText = `${amoveAdminCount + 2} Admin Users, ${amoveStandardCount + 2} Standard Users, ` +
                `${amoveConnectionCount + 2} Cloud Connections, ${amovePerformCount + 3}TB Of Perform Storage, ` +
                `${amoveScaleCount + 3}TB Of Scale Storage`;
        } else if (cardTitle === "Click For Business") {
            smallText = `${amoveAdminCount + 2} Admin Users, ${amoveStandardCount + amovePowerCount + 8} Power/Standard Users, ` +
                `${amoveConnectionCount + 2} Cloud Connections, ${amoveGlobalCount + 5}TB Of Global Storage, ` +
                `${amoveScaleCount + 5}TB Of Scale Storage`;
        }

        // Set the content for #small-text
        document.querySelector('#small-text').innerHTML = smallText;
    }, []);



    return (
        <>
            <div id="main-content" className="checkout-payment-main">
                <div className="container-fluid">
                    <div className="main-content-body">
                        <div className="sec-checkout-payment">
                            <div className="row justify-content-center">
                                <div className="col-md-6">
                                    <div className="cart-wrapper" id="amove-invoice">
                                        <div className="users-count">
                                            <span className="quote-number">{quoteNumber}</span>
                                            <ul className="users-count grid-icon">
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={amoveIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title plan-title"></span>
                                                            <span className="small-text" id="small-text">
                                        </span>
                                                        </p>
                                                    </div>

                                                    <strong className="plan-price"></strong>
                                                </li>
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={performIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Amove Perform Storage</span>
                                                            <span className="perform-count"></span>TB of Perform Cloud Storage
                                                        </p>
                                                    </div>
                                                    <strong className="amove-perform"></strong>
                                                </li>
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={globalIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Amove Global Storage</span>
                                                            <span className="global-count"></span>TB of Global Cloud Storage
                                                        </p>
                                                    </div>
                                                    <strong className="amove-global"></strong>
                                                </li>
                                                <li>
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={scaleIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Amove Scale Storage</span>
                                                            <span className="scale-count"></span>TB of Scale Cloud Storage
                                                        </p>
                                                    </div>
                                                    <strong className="amove-scale"></strong>
                                                </li>
                                                <li className="connection-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={cloudIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Connections</span>
                                                            <span className="connection-count"></span> Connections to Any Cloud or Local Storage
                                                        </p>
                                                    </div>
                                                    <strong className="connection-price"></strong>
                                                </li>
                                                <li className="admin-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={usersIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Admin User</span>
                                                            <span className="admin-count"></span> Admin Users
                                                        </p>
                                                    </div>
                                                    <strong className="admin-price"></strong>
                                                </li>
                                                <li className="power-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                        <img src={powerIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Power User</span>
                                                            <span className="power-count"></span> Power Users
                                                        </p>
                                                    </div>
                                                    <strong className="power-price"></strong>
                                                </li>
                                                <li className="standard-wrapper">
                                                    <div className="title-wrapper">
                                    <span className="icon-wrapper">
                                         <img src={standardIcon} alt="icon"/>
                                    </span>
                                                        <p>
                                                            <span className="title">Standard User</span>
                                                            <span className="standard-count"></span> Standard Users
                                                        </p>
                                                    </div>
                                                    <strong className="standard-price"></strong>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="discount-div ignore">
                                            <input type="text" placeholder="Discount or Partner Code" className="form-control"/>
                                            <button className="btn-style" id="apply-discount">Apply</button>
                                        </div>
                                        <div className="sub-total">
                                            <div className="subtotal-wrapper">
                                                <span className="small">Subtotal</span>
                                                <span className="small subtotal-price"></span>
                                            </div>
                                            <div className="subtotal-wrapper">
                                                <span className="small">Discount</span>
                                                <span className="small discount-price"></span>
                                            </div>
                                        </div>

                                        <div className="total-wrapper">
                                            <h3 className="small">Total</h3>
                                            <div>
                                                <h3 className="small total-price"></h3>
                                                <span className="plan-subscription"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="strip-form">
                                        <div className="form">

                                            <div className="form-group submit">
                                                <ul className="btn-list">
                                                    <li><a href="#" className="btn-style">Pay Now</a></li>
                                                </ul>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AmoveCheckout;

