import React, {useEffect, useState} from 'react'
import {NavLink} from "react-router-dom";
import {useAuth} from "../../context/AuthContext";

const SettingHeader = () => {
    const {
        user, isDark, setIsDark,setPopupSupport
    } = useAuth();
    const toggleDarkMode = () => {
        var isCheckDark = localStorage.getItem('darkMode');
        if (isCheckDark === 'true') {
            document.body.classList.remove('dark-theme-active');
            localStorage.setItem('darkMode', "false")
            setIsDark(false);
        } else {
            document.body.classList.add('dark-theme-active');
            localStorage.setItem('darkMode', "true");
            setIsDark(true);
        }
    }
    return (
        <div className="account-detail-header">
            <h2>Account</h2>
            <ul className="account-tabs">
                <li><NavLink to="/account">Profile</NavLink></li>
                <li><NavLink to="/security">Password</NavLink></li>
                {user.userType === 16 &&
                    <li><NavLink to="/SSO">SSO</NavLink></li>
                }
                <li className="d-md-inline-block d-none"><NavLink to="/activity">Activity</NavLink></li>
                {user.userType === 16 &&
                    <li><NavLink to="/log">Access Logs</NavLink></li>
                }
                {user.userType === 16 &&
                    <li><NavLink to="/api-token">API Token</NavLink></li>
                }
                <li className="d-md-inline-block d-none"><NavLink to="/drive-settings">Drive Settings</NavLink></li>
                {user.authProvider === 0 &&
                    <li className="d-md-inline-block d-none"><NavLink to="/mfa-setup">MFA Settings</NavLink></li>
                }
                {user.userType === 16 &&
                <li><NavLink to="/billing-status">Billing</NavLink></li>
                }
                <li><a href="#" onClick={(e) => {
                    e.preventDefault()
                    setPopupSupport(true)
                }}>Support</a></li>
               {/* {user.userType === 16 &&
                    <li><NavLink to="/quote-status">Billing New</NavLink></li>
                }*/}
            </ul>
        </div>
    )
}

export default SettingHeader
