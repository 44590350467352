import React, {useEffect, useState, useRef} from 'react';
import trashIcon from "../assets/images/icons/trash-icon.svg";
import {Collapse, notification, Popconfirm, Switch} from 'antd';
import {useNavigate} from "react-router-dom";
import {useAuth} from "../context/AuthContext";
import warning from "../assets/images/icons/warning-sign.svg";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import crossBalckIcon from "../assets/images/icons/tour-cross-black-icon.svg";
import globalIcon from "../assets/images/global.svg";
import performIcon from "../assets/images/perform.svg";
import scaleIcon from "../assets/images/scale.svg";
import config from "../config";
import {NFS} from "./NFS";
import {SMB} from "./SMB";
import AgentOffPopup from "./AgentOffPopup";
import {SelectSharedDrive} from "./CreateSharedDriveComponent";

const {Panel} = Collapse;
const CloudAccounts = ({open = 0, lastid = null, onboarding = false, callback}) => {
    const {
        getData,
        postDataNoResponse,
        deleteData,
        token,
        user,
        logout,
        cloudAccounts,
        setCloudAccounts,
        cloudCounts,
        setcloudCounts,
        getIcon,
        refreshData,
        getCloudAccounts,
        isCMDDone,
        agentIsRunning,
        reloadDrivesFromAccounts,
        prettySize,
        setReloadDrivesFromAccounts,
        userStatus,
        setPreSelectedAccountID,
        isWindows,
        sendDataToMixPanel,
        setOpenCloudDriveAccordion,
        setAgentIsRunning,
        setIsCMDDone,
        agentPopupOpen,
        setAgentPopupOpen,
        sendDataToWebsocket,
        setDefaultOpenCloudDrive,
        cloudAccountsLoaded,
        setCloudAccountsLoaded,
        setReloadBackups,
        deleteDataWeb,
        postDataWebNoResponse,
        sync,
        setPopup500,
        setPopupSupport,
        setEndpoint,
        setShowPopupSupport,
        showTourDashboard,
        setShowTourDashboard,
        checkAgent,
        tourStep,
        setTourStep,
        setShowInvitePopup,
        setShowDrivePopup,
        setError499,
        setPopup499,
        setShowStartedBtn,
        checkLength,
        getDataWithToken,
        calcDrives,
        setCalcDrives,
        cloudDrives,
        setCloudDrives,
        updateDataWithToken
    } = useAuth();

    const [api, contextHolder] = notification.useNotification();

    const [firstLoad, setFirstLoad] = useState(false);

    //Drive items

    const [local, setlocal] = useState("drive");
    const [localCachePath, setLocalCachePath] = useState("");
    const [isActive, setActive] = useState(null);
    const [showCloudDriveNameError, setShowCloudDriveNameError] = useState(false);
    const [showCloudDriveNameLengthError, setShowCloudDriveNameLengthError] = useState(false);
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [openPreCache, setOpenPreCache] = useState(false);
    const [selectedCloudAccount, setSelectedCloudAccount] = useState({});
    const [selectedCloudAccountID, setSelectedCloudAccountID] = useState("");
    const [localDrives, setLocalDrives] = useState([]);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const [localDir, setLocalDir] = useState([]);
    const [previousPath, setPreviousPath] = useState("");
    const [selectedPath, setSelectedPath] = useState("");
    const [localDrivesPreCache, setLocalDrivesPreCache] = useState([]);
    const [localDirPreCache, setLocalDirPreCache] = useState([]);
    const [previousPathPreCache, setPreviousPathPreCache] = useState("");
    const [availableLocalDrives, setAvailableLocalDrives] = useState([]);
    const [selectedAvailableDrive, setSelectedAvailableDrive] = useState("Z");
    const [bucket, setBucket] = useState(null);
    const [yourCloudDriveName, setYourCloudDriveName] = useState("");
    const [folderName, setFolderName] = useState("");
    const [loading, setLoading] = useState(false);
    const [loadingCreate, setLoadingCreate] = useState(false);
    const [errorText, setErrorText] = useState('');
    const [backupInstance, setBackupInstance] = useState([]);
    const [cacheSettings, setcacheSettings] = useState('stream');
    const [directoryType, setDirectoryType] = useState('automatic');
    const [cacheSize, setcacheSize] = useState(50);
    const [objects, setobjects] = useState(10000);
    const [cacheSettingsGBTB, setcacheSettingsGBTB] = useState('GB');
    const [newBucketName, setNewBucketName] = useState("");
    const [limitExceed, setLimitExceed] = useState(false);
    const [newBucketCreated, setNewBucketCreated] = useState(false);
    const [localCacheEncrypted, setLocalCacheEncrypted] = useState(true);
    const [rotationalCache, setRotationalCache] = useState(true);
    const [showSingleButton, setShowSingleButton] = useState(false);
    const [showCreateCloudDrive, setChowCreateCloudDrive] = useState(false);
    const [activeKey, setActiveKey] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [descending, setDescending] = useState(false);
    const [searchValForSearch, setSearchValForSearch] = useState("");


    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            //if(open === 1){
            getCloudAccounts()
            // }
        }
    }, []);
    useEffect(() => {
        if (agentIsRunning) {
            if (yourCloudDriveName !== "") {
                setShowTourDashboard(false)
                setTourStep(0)
                if (user.userType !== 32) {
                    createSharedCloudDrive()
                } else {
                    setupCloudDrive()
                }
            }
        }
    }, [agentIsRunning]);
    useEffect(() => {
        if (showTourDashboard || open === 1) {
            if (tourStep === 0) {
                setTourStep(1);
                setActiveKey(["0", "1"]);
                callback({"accounts": true})
                /*let newArr = [...cloudAccounts];
                newArr[0].showBucket = true;
                setCloudAccounts(newArr)*/
            }
        }
    }, [showTourDashboard]);
    useEffect(() => {
        if (onboarding) {
            var dashboardTourStep = parseInt(localStorage.getItem('dashboardTourStep'))
            if (dashboardTourStep === null) {
                setTourStep(1)
                setShowTourDashboard(true)
            } else if (dashboardTourStep > 0) {
                setTourStep(dashboardTourStep)
                setShowTourDashboard(true)
            }
        }
    }, [showTourDashboard]);
    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (cloudDrives.length === 0 || reloadDrivesFromAccounts) {
                getData('/Management/clouddrive')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setCloudDrives(data);

                    });
            }
            if (backupInstance.length === 0) {
                getData('/Management/cloudbackup')
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {

                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }

                        setBackupInstance(data);

                    });
            }
        }
    }, [userStatus, reloadDrivesFromAccounts]);

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (cloudDrives.length === 0) {
                loadCloudDrives()
            }
        }
    }, [isCMDDone]);
    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if ((cloudAccountsLoaded && cloudDrives.length > 0) || calcDrives) {
                let newArr = [...cloudAccounts];
                for (var i = 0; i < newArr.length; i++) {
                    for (var j = 0; j < newArr[i].buckets.length; j++) {
                        if (agentIsRunning) {
                            newArr[i].buckets[j].totalDrives = cloudDrives.filter((item) => {
                                return item?.account?.id === newArr[i]?.id && item?.storageName === newArr[i]?.buckets[j]?.name;
                            }).length;
                        } else {
                            newArr[i].buckets[j].totalDrives = cloudDrives.filter((item) => {
                                return item?.cloudAccount?.id === newArr[i]?.id && item?.storageName === newArr[i]?.buckets[j]?.name;
                            }).length;
                        }
                    }
                }
                setCloudAccounts(newArr)
                if (calcDrives) {
                    setCalcDrives(false)
                }
            }
        }
    }, [cloudAccountsLoaded, cloudDrives, calcDrives]);


    function loadCloudDrives(refresh = false) {
        if (agentIsRunning) {
            setReloadDrivesFromAccounts(true)
            getData('/Management/clouddrive')
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, response]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true);
                        setLoading(false);
                        return;
                    }

                    if (status === 499) {
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }

                    var clouddrives = response;
                    var data = clouddrives;
                    if (user.userType === 16) {
                        getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
                            .then((res) => Promise.all([res.status, res.json()]))
                            .then(([status, response]) => {
                                if (status === 401) {
                                    //logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    var errorType = response.type;
                                    setError499(response.errors[errorType][0])
                                    setPopup499(true)
                                    return;
                                }
                                var sharedDrives = response.data;
                                let sharedDriveIds = new Set(clouddrives.map(d => d.sharedDriveId));
                                let notExistsDrives = sharedDrives.filter(drive => !sharedDriveIds.has(drive.id));
                                if (notExistsDrives.length > 0) {
                                    for (var i = 0; i < notExistsDrives.length; i++) {
                                        notExistsDrives[i]["account"] = notExistsDrives[i]["cloudAccount"];
                                        notExistsDrives[i]["notassigned"] = true;
                                        notExistsDrives[i]["sharedDriveId"] = notExistsDrives[i]["id"];
                                        data.push(notExistsDrives[i])
                                    }
                                }
                                setCloudDrives(data)
                                setCalcDrives(true)
                            })
                    } else {
                        setCloudDrives(data)
                        setCalcDrives(true)
                    }
                }).catch((error) => {
            })
        } else {
            if (user.userType === 16) {
                getDataWithToken(`/api/sharedclouddrive/get_all?page=${currentPage}&pagesize=${perPage}&sortfield=Name&descending=${descending}&deleted=false&name=${searchValForSearch}`)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, response]) => {
                        if (status === 401) {
                            //logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        var data = response.data;

                        for (var j = 0; j < data.length; j++) {
                            data[j]["account"] = data[j].cloudAccount;
                        }

                        setCloudDrives(data)
                        setCalcDrives(true)
                    })
            }
        }
    }

    const onChange = (key) => {
        if (cloudAccounts.length > 0) {
            setActiveKey(key)
            callback({"accounts": key.length > 1})
            if (key.length < 2) {
                let newArr = [...cloudAccounts];
                for (var i = 0; i < newArr.length; i++) {
                    newArr[i].showBucket = false;
                }
                setCloudAccounts(newArr)
            }
        }
    };


    function deleteCloudAccount(item, current) {

        if (current) {
            showBucket(item.id, true)
        } else {
            showBucket(item.id, false)
        }
        setShowTourDashboard(false)
        var id = item.id;
        var type = item.cloudIconType;
        if (item.internalStorage) {
            deleteDataWeb('/api/cloudaccount/idrive_delete_storage?id=' + id)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        setEndpoint("/api/cloudaccount/idrive_delete_storage")
                        setShowPopupSupport(true)
                        //  logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var response = JSON.parse(data)
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    data = JSON.parse(data)
                    if (status === 499) {
                        api.open({
                            message: 'Error',
                            description:
                                data.errors.DEFAULT[0],
                            icon: <img src={warning} alt="warning"/>,
                            duration: 5,
                            placement: "topRight"
                        });

                    } else {
                        deleteDrivesSync(id)
                        setCloudAccounts((current) =>
                            current.filter(
                                (item) => item.id !== id
                            )
                        );
                        api.open({
                            message: 'Amove storage removed successfully.',
                            description: `Your Amove storage has been removed.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });

                        let newArr = [...cloudCounts];
                        for (var i = 0; i < newArr.length; i++) {
                            if (newArr[i].cloudType == type) {
                                newArr[i].count--;
                            }
                        }
                        newArr = newArr.filter(function (item) {
                            return item.count > 0;
                        })
                        setcloudCounts(newArr)
                    }
                });
        } else {
            deleteDataWeb('/api/desktop/delete?id=' + id)
                .then((res) => Promise.all([res.status, res.text()]))
                .then(([status, data]) => {

                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setPopup500(true)
                        return;
                    }
                    if (status === 499) {
                        var response = JSON.parse(data)
                        var errorType = response.type;
                        setError499(response.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    data = JSON.parse(data)
                    if (status === 499) {
                        api.open({
                            message: 'Error',
                            description:
                                data.errors.DEFAULT[0],
                            icon: <img src={warning} alt="warning"/>,
                            duration: 5,
                            placement: "topRight"
                        });
                    } else {


                        deleteDrivesSync(id)
                        setCloudAccounts((current) =>
                            current.filter(
                                (item) => item.id !== id
                            )
                        );
                        api.open({
                            message: 'Account removed successfully.',
                            description: `Your cloud account has been removed.`,
                            icon: <img src={toastIcon} alt="toastIcon"/>,
                            duration: 5
                        });

                        let newArr = [...cloudCounts];
                        for (var i = 0; i < newArr.length; i++) {
                            if (newArr[i].cloudType == type) {
                                newArr[i].count--;
                            }
                        }
                        newArr = newArr.filter(function (item) {
                            return item.count > 0;
                        })
                        setcloudCounts(newArr)
                    }
                });
        }


    }


    function actionSettingUpdate(e, data) {
        var dataToPost = {
            "id": data.id,
            "accountId": data.accountId,
            "userId": data.userId,
            "cloudType": data.cloudType,
            "name": data.name,
            "accessKey": data.accessKey,
            "secretKey": data.secretKey,
            "credentialsData": data.credentialsData,
            "serviceUrl": data.serviceUrl,
            "active": data.active,
            "shared": e,
            "internalStorage": data.internalStorage,
            "storageTier": data.storageTier,
            "deleted": data.deleted
        }
        updateDataWithToken("/api/v1/cloudaccount/manage_share", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, res]) => {
                console.log(res)
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = res.type;
                    setError499(res.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: e ? data.name + " is now set to Public. Other users can access it." : data.name + " is now set to Private. Only you can access it.",
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 3
                    });

                    let newArr = [...cloudAccounts];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id == data.id) {
                            newArr[i].shared = e;
                        }
                    }
                    setCloudAccounts(newArr)
                }
            });
    }


    function deleteDrivesSync(id) {
        if (cloudDrives.length > 0) {
            let newcloudDrives = [...cloudDrives];
            newcloudDrives = newcloudDrives.filter((item) => item.account.id === id)
            newcloudDrives.map(function (item) {
                    if (user.userType !== 32 && user.userType !== 64) {
                        if (item.sharedDrive) {
                            deleteDataWeb('/api/sharedclouddrive/delete?id=' + item.sharedDriveId)
                                .then((res) => Promise.all([res.status, res.text()]))
                                .then(([status, data]) => {
                                    if (status === 401) {
                                        logout()
                                        return;
                                    }
                                    if (status === 500) {
                                        setPopup500(true)
                                        return;
                                    }
                                    if (status === 499) {
                                        var response = JSON.parse(data)
                                        var errorType = response.type;
                                        setError499(response.errors[errorType][0])
                                        setPopup499(true)
                                        return;
                                    }
                                    if (status === 200) {

                                        deleteData('/Management/clouddrive/' + item.id)
                                            .then((res) => Promise.all([res.status, res.text()]))
                                            .then(([status, data]) => {
                                                if (status === 401) {
                                                    logout()
                                                    return;
                                                }
                                                if (status === 500) {
                                                    setPopup500(true)
                                                    return;
                                                }
                                                if (status === 499) {
                                                    var response = JSON.parse(data)
                                                    var errorType = response.type;
                                                    setError499(response.errors[errorType][0])
                                                    setPopup499(true)
                                                    return;
                                                }
                                                setReloadDrivesFromAccounts(true)
                                            });
                                    }
                                });
                        }
                    } else {
                        deleteData('/Management/clouddrive/' + item.id)
                            .then((res) => Promise.all([res.status, res.text()]))
                            .then(([status, data]) => {
                                if (status === 401) {
                                    logout()
                                    return;
                                }
                                if (status === 500) {
                                    setPopup500(true)
                                    return;
                                }
                                if (status === 499) {
                                    var response = JSON.parse(data)
                                    var errorType = response.type;
                                    setError499(response.errors[errorType][0])
                                    setPopup499(true)
                                    return;
                                }
                                setReloadDrivesFromAccounts(true)
                            });
                    }
                }
            )
        }
        if (backupInstance.length > 0) {
            let newSync = [...backupInstance];
            newSync = newSync.filter((item) => item.account.id === id)

            newSync.map(function (item) {
                deleteData('/Management/cloudbackup/' + item.id)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var response = JSON.parse(data)
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setReloadBackups(true)
                    });
            });
        }
    }

    function deleteBucket(bucket, item) {
        deleteDataWeb('/api/cloudaccount/delete_bucket?bucketName=' + bucket)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {

                if (status === 401) {
                    setEndpoint("/api/cloudaccount/add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: 'Bucket removed successfully.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    let newArr = [...cloudAccounts];
                    for (var i = 0; i < newArr.length; i++) {
                        if (newArr[i].id == item.id) {
                            newArr[i].buckets = newArr[i].buckets.filter((buck) => buck.name !== bucket)

                        }
                    }
                    setCloudAccounts(newArr)
                }

            });
    }

    useEffect(() => {
        if (userStatus.status === "trial" || userStatus.status === "active") {
            if (!refreshData && firstLoad) {
                api.open({
                    message: 'All Cloud Accounts have been updated.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 3,
                });
            }
            setFirstLoad(true)
        }
    }, [refreshData]);
    useEffect(() => {
        if (cloudAccountsLoaded) {
            setCloudAccountsLoaded(false)
            if (lastid !== null && lastid !== undefined) {
                showBucket(lastid, false);
                setActiveKey(["0", "1"]);
                callback({"accounts": true})
            }
        }
        if (showTourDashboard) {
            setAgentPopupOpen(false)
            let newArr = [...cloudAccounts];
            if (newArr.length > 0) {
                newArr[0].showBucket = true;
                setCloudAccounts(newArr)
            }
        }
    }, [cloudAccountsLoaded, showTourDashboard]);


    const cloudAccount = (
        <div className={`connected-accounts-header`}>
            <h3>Connections</h3>
            {cloudCounts.length > 0 && (
                <ul className="login-accounts">
                    {cloudCounts.map((item, i) => (
                        <li key={i} className={` ${item.count > 0 ? "show" : ""}`}>
                            {getIcon(item.cloudType, item.cloudType)}
                            <span className={` ${item.count > 1 ? "show" : ""}`}>
                            {item.count}
                            </span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );

    function showBucket(id, current) {
        let newArr = [...cloudAccounts];
        for (var i = 0; i < newArr.length; i++) {
            newArr[i].showBucket = false;
            if (newArr[i].id == id && current == false) {
                newArr[i].showBucket = true;
            }
        }
        setCloudAccounts(newArr)
    }

    function getFormatedDate(d) {
        var date = new Date(d)
        return ('0' + (date.getMonth() + 1)).slice(-2) + "-" + ('0' + date.getDate()).slice(-2) + "-" + date.getFullYear();
    }


    const createSharedCloudDrive = () => {
        setLoading(true)
        var dataToPost = {
            "cloudAccountId": selectedCloudAccount.id,
            "name": yourCloudDriveName,
            "objectsPerFolder": objects,
            "allowDelete": false,
            "storageName": bucket,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
        }

        postDataWebNoResponse("/api/sharedclouddrive/insert", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }

                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true);
                    setLoading(false);
                    return;
                }
                setYourCloudDriveName("")
                let newArr = [...cloudAccounts];
                for (var i = 0; i < newArr.length; i++) {
                    for (var j = 0; j < newArr[i].buckets.length; j++) {
                        newArr[i].buckets[j].createDrive = false;
                    }
                }
                setCloudAccounts(newArr)
                api.open({
                    message: yourCloudDriveName + ' Drive has been successfully created.',
                    icon: <img src={toastIcon} alt="toastIcon"/>,
                    duration: 5,
                });
                setLoading(false)
                sendDataToMixPanel('Drive has been successfully set up.', user.username, {
                    'App': 'Web App',
                    'Drive Name': yourCloudDriveName
                })
                if (showTourDashboard) {
                    setShowTourDashboard(false)
                    setTourStep(0)
                    localStorage.setItem('dashboardTourStep', 0)
                }
                AssignUserToDrive(data.id)
                setTimeout(function () {
                    setDefaultOpenCloudDrive(true)
                    var shorNextTour = localStorage.getItem('shorNextTour')
                    if (shorNextTour === null || shorNextTour === "false") {
                        localStorage.setItem('shorNextTour', "false")
                        setShowInvitePopup(true)
                        setShowDrivePopup(true)
                    }
                    setLoading(false)
                }, 3000)
            })
    };

    const AssignUserToDrive = (sharedCloudDriveId) => {
        var dataToPost = [{
            "userId": user.userId,
            "sharedCloudDriveId": sharedCloudDriveId,
            "permissionType": 2
        }]
        postDataWebNoResponse("/api/permission/users_sharedclouddrive_permision", dataToPost)
            .then((res) => Promise.all([res.status, res.text()]))
            .then(([status, data]) => {
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var response = JSON.parse(data)
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                sendDataToMixPanel('User assign to Drive.', user.username, {
                    'App': 'Web App'
                })
                sync('/Management/sync_shareddrive')
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var response = JSON.parse(data)
                            var errorType = response.type;
                            setError499(response.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setReloadDrivesFromAccounts(true);
                        setOpenCloudDriveAccordion(true)
                    });

            })

    };

    const setupCloudDrive = () => {
        setLoading(true)
        var driveType = 1;
        var cSetting = 1;
        var cachePath = localCachePath;

        var cSize = (cacheSize * 1024 * 1024 * 1024);
        if (local === 'folder') {
            driveType = 2;
            cSize = 0;
        }
        if (cacheSettingsGBTB === "TB") {
            cSize = cSize * 1024;
        }
        if (cacheSettings === "stream") {
            cSetting = 1;
        }
        if (cacheSettings === "mirror") {
            cSetting = 2;
        }
        var localPath = '';

        if (driveType === 1) {
            localPath = selectedAvailableDrive;
        }
        if (driveType === 2) {
            localPath = selectedPath;
            cachePath = selectedPath;
        }

        if (directoryType === 'automatic') {
            cachePath = "$$$";
        }

        if (!isWindows) {
            localPath = "A";
            cachePath = "/";
        }

        if (!agentIsRunning) {
            setAgentPopupOpen(true);
            return;
        }

        var dataToPost = {
            "name": yourCloudDriveName,
            "driveName": localPath,
            "storageName": bucket,
            "permanentLocalPath": cachePath,
            "driveType": driveType,
            "syncType": cSetting,
            "rotationalCache": rotationalCache,
            "maximumCacheSize": cSize,
            "objectsPerFolder": objects,
            "localCacheEncrypted": localCacheEncrypted,
            "cloudObjectsEncrypted": false,
            "account": {
                "id": selectedCloudAccount.id,
                "accountId": selectedCloudAccount.accountId,
                "cloudType": selectedCloudAccount.cloudType,
                "name": selectedCloudAccount.name,
                "accessKey": selectedCloudAccount.accessKey,
                "secretKey": selectedCloudAccount.secretKey,
                "credentialsData": selectedCloudAccount.credentialsData,
                "serviceUrl": selectedCloudAccount.serviceUrl,
                "active": selectedCloudAccount.active,
                "internalStorage": selectedCloudAccount.internalStorage,
                "deleted": selectedCloudAccount.deleted
            }
        }

        if (selectedCloudAccount.cloudIconType === 'storj' || selectedCloudAccount.cloudIconType === 'Amove') {
            dataToPost.transferOptions = {
                "chunkSize": 64 * 1024 * 1024,
            }
        }

        if (!limitExceed) {
            postDataNoResponse('/Management/clouddrive', dataToPost)
                .then((res) => Promise.all([res.status, res.json()]))
                .then(([status, data]) => {
                    if (status === 401) {
                        logout()
                        return;
                    }
                    if (status === 500) {
                        setLoading(false)
                        setPopup500(true)
                        return;
                    }

                    if (status === 499) {
                        var errorType = data.type;
                        setError499(data.errors[errorType][0])
                        setPopup499(true)
                        return;
                    }
                    api.open({
                        message: yourCloudDriveName + ' Drive has been successfully set up.',
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5,
                    });
                    if (showTourDashboard) {
                        setShowTourDashboard(false)
                        setTourStep(0)
                        localStorage.setItem('dashboardTourStep', 0)
                    }
                    setLoading(false)
                    sendDataToMixPanel('Drive has been successfully set up.', user.username, {
                        'App': 'Web App',
                        'Drive Name': yourCloudDriveName
                    })
                    setReloadDrivesFromAccounts(true)
                    setOpenCloudDriveAccordion(true)
                    setYourCloudDriveName("")

                    let newArr = [...cloudAccounts];
                    for (var i = 0; i < newArr.length; i++) {
                        for (var j = 0; j < newArr[i].buckets.length; j++) {
                            newArr[i].buckets[j].createDrive = false;
                        }
                    }
                    setLoading(false)
                    setCloudAccounts(newArr)
                })
                .catch(() => {
                    setIsCMDDone(false);
                    setAgentIsRunning(false)
                    setAgentPopupOpen(true)
                });
        } else {
            setAgentPopupOpen(true)
            setLoading(false)
        }
    }

    const cloudDriveName = (event) => {
        const name = event.target.value;
        setYourCloudDriveName(name)
        setShowCloudDriveNameError(false)
        setShowCloudDriveNameLengthError(false)
        if (name.length > 1) {
            var result1 = cloudDrives.filter(item => item.name.toLowerCase() === name.toLowerCase());
            var result2 = backupInstance.filter(item => item.name.toLowerCase() === name.toLowerCase());
            if (result1.length > 0 || result2.length > 0) {
                setActive(false);
                setShowCloudDriveNameError(true)
                if (showTourDashboard) {
                    setTourStep(3)
                    localStorage.setItem('dashboardTourStep', 3)
                }
            } else {
                if (!checkLength(name, 32)) {
                    setShowCloudDriveNameLengthError(true)
                } else {
                    setShowCloudDriveNameLengthError(false)
                    setActive(true);
                    if (showTourDashboard) {
                        setTourStep(4)
                        localStorage.setItem('dashboardTourStep', 4)
                    }
                }
            }
        } else {
            setActive(false);
            if (showTourDashboard) {
                setTourStep(3)
                localStorage.setItem('dashboardTourStep', 3)
            }
        }
    }
    return (
        <> {contextHolder}
            {showTourDashboard &&
                <div className="tour-overlay"></div>
            }
            <div className={`tour-tooltip tour-step-1 ${showTourDashboard && tourStep === 1 ? "active" : ""}`}>
                <div className="tour-tooltip-wrapper">
                    <a href="#" className="cross" onClick={(e) => {
                        e.preventDefault();
                        if (showTourDashboard) {
                            setTourStep(0);
                            localStorage.setItem('dashboardTourStep', 0)
                        }
                        window.history.pushState({}, null, "/");
                        setShowTourDashboard(false)
                    }}>
                        <img src={crossBalckIcon} alt="icon"/>
                    </a>
                    <h3>Amove Storage Now Available</h3>
                    <p>25GB is now available directly from your Amove Drive. <br/>
                        1TB free for each user with an Amove Subscription.</p>
                    <div className="btn-wrapper">
                        <a href="#" className="btn-tour" onClick={(e) => {
                            e.preventDefault();
                            if (showTourDashboard) {
                                setTourStep(2);
                                localStorage.setItem('dashboardTourStep', 2)
                            }
                            window.history.pushState({}, null, "/");
                        }}>Let's get started</a>
                    </div>
                </div>
            </div>
            <div className={`tour-tooltip tour-create-drive tour-step-5  ${showTourDashboard && tourStep === 5 ? "active" : ""}`}>
                <div className="tour-tooltip-wrapper">
                    <a href="#" className="cross" onClick={(e) => {
                        e.preventDefault();
                        if (showTourDashboard) {
                            setTourStep(0);
                            localStorage.setItem('dashboardTourStep', 0)
                        }
                        setShowTourDashboard(false)
                    }}>
                        <img src={crossBalckIcon} alt="icon"/>
                    </a>
                    <h3>Create a drive</h3>
                    <ul className="steps">
                        <li className="active"><span className="dot"></span></li>
                        <li className="active"><span className="dot"></span></li>
                        <li className="active"><span className="dot"></span></li>
                    </ul>
                    <div className="launch-amove">
                        <p className="p black bold">Instant Connectivity for Everyone</p>
                        <p className="p black">Set up unlimited Connections, Drives, Syncs and manage
                            all of your teams and content instantly, from anywhere.</p>

                        <p className="p black bold">Download the Amove Desktop App</p>
                        <p className="p black">If you have not installed the Amove app, please do so:</p>


                        <div className="btn-wrapper">
                            {config.environment === 'STG' && !showSingleButton &&
                                <>
                                    {!isWindows &&
                                        <a href='#' onClick={(e) => {
                                            e.preventDefault();
                                            checkAgent(true)
                                            setShowSingleButton(true)
                                            if (showTourDashboard) {
                                                setTourStep(0);
                                                localStorage.setItem('dashboardTourStep', 0)
                                            }
                                            setShowTourDashboard(false)
                                            sendDataToMixPanel('Amove-intel-STG downloaded.', user.username, {
                                                'App': 'Web App'
                                            })
                                            // Create a temporary link element
                                            const link = document.createElement('a');
                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-intel-STG.pkg";
                                            link.download = "Amove-Mac-latest.pkg";
                                            link.target = "_blank";

                                            // Append the link to the body
                                            document.body.appendChild(link);

                                            // Trigger the download by clicking the link
                                            link.click();

                                            // Remove the link from the document
                                            document.body.removeChild(link);
                                        }} className="btn-tour orange">Download Amove</a>
                                    }
                                    {isWindows &&
                                        <a href='#' onClick={(e) => {
                                            e.preventDefault();
                                            checkAgent(true)
                                            setShowSingleButton(true)
                                            if (showTourDashboard) {
                                                setTourStep(0);
                                                localStorage.setItem('dashboardTourStep', 0)
                                            }
                                            setShowTourDashboard(false)
                                            sendDataToMixPanel('Amove-64bit-STG downloaded.', user.username, {
                                                'App': 'Web App'
                                            })
                                            // Create a temporary link element
                                            const link = document.createElement('a');
                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-64bit-STG.exe";
                                            link.download = "Amove-Mac-latest.pkg";
                                            link.target = "_blank";

                                            // Append the link to the body
                                            document.body.appendChild(link);

                                            // Trigger the download by clicking the link
                                            link.click();

                                            // Remove the link from the document
                                            document.body.removeChild(link);
                                        }} className="btn-tour orange">Download Amove</a>
                                    }
                                </>
                            }
                            {config.environment !== 'STG' && !showSingleButton &&
                                <>
                                    {!isWindows &&
                                        <a href='#' onClick={(e) => {
                                            e.preventDefault();
                                            checkAgent(true)
                                            setShowSingleButton(true)
                                            if (showTourDashboard) {
                                                setTourStep(0);
                                                localStorage.setItem('dashboardTourStep', 0)
                                            }
                                            setShowTourDashboard(false)
                                            sendDataToMixPanel('Amove-Mac-latest downloaded.', user.username, {
                                                'App': 'Web App'
                                            })
                                            // Create a temporary link element
                                            const link = document.createElement('a');
                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Mac-latest.pkg";
                                            link.download = "Amove-Mac-latest.pkg";
                                            link.target = "_blank";

                                            // Append the link to the body
                                            document.body.appendChild(link);

                                            // Trigger the download by clicking the link
                                            link.click();

                                            // Remove the link from the document
                                            document.body.removeChild(link);
                                        }} className="btn-tour fix-width orange">Download Amove</a>
                                    }
                                    {isWindows &&
                                        <a href='#' onClick={(e) => {
                                            e.preventDefault();
                                            checkAgent(true)
                                            setShowSingleButton(true)
                                            if (showTourDashboard) {
                                                setTourStep(0);
                                                localStorage.setItem('dashboardTourStep', 0)
                                            }
                                            setShowTourDashboard(false)
                                            sendDataToMixPanel('Amove-Win-latest downloaded.', user.username, {
                                                'App': 'Web App'
                                            })
                                            // Create a temporary link element
                                            const link = document.createElement('a');
                                            link.href = "https://d2h7a876wzviri.cloudfront.net/Amove-Win-latest.exe";
                                            link.download = "Amove-Mac-latest.pkg";
                                            link.target = "_blank";

                                            // Append the link to the body
                                            document.body.appendChild(link);

                                            // Trigger the download by clicking the link
                                            link.click();

                                            // Remove the link from the document
                                            document.body.removeChild(link);
                                        }} className="btn-tour fix-width orange">Download Amove</a>
                                    }
                                </>
                            }
                        </div>

                        <p className="p black bold">Launch the Amove Desktop App</p>
                        <p className="p black">If you already have the app installed, simply launch it:</p>

                        <div className="btn-wrapper">
                            <a href='#' onClick={(e) => {
                                e.preventDefault();
                                sendDataToMixPanel('Launch the Amove Desktop App', user.username, {
                                    'App': 'Web App'
                                })
                                checkAgent(true);
                                window.location.href = `amoveapp://open?token=${token}`

                                if (showTourDashboard) {
                                    setTourStep(0);
                                    localStorage.setItem('dashboardTourStep', 0)
                                }
                                setShowTourDashboard(false);
                                setShowStartedBtn(false)
                                setShowInvitePopup(true)
                                setShowDrivePopup(true)
                                setDefaultOpenCloudDrive(true)
                                localStorage.setItem('shorNextTour', "true")
                            }} className={`btn-tour`}>Launch Amove</a>
                        </div>
                    </div>
                </div>
            </div>
            <div className={`connected-accounts  ${showTourDashboard ? "tour-area" : ""}  ${cloudAccounts.length === 0 ? "hide-arrow-icon" : ""}`}>
                {/*<Collapse defaultActiveKey={[open]} onChange={onChange}>*/}
                <Collapse defaultActiveKey={[open]} onChange={onChange} activeKey={activeKey}>
                    <Panel header={cloudAccount} key="1">
                        {cloudAccounts.length > 0 &&
                            <div className="connected-accounts-body">
                                {cloudAccounts.length > 0 && (<>
                                        <div className="table-header d-none d-md-block">
                                            <div className="row">
                                                {cloudAccounts.filter((item) => item.internalStorage).length < 1 &&
                                                    <>
                                                        {/*<div className="col-md-6 col-5">
                                                    <p>Name</p>
                                                </div>
                                                <div className="col-md-4 col-5">
                                                    <p>Storage</p>
                                                </div>
                                                <div className="col-md-2 col-2 text-end">
                                                    <p>Action</p>
                                                </div>*/}

                                                        <div className="col-md-6 col-3">
                                                            <p>Name</p>
                                                        </div>
                                                        <div className="col-md-4 col-2">
                                                            <p>Storage</p>
                                                        </div>
                                                        <div className="col-md-1 col-2">
                                                            <p>Shared</p>
                                                        </div>
                                                        <div className="col-md-1 col-2 text-end">
                                                            <p>Delete</p>
                                                        </div>
                                                    </>
                                                }

                                                {cloudAccounts.filter((item) => item.internalStorage).length > 0 &&
                                                    <>
                                                        <div className="col-md-5 col-4">
                                                            <p>Name</p>
                                                        </div>
                                                        <div className="col-md-2 col-3 text-center">
                                                            <p>Storage</p>
                                                        </div>
                                                        <div className="col-md-2 col-2 text-center">
                                                            <p></p>
                                                        </div>
                                                        <div className="col-md-1 col-2 text-center">
                                                            <p></p>
                                                        </div>
                                                        <div className="col-md-1 col-2 text-center">
                                                            <p>Shared</p>
                                                        </div>
                                                        <div className="col-md-1 col-1 text-end">
                                                            <p>Delete</p>
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        <div className="table-body">
                                            <ul>
                                                {cloudAccounts.map((item, accountIndex) => (
                                                    <div className="table-body-card" key={item.id}>
                                                        <div className="row cursor-pointer" onClick={(e) => {

                                                            if (!e.target.classList.contains('del-image') && !e.target.closest('.dont-open')) {
                                                                showBucket(item.id, item.showBucket)
                                                            }

                                                        }}>

                                                            {cloudAccounts.filter((item) => item.internalStorage).length < 1 &&
                                                                <>
                                                                    <div className="col-md-6 col-12 align-self-center">
                                                                        <div className="account-detail">
                                                                            <div className="img-wrapper">
                                                                                {getIcon(item.cloudType, item.cloudIconType)}
                                                                            </div>
                                                                            <div className="content-wrapper">
                                                                                <p className="medium">{item.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-4 col-12 align-self-center">
                                                                        {item.buckets.length > 0 && (
                                                                            <p className="medium">{item.buckets.length} buckets</p>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-1 col-12 align-self-center">
                                                                        <ul>
                                                                            <li className="dont-open">
                                                                                <Switch onChange={(e) => {
                                                                                    if (!limitExceed) {
                                                                                        actionSettingUpdate(e, item)
                                                                                    }
                                                                                }} defaultChecked={item.shared} className={`dont-open ${item.userId !== user.userId ? "disabled" : ""}`}/>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-md-1 col-12 align-self-center">
                                                                        <ul className="icons dont-open">
                                                                            <li className="delete-wrapper"><Popconfirm
                                                                                className="dont-open"
                                                                                placement="topLeft"
                                                                                title={<>
                                                                                    Once the {item.name} storage connection is deleted, all of the Drives that it supports will be deleted as well for all users. <br/>Are you sure you want to delete it?
                                                                                </>}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                onConfirm={() => deleteCloudAccount(item, item.showBucket)}
                                                                            >
                                                                                <img src={trashIcon} className="del-image" alt="logo"/>
                                                                            </Popconfirm></li>
                                                                        </ul>
                                                                    </div>
                                                                </>
                                                            }

                                                            {cloudAccounts.filter((item) => item.internalStorage).length > 0 &&
                                                                <>
                                                                    <div className="col-md-5 col-12 align-self-center">
                                                                        <div className="account-detail">
                                                                            <div className="img-wrapper">
                                                                                {getIcon(item.cloudType, item.cloudIconType)}
                                                                            </div>
                                                                            <div className="content-wrapper">
                                                                                <p className="medium">{item.name}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-2 col-12 align-self-center text-center">
                                                                        {item.buckets.length > 0 && (
                                                                            <p className="medium">{item.buckets.length} buckets</p>
                                                                        )}
                                                                    </div>
                                                                    <div className="col-md-2 col-12 text-center">
                                                                        {item.totalUsedSpaceSize !== undefined &&
                                                                            <p>{prettySize(item.totalUsedSpaceSize * 1024 * 1024 * 1024)}</p>
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-1 col-12 text-center">
                                                                        {item.totalObjectCount !== undefined &&
                                                                            <p>{item.totalObjectCount}</p>
                                                                        }
                                                                    </div>
                                                                    <div className="col-md-1 col-12 text-md-center text-start">
                                                                        <ul>
                                                                            <li className="dont-open">
                                                                                <Switch onChange={(e) => {
                                                                                    if (!limitExceed) {
                                                                                        actionSettingUpdate(e, item)
                                                                                    }
                                                                                }} defaultChecked={item.shared} className={`dont-open ${item.userId !== user.userId ? "disabled" : ""}`}/>
                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                    <div className="col-md-1 col-12 align-self-center">
                                                                        <ul className="icons dont-open">
                                                                            <li className="delete-wrapper"><Popconfirm
                                                                                placement="topLeft"
                                                                                title={<>
                                                                                    Once the {item.name} storage connection is deleted, all of the Drives that it supports will be deleted as well for all users. <br/>Are you sure you want to delete it?
                                                                                </>}
                                                                                okText="Yes"
                                                                                cancelText="No"
                                                                                getPopupContainer={trigger => trigger.parentNode}
                                                                                onConfirm={() => deleteCloudAccount(item)}
                                                                            >
                                                                                <img src={trashIcon} className="del-image" alt="logo"/>
                                                                            </Popconfirm></li>
                                                                        </ul>
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>

                                                        {item.buckets.length > 0 && (
                                                            <>
                                                                {item.showBucket &&
                                                                    <div className="buckets-list-wrapper">
                                                                        <div className="buckets-list">
                                                                            <div className="buckets-header d-none d-md-block">
                                                                                <div className="row">
                                                                                    {!item.internalStorage &&
                                                                                        <>
                                                                                            <div className="col-md-3 col-3 align-self-center">
                                                                                                <p className="medium">Bucket Name</p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-2 align-self-center text-center">
                                                                                                <p className="medium">Region</p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-2 align-self-center text-center">
                                                                                                <p className="medium">Created
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-2 align-self-center text-center">
                                                                                                <p className="medium">Add New Drive
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-3 align-self-center text-center">
                                                                                                <p className="medium last-item">Total Drives</p>
                                                                                            </div>
                                                                                        </>
                                                                                    }
                                                                                    {item.internalStorage &&
                                                                                        <>
                                                                                            <div className="col-md-2 col-2 align-self-center">
                                                                                                <p className="medium">Bucket Name</p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-2 align-self-center text-center">
                                                                                                <p className="medium">Region</p>
                                                                                            </div>
                                                                                            <div className="col-md-1 col-1 align-self-center text-center">
                                                                                                <p className="medium">Created
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-2 align-self-center text-center">
                                                                                                <p className="medium"></p>
                                                                                            </div>
                                                                                            <div className="col-md-3 col-2 align-self-center text-center">
                                                                                                <p className="medium">Add New Drive
                                                                                                </p>
                                                                                            </div>
                                                                                            <div className="col-md-2 col-3 align-self-center text-center">
                                                                                                <p className="medium last-item">Total Drives</p>
                                                                                            </div>
                                                                                        </>

                                                                                    }
                                                                                </div>
                                                                            </div>
                                                                            <div className="buckets-wrapper">
                                                                                {
                                                                                    item.buckets.map((bucket, index) => (
                                                                                        <div className="row">
                                                                                            {!item.internalStorage &&
                                                                                                <>
                                                                                                    <div className="col-md-3 col-12 align-self-center">
                                                                                                        <p className="medium">{bucket.name}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-12 align-self-center text-center">
                                                                                                        <p className="medium">{bucket.region}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-12 align-self-center text-center">
                                                                                                        <p className="medium text-nowrap">{getFormatedDate(bucket.creationDate)}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-3 col-12 align-self-center create-btn text-center">
                                                                                                        {item.cloudType !== 256 && item.cloudType !== 512 && item.cloudType !== 1024 &&
                                                                                                            <p className="medium tour-parent"><a className={`btn-style small ${bucket.createDrive ? "active" : ""}`} href="#" onClick={(e) => {
                                                                                                                e.preventDefault();

                                                                                                                if (agentIsRunning) {
                                                                                                                    setChowCreateCloudDrive(true)
                                                                                                                    setBucket(bucket.name)
                                                                                                                    setSelectedCloudAccountID(item.id)
                                                                                                                } else {
                                                                                                                    if (showTourDashboard) {
                                                                                                                        setTourStep(5)
                                                                                                                        localStorage.setItem('dashboardTourStep', 5)
                                                                                                                    } else {
                                                                                                                        setAgentPopupOpen(true)
                                                                                                                    }
                                                                                                                }
                                                                                                                /*
                                                                                                                let indexAccount = cloudAccounts.findIndex(x => x.id == item.id);
                                                                                                                setSelectedCloudAccount(cloudAccounts[indexAccount]);
                                                                                                                setSelectedAvailableDrive('');
                                                                                                                setPreSelectedAccountID("");
                                                                                                                setYourCloudDriveName("")
                                                                                                                let newArr = [...cloudAccounts];
                                                                                                                for (var i = 0; i < newArr.length; i++) {
                                                                                                                    for (var j = 0; j < newArr[i].buckets.length; j++) {
                                                                                                                        newArr[i].buckets[j].createDrive = false;
                                                                                                                    }
                                                                                                                }
                                                                                                                newArr[accountIndex].buckets[index].createDrive = true;
                                                                                                                setCloudAccounts(newArr);
                                                                                                                setShowCloudDriveNameError(false)
                                                                                                                setActive(false);
                                                                                                                if (showTourDashboard) {
                                                                                                                    setTourStep(3)
                                                                                                                    localStorage.setItem('dashboardTourStep', 3)
                                                                                                                }*/
                                                                                                            }}>Create Drive</a>
                                                                                                                {index === 0 &&
                                                                                                                    <div className={`tour-tooltip tour-step-2 ${showTourDashboard && tourStep === 2 ? "active" : ""}`}>
                                                                                                                        <div className="tour-tooltip-wrapper">
                                                                                                                            <a href="#" className="cross" onClick={(e) => {
                                                                                                                                e.preventDefault();
                                                                                                                                if (showTourDashboard) {
                                                                                                                                    setTourStep(0);
                                                                                                                                    localStorage.setItem('dashboardTourStep', 0)
                                                                                                                                }
                                                                                                                                setShowTourDashboard(false)
                                                                                                                            }}>
                                                                                                                                <img src={crossBalckIcon} alt="icon"/>
                                                                                                                            </a>
                                                                                                                            <h3>Add New Drive</h3>
                                                                                                                            <ul className="steps">
                                                                                                                                <li className="active"><span className="dot"></span></li>
                                                                                                                                <li><span className="dot"></span></li>
                                                                                                                                <li><span className="dot"></span></li>
                                                                                                                            </ul>
                                                                                                                            <p className="p mb-0">2 simple steps. Click here to start.</p>
                                                                                                                            {/*<p className="mb-0">Please click "Create a Drive" to proceed</p>*/}
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                }
                                                                                                            </p>
                                                                                                        }
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-12 align-self-center text-md-center text-start">
                                                                                                        {/*{bucket.createDrive &&
                                                                                                            <div className={`form-group tour-parent ${isActive ? "help-circle" : null}`}>
                                                                                                                <input type="text" className="form-control" value={yourCloudDriveName} placeholder="Name your drive" onChange={cloudDriveName}/>
                                                                                                                <a href="#" className={`icon ${!isActive ? "disabled-s" : ""} ${!isActive || loading ? "disabled" : ""}`} onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    if (agentIsRunning) {
                                                                                                                    if (isActive && !loading) {
                                                                                                                            if (user.userType !== 32) {
                                                                                                                                createSharedCloudDrive()
                                                                                                                            } else {
                                                                                                                                setupCloudDrive()
                                                                                                                            }
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        if (showTourDashboard) {
                                                                                                                            setTourStep(5)
                                                                                                                            localStorage.setItem('dashboardTourStep', 5)
                                                                                                                        } else {
                                                                                                                            setAgentPopupOpen(true)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }}>+</a>
                                                                                                                {showCloudDriveNameError &&
                                                                                                                    <span className="red-error cloud-name">That drive name has already been used.</span>
                                                                                                                }
                                                                                                                {index === 0 &&
                                                                                                                <div className={`tour-tooltip tour-step-3 ${showTourDashboard && tourStep === 3 ? "active" : ""}`}>
                                                                                                                    <div className="tour-tooltip-wrapper">
                                                                                                                        <a href="#" className="cross" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            if (showTourDashboard) {
                                                                                                                                setTourStep(0);
                                                                                                                                localStorage.setItem('dashboardTourStep', 0)
                                                                                                                            }
                                                                                                                            setShowTourDashboard(false)
                                                                                                                        }}>
                                                                                                                            <img src={crossBalckIcon} alt="icon"/>
                                                                                                                        </a>
                                                                                                                        <h3>Create a drive</h3>
                                                                                                                        <ul className="steps">
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li><span className="dot"></span></li>
                                                                                                                        </ul>
                                                                                                                        <p className="p">Name a drive</p>
                                                                                                                        <p className="mb-0">Please name the drive to continue.</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                }
                                                                                                                {index === 0 &&
                                                                                                                <div className={`tour-tooltip tour-step-4 ${showTourDashboard && tourStep === 4 ? "active" : ""}`}>
                                                                                                                    <div className="tour-tooltip-wrapper">
                                                                                                                        <a href="#" className="cross" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            if (showTourDashboard) {
                                                                                                                                setTourStep(0);
                                                                                                                                localStorage.setItem('dashboardTourStep', 0)
                                                                                                                            }
                                                                                                                            setShowTourDashboard(false)
                                                                                                                        }}>
                                                                                                                            <img src={crossBalckIcon} alt="icon"/>
                                                                                                                        </a>
                                                                                                                        <h3>Create a drive</h3>
                                                                                                                        <ul className="steps">
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                        </ul>
                                                                                                                        <p className="p">Add a drive</p>
                                                                                                                        <p className="mb-0">Next, click "+" button to continue.</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        }*/}
                                                                                                        {bucket.totalDrives > 0 &&
                                                                                                            <>{bucket.totalDrives}</>
                                                                                                        }
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                            {item.internalStorage &&
                                                                                                <>
                                                                                                    <div className="col-md-2 col-12 align-self-center">
                                                                                                        <p className="medium">{bucket.name}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-12 align-self-center text-center">
                                                                                                        <p className="medium">{item.credentialsData}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-1 col-12 align-self-center text-center">
                                                                                                        <p className="medium text-nowrap">{getFormatedDate(bucket.creationDate)}</p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-12 align-self-center text-center">
                                                                                                        <p className="medium">{/*{prettySize(bucket.usedSpaceSize * 1024 * 1024 * 1024)}*/}</p>
                                                                                                    </div>
                                                                                                    {/*<div className="col-md-1 col-1 align-self-center text-center">
                                                                                                    <p className="medium">{bucket.objectCount}</p>
                                                                                                </div>
*/}

                                                                                                    <div className="col-md-3 col-12 align-self-center text-md-center text-start">
                                                                                                        <p className="medium tour-parent"><a className={`btn-style small ${bucket.createDrive ? "active" : ""}`} href="#" onClick={(e) => {
                                                                                                            e.preventDefault();
                                                                                                            if (agentIsRunning) {
                                                                                                                setChowCreateCloudDrive(true)
                                                                                                                setBucket(bucket.name)
                                                                                                                setSelectedCloudAccountID(item.id)
                                                                                                            } else {
                                                                                                                if (showTourDashboard) {
                                                                                                                    setTourStep(5)
                                                                                                                    localStorage.setItem('dashboardTourStep', 5)
                                                                                                                } else {
                                                                                                                    setAgentPopupOpen(true)
                                                                                                                }
                                                                                                            }

                                                                                                            /*setBucket(bucket.name)
                                                                                                            let indexAccount = cloudAccounts.findIndex(x => x.id == item.id);
                                                                                                            setSelectedCloudAccount(cloudAccounts[indexAccount]);
                                                                                                            setSelectedAvailableDrive('');
                                                                                                            setPreSelectedAccountID("");
                                                                                                            setYourCloudDriveName("");
                                                                                                            setShowCloudDriveNameLengthError(false)
                                                                                                            let newArr = [...cloudAccounts];
                                                                                                            for (var i = 0; i < newArr.length; i++) {
                                                                                                                for (var j = 0; j < newArr[i].buckets.length; j++) {
                                                                                                                    newArr[i].buckets[j].createDrive = false;
                                                                                                                }
                                                                                                            }
                                                                                                            newArr[accountIndex].buckets[index].createDrive = true;
                                                                                                            setCloudAccounts(newArr);
                                                                                                            setShowCloudDriveNameError(false)
                                                                                                            setActive(false);
                                                                                                            if (showTourDashboard) {
                                                                                                                setTourStep(3)
                                                                                                                localStorage.setItem('dashboardTourStep', 3)
                                                                                                            }*/
                                                                                                        }}>Create Drive</a>
                                                                                                            {index === 0 &&
                                                                                                                <div className={`tour-tooltip tour-step-2 ${showTourDashboard && tourStep === 2 ? "active" : ""}`}>
                                                                                                                    <div className="tour-tooltip-wrapper">
                                                                                                                        <a href="#" className="cross" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            if (showTourDashboard) {
                                                                                                                                setTourStep(0);
                                                                                                                                localStorage.setItem('dashboardTourStep', 0)
                                                                                                                            }
                                                                                                                            setShowTourDashboard(false)
                                                                                                                        }}>
                                                                                                                            <img src={crossBalckIcon} alt="icon"/>
                                                                                                                        </a>
                                                                                                                        <h3>Add New Drive</h3>
                                                                                                                        <ul className="steps">
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li><span className="dot"></span></li>
                                                                                                                            <li><span className="dot"></span></li>
                                                                                                                        </ul>
                                                                                                                        <p className="p mb-0">Just a few simple steps. Click here to begin.</p>
                                                                                                                        {/*<p className="mb-0">Please click "Create a Drive" to proceed</p>*/}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            }
                                                                                                        </p>
                                                                                                    </div>
                                                                                                    <div className="col-md-2 col-12 align-self-center text-center">
                                                                                                        {bucket.totalDrives > 0 &&
                                                                                                            <>{bucket.totalDrives}</>
                                                                                                        }
                                                                                                        {/*{bucket.createDrive &&
                                                                                                            <div className={`form-group tour-parent ${isActive ? "help-circle" : null}`}>
                                                                                                                <input type="text" className="form-control" value={yourCloudDriveName} placeholder="Name your drive" onChange={cloudDriveName}/>
                                                                                                                <a href="#" className={`icon ${!isActive ? "disabled-s" : ""} ${!isActive || loading || showCloudDriveNameLengthError ? "disabled" : ""}`} onClick={(e) => {
                                                                                                                    e.preventDefault();
                                                                                                                    if (agentIsRunning) {
                                                                                                                    if (isActive && !loading) {
                                                                                                                            if (user.userType !== 32) {
                                                                                                                                createSharedCloudDrive()
                                                                                                                            } else {
                                                                                                                                setupCloudDrive()
                                                                                                                            }
                                                                                                                        }
                                                                                                                    } else {
                                                                                                                        if (showTourDashboard) {
                                                                                                                            setTourStep(5)
                                                                                                                            localStorage.setItem('dashboardTourStep', 5)
                                                                                                                        } else {
                                                                                                                            setAgentPopupOpen(true)
                                                                                                                        }
                                                                                                                    }
                                                                                                                }}>+</a>
                                                                                                                {showCloudDriveNameError &&
                                                                                                                    <span className="red-error cloud-name">That drive name has already been used.</span>
                                                                                                                }
                                                                                                                {showCloudDriveNameLengthError && <span className="red-error cloud-name">32 characters max allowed</span>}
                                                                                                                {index === 0 &&
                                                                                                                <div className={`tour-tooltip tour-step-3 ${showTourDashboard && tourStep === 3 ? "active" : ""}`}>
                                                                                                                    <div className="tour-tooltip-wrapper">
                                                                                                                        <a href="#" className="cross" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            if (showTourDashboard) {
                                                                                                                                setTourStep(0);
                                                                                                                                localStorage.setItem('dashboardTourStep', 0)
                                                                                                                            }
                                                                                                                            setShowTourDashboard(false)
                                                                                                                        }}>
                                                                                                                            <img src={crossBalckIcon} alt="icon"/>
                                                                                                                        </a>
                                                                                                                        <h3>Add New Drive</h3>
                                                                                                                        <ul className="steps">
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li><span className="dot"></span></li>
                                                                                                                        </ul>
                                                                                                                        <p className="p mb-0">Name your drive and launch it!</p>
                                                                                                                         <p className="mb-0">Please name the drive to continue.</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                }

                                                                                                                {index === 0 &&
                                                                                                                <div className={`tour-tooltip tour-step-4 ${showTourDashboard && tourStep === 4 ? "active" : ""}`}>
                                                                                                                    <div className="tour-tooltip-wrapper">
                                                                                                                        <a href="#" className="cross" onClick={(e) => {
                                                                                                                            e.preventDefault();
                                                                                                                            if (showTourDashboard) {
                                                                                                                                setTourStep(0);
                                                                                                                                localStorage.setItem('dashboardTourStep', 0)
                                                                                                                            }
                                                                                                                            setShowTourDashboard(false)
                                                                                                                        }}>
                                                                                                                            <img src={crossBalckIcon} alt="icon"/>
                                                                                                                        </a>
                                                                                                                        <h3>Add New Drive</h3>
                                                                                                                        <ul className="steps">
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                            <li className="active"><span className="dot"></span></li>
                                                                                                                        </ul>
                                                                                                                        <p className="p mb-0">Name your drive and launch it!</p>
                                                                                                                         <p className="mb-0">Please name the drive to continue.</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                }
                                                                                                            </div>
                                                                                                        }*/}
                                                                                                        {/*<ul className="icons">
                                                                                                        <li className="delete-wrapper"><Popconfirm
                                                                                                            placement="topLeft"
                                                                                                            title="Are you sure to delete this bucket?"
                                                                                                            okText="Yes"
                                                                                                            cancelText="No"
                                                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                                                            onConfirm={() => deleteBucket(bucket.name, item)}
                                                                                                        >
                                                                                                            <img src={trashIcon} className="del-image" alt="logo"/>
                                                                                                        </Popconfirm></li>
                                                                                                    </ul>*/}
                                                                                                    </div>
                                                                                                </>
                                                                                            }
                                                                                        </div>
                                                                                    ))
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                }
                                                            </>
                                                        )}
                                                    </div>
                                                ))}
                                            </ul>
                                        </div>
                                    </>
                                )}
                            </div>
                        }
                        <NFS/>
                        <SMB/>
                    </Panel>
                </Collapse>
            </div>

            <SelectSharedDrive isManualGroup={showCreateCloudDrive} setManualGroup={() => {
                setChowCreateCloudDrive(false)
            }} getAllDrives={() => {
                loadCloudDrives()
            }} selectedCloud={selectedCloudAccountID} selectedB={bucket}/>

            {agentPopupOpen &&
                <AgentOffPopup/>
            }
        </>
    )
}

export {CloudAccounts}
