import React, {useState, useEffect, useRef} from "react";
import {Link, useNavigate, NavLink} from "react-router-dom";
import {notification, Button, Modal, Select, Checkbox, Tooltip} from 'antd';

import FrameIO from "../assets/images/frame.io.svg";
import ibm from "../assets/images/ibm.svg";
import awsLogo from "../assets/images/new-logos/aws-cloud-brand-logo.svg";
import azureLogo from "../assets/images/new-logos/azure-cloud-brand-logo.svg";
import googleCloudLogo from "../assets/images/new-logos/googleCloud.svg";
import wasabiCloudLogo from "../assets/images/new-logos/wasabi-cloud-brand-logo.svg";
import seagateLogo from "../assets/images/new-logos/seagate-logo.svg";
import NFSNewLogo from "../assets/images/new-logos/nfs.svg";
import SMBLogo from "../assets/images/new-logos/smb.svg";
import storjLogo from "../assets/images/new-logos/storj.svg";
import s3Logo from "../assets/images/new-logos/any-s3new.svg";
import DigitalOcean from "../assets/images/new-logos/digitalocean-logo-vector-1.svg";
import Backblaze from "../assets/images/new-logos/Backblaze.svg";
import ovhLogo from "../assets/images/new-logos/OVH.svg";
import perifery from "../assets/images/new-logos/perifery-logo.svg";
import box from "../assets/images/new-logos/box.svg";
import dropBox from "../assets/images/new-logos/dropbox.svg";
import googleDrive from "../assets/images/new-logos/Googledrive.svg";
import oneDrive from "../assets/images/new-logos/one-drive.svg";
import symply from "../assets/images/symply-logo-green.png";
import noData from "../assets/images/add-icon.png";
import toastIcon from "../assets/images/icons/toast-icon.svg";
import warning from "../assets/images/icons/warning-sign.svg";
import {useAuth} from "../context/AuthContext";
import config from "../config";
import pageLogo from "../assets/images/logo.svg";
import popupImage from "../assets/images/popup-image.jpg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import AmoveStorage from "../components/SetupAmoveStorage";
import SetupNFS from "../components/SetupNFS";
import farmeIOIcon from "../assets/images/new-logos/frame.io.svg";
import Cloudflare from "../assets/images/new-logos/Cloudflare_Logo.svg";
import idrive from "../assets/images/new-logos/e2_logo_white_03.svg";
import searchIcon from "../assets/images/icons/search-gray-icon.svg";
import crossBalckIcon from "../assets/images/icons/tour-cross-black-icon.svg";
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';
import ProvisionAmoveStorage from "../components/ProvisionAmoveStorage";
import {tr} from "react-date-range/dist/locale";
import info from "../assets/images/info.svg";

const AddCloudAccount = () => {
    const {
        getData,
        updateCloudAccounts,
        setUpdateCloudAccounts,
        cloudAccounts,
        getCloudAccounts,
        logout,
        isCMDDone,
        agentIsRunning,
        checkAgent,
        cloudCounts,
        getIcon,
        cloudAccountsDone,
        sendDataToMixPanel,
        user,
        isDark,
        setHideBTNs,
        getDataWithTokenPOST,
        hideBTNs,
        isWindows,
        postDataWebNoResponse,
        setPopup500,
        setPopupSupport,
        setEndpoint,
        setShowPopupSupport,
        showTourConnections,
        setShowTourConnections,
        setShowTourDashboard,
        getDataWithToken,
        setAgentPopupOpen,
        setError499,
        setPopup499,
        regions,
        setRegions,
        token,
        userStatus,
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [loading, setLoading] = useState(false);
    const [loadingAmove, setLoadingAmove] = useState(false);
    const [showError, setShowError] = useState(false);
    const [showMessage, setShowMessage] = useState(false);
    const [isCreateStorage, setIsCreateStorage] = useState();
    const [errorMessage, setErrorMessage] = useState();
    const [connectedCloudDrives, setConnectedCloudDrives] = useState(0);
    const [chcekPrivacy, setChcekPrivacy] = useState(false);
    const [radioCheck, setRadiaCheck] = useState({
        'name': "",
        'logo': "",
        'text': "",
        'width': "",
        'value': "",
        'type': "",
        'isActive': false
    })
    const [cloudDetail, setCloudDetail] = useState([]);
    const [errorText, setErrorText] = useState('');
    const [showFreeStorage, setShowFreeStorage] = useState(true);
    const [showButtons, setShowButtons] = useState(false);
    const [showSingleButton, setShowSingleButton] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const urlParams = new URLSearchParams(window.location.search);
    const startTour = urlParams.get('startTour')
    const [selectedRegions, setSelectedRegions] = useState("Select a Region");
    const [showOnlyToolTip, setShowOnlyToolTip] = useState(true);
    const [cloudSidebar, setCloudSidebar] = useState(false);
    const [advanceSettings, setAdvanceSettings] = useState(false);
    const [useSecureTransfer, setUseSecureTransfer] = useState(true);
    const [localNetworkStorage, setLocalNetworkStorage] = useState(false);
    const [signatureVersion, setSignatureVersion] = useState(1);
    const [addressingModel, setAddressingModel] = useState(0);
    const [SSLCertificateValidation, setSSLCertificateValidation] = useState(false);


    const inputEvent = (event) => {
        const name = event.target.name;
        var value = event.target.value;
        // setPrivacyValue
        if (value === "onlyMe") {
            setChcekPrivacy(false)
        } else if (value === "organization") {
            setChcekPrivacy(true)
        }
        if (value.length > 1) {
            event.target.classList.add('active')
        } else {
            event.target.classList.remove('active')
        }

        setShowError(false);

        if (name === "name" && value.length > 15) {
            value = value.substring(0, 15);
            document.getElementById("give-name").value = value
        }

        setCloudDetail((lastValue) => {
            return {
                ...lastValue,
                [name]: value,
                ['cloudType']: parseInt(radioCheck.type),
                ['shared']: chcekPrivacy
            }
        });

        if (event.key === 'Enter') {
            addCloudAccount();
        }
    }

    useEffect(() => {
        if (token !== undefined && token !== null && userStatus.status !== undefined) {
            if (dataFetchedRef.current) return;
            dataFetchedRef.current = true;
            if (cloudAccounts.length === 0) {
                getCloudAccounts();
            }
            if (startTour === "true") {
                setShowTourConnections(true)
            }
        }
    }, [token, userStatus]);

    useEffect(() => {
        if (cloudCounts.length === 0 || (cloudAccounts.filter((item) => item.internalStorage).length > 0 && cloudAccountsDone && cloudCounts.length === 1 && (localStorage.getItem("showOnlyToolTip") === null))) {
            setShowOnlyToolTip(true)
        } else {
            setShowOnlyToolTip(false)
            localStorage.setItem("showOnlyToolTip", "false")
        }
    }, [cloudCounts]);


    /*    useEffect(() => {
            setTimeout(function () {
                if (cloudAccounts.length > 0 && cloudAccountsDone) {
                    if (cloudAccounts.filter((item) => item.internalStorage).length < 1) {
                        setShowFreeStorage(true);
                    }
                } else if (cloudAccountsDone) {
                    setShowFreeStorage(true);
                }
            }, 500)
        }, [cloudAccounts, cloudAccountsDone]);*/


    useEffect(() => {
        getData('/Management/clouddrive')
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, data]) => {
                setLoading(false);
                if (status === 401) {
                    logout()
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    return;
                }
                if (status === 499) {
                    var errorType = data.type;
                    setError499(data.errors[errorType][0])
                    setPopup499(true)
                    return;
                }
                setConnectedCloudDrives(data.length)
                setShowMessage(true);
            })
    }, [isCMDDone]);


    function clearFields() {
        var inputs = document.querySelectorAll('.field-wrapper input')
        for (var i = 0; i < inputs.length; i++) {
            inputs[i].value = '';
        }
    }

    const onChangeCloud = (event) => {
        setCloudSidebar(true);
        const name = event.target.name;
        const type = event.target.value;
        const value = event.target.getAttribute('data-name');
        const logo = event.target.getAttribute('data-logo');
        const text = event.target.getAttribute('data-text');
        const width = event.target.getAttribute('data-width');
        const isActive = true;
        document.querySelectorAll('.form-control').forEach(el => {
            el.classList.remove('active')
        });
        setShowError(false);
        clearFields();
        setCloudDetail([])
        setRadiaCheck(s => ({...s, name, logo, text, width, value, type, isActive}));
    }
    const [api, contextHolder] = notification.useNotification();
    const addCloudAccount = () => {
        let newArr = cloudDetail;
        var cloudName = newArr.name;
        console.log(cloudName)
        if (cloudName !== "" && cloudName !== undefined && cloudName !== null) {
            setLoading(true);
            setAgentPopupOpen(false)
            if (radioCheck.type == 128) {
                var redirectURL = config.callbackURL + "/dropbox";
                var cloudName = newArr.name;

                var dataToPost = {
                    "redirectURL": redirectURL,
                    "cloudName": cloudName
                }

                postDataWebNoResponse('/api/desktop/generate_dropbox_authorization_url', dataToPost)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setShowTourConnections(false)
                        window.location.href = data;
                    })


            } else if (radioCheck.type == 256) {
                var redirectURL = config.callbackURL + "/box";
                var cloudName = newArr.name;

                var dataToPost = {
                    "redirectURL": redirectURL,
                    "cloudName": cloudName
                }

                postDataWebNoResponse('/api/desktop/generate_box_authorization_url', dataToPost)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setShowTourConnections(false)
                        window.location.href = data;
                    })


            } else if (radioCheck.type == 512) {
                var redirectURL = config.callbackURL + "/onedrive";
                var cloudName = newArr.name;

                var dataToPost = {
                    "redirectURL": redirectURL,
                    "cloudName": cloudName
                }

                postDataWebNoResponse('/api/desktop/generate_onedrive_authorization_url', dataToPost)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setShowTourConnections(false)
                        window.location.href = data;
                    })


            } else if (radioCheck.type == 1024) {
                var redirectURL = config.callbackURL + "/googledrive";
                var cloudName = newArr.name;

                var dataToPost = {
                    "redirectURL": redirectURL,
                    "cloudName": cloudName
                }
                postDataWebNoResponse('/api/desktop/generate_googledrive_authorization_url', dataToPost)
                    .then((res) => Promise.all([res.status, res.text()]))
                    .then(([status, data]) => {
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var dataNew = JSON.parse(data)
                            var errorType = dataNew.type;
                            setError499(dataNew.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        setShowTourConnections(false)
                        window.location.href = data;
                    })
            } else {
                if (radioCheck.value === "S3") {
                    newArr.credentialsData = JSON.stringify({
                        "ssl": useSecureTransfer,
                        "signatureVersion": signatureVersion,
                        "addressingModel": addressingModel,
                        "localNetwork": localNetworkStorage,
                        "disableSslCertificateValidation": SSLCertificateValidation
                    })
                }

                postDataWebNoResponse('/api/desktop/insert', newArr)
                    .then((res) => Promise.all([res.status, res.json()]))
                    .then(([status, data]) => {
                        setLoading(false);
                        if (status === 401) {
                            logout()
                            return;
                        }
                        if (status === 500) {
                            setPopup500(true)
                            return;
                        }
                        if (status === 499) {
                            var errorType = data.type;
                            setError499(data.errors[errorType][0])
                            setPopup499(true)
                            return;
                        }
                        if (status === 400) {
                            setLoading(false);
                            setErrorMessage("Please enter valid credentials for each field.")
                            setShowError(true);
                        } else {
                            setShowTourConnections(false)
                            api.open({
                                message: cloudDetail.name + ' Cloud Account has been successfully connected to Amove.',
                                icon: <img src={toastIcon} alt="toastIcon"/>,
                                duration: 3,
                            });
                            sendDataToMixPanel('Cloud Account has been successfully connected to Amove.', user.username, {
                                'App': 'Web App',
                                'Drive Name': cloudDetail.name
                            })
                            clearFields();
                            setUpdateCloudAccounts(!updateCloudAccounts)
                            var ele = document.getElementsByName("cloud-brand");
                            for (var i = 0; i < ele.length; i++)
                                ele[i].checked = false;
                            const name = "";
                            const value = "";
                            const logo = "";
                            const text = "";
                            const type = "";
                            const isActive = false;
                            setRadiaCheck(s => ({...s, name, logo, text, value, type, isActive}));
                            if (hideBTNs) {
                                setHideBTNs(false)
                                localStorage.setItem("dashboardTourStep", 1)
                                setShowTourDashboard(true);
                                setCloudSidebar(false);
                                navigate("/?accounts=true&onboarding=true&lastid=" + data.id)

                            } else {
                                setCloudSidebar(false);
                                navigate("/?accounts=true&lastid=" + data.id)
                            }
                        }
                        setLoading(false);
                    })
            }
        }else {
            setLoading(false);
            setErrorMessage("Please enter valid credentials for each field.")
            setShowError(true);
        }
    };

    useEffect(() => {
        if (agentIsRunning) {
            navigate("/add-cloud-account");
        }
    }, [agentIsRunning]);

    function getCloudFields(cloudType) {
        cloudType = parseInt(cloudType);
        if (cloudType === 9998) {
            return (
                <SetupNFS type="NFS"/>
            )
        }
        if (cloudType === 9999) {
            return (
                <SetupNFS type="SMB"/>
            )
        }
        if (cloudType === 1) {
            return (
                <div className={"field-wrapper"}>
                    {/* ${ ? "help-circle" : null}*/}
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="accessKey" placeholder="Enter access key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="secret-key" className="form-control" onChange={inputEvent} name="secretKey" placeholder="Enter secret key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 2) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="accessKey" placeholder="Enter access key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="secret-key" className="form-control" onChange={inputEvent} name="secretKey" placeholder="Enter secret key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 4) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="accessKey" placeholder="Storage account name"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="secret-key" className="form-control" onChange={inputEvent} name="secretKey" placeholder="Enter account key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 8 || cloudType === 64) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="accessKey" placeholder="Enter access key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="secret-key" className="form-control" onChange={inputEvent} name="secretKey" placeholder="Enter secret key"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="serviceUrl" placeholder="URL(Generic S3 endpoint)"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 16) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <textarea id="access-key" className="form-control" onChange={inputEvent} name="credentialsData" placeholder="Service Accounts Key(JSON)"></textarea>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 32) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="credentialsData" placeholder="Enter Api Token"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 10) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="emailAddress" placeholder="Email"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="password" id="give-name" className="form-control" onChange={inputEvent} name="password" placeholder="Password"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 11) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="access-key" className="form-control" onChange={inputEvent} name="emailAddress" placeholder="Email"/>
                    </div>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="password" id="give-name" className="form-control" onChange={inputEvent} name="password" placeholder="Password"/>
                    </div>
                </div>
            )
        }
        if (cloudType === 128 || cloudType === 256 || cloudType === 512 || 1024) {
            return (
                <div className={"field-wrapper"}>
                    <div className={`form-group  ${showError ? "has-error" : ""}`}>
                        <input type="text" id="give-name" className="form-control" onChange={inputEvent} name="name" placeholder="Name your cloud account"/>
                    </div>
                </div>
            )
        }

    }


    const handleSearchChange = (e) => {
        setSearchQuery(e.target.value.toLowerCase());

        const nodeList = document.querySelectorAll("#brandNames li");
        for (let i = 0; i < nodeList.length; i++) {
            if (nodeList[i].getAttribute('data-title').toLowerCase().includes(e.target.value.toLowerCase())) {
                nodeList[i].classList.add("active");
            } else {
                nodeList[i].classList.remove("active");
            }
        }

    };
    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };


    return (
        <>
            {contextHolder}
            <div id="main-content" className={`${showTourConnections ? "tour-index" : ""}`}>
                {showTourConnections &&
                    <div className="tour-overlay"></div>
                }
                <div className="container-fluid">
                    <div className="main-content-body dashboard-home add-cloud-account-page">
                        <div className="cloud-brands-wrapper">
                            <div className={`left-content ${!radioCheck.isActive ? "brand-grid-5" : "brand-grid-4"}`}>
                                <div className={`drive-brands connection-drive-brands ${cloudAccounts.filter((item) => item.internalStorage).length < 1 && cloudAccountsDone ? "not-amove-storage" : ""}`}>
                                    <div className={`drive-brands-wrapper ${!showFreeStorage ? "remove-padding" : ""}`}>
                                        <div className="row">
                                            <div className="col-lg-5 col-md-12">

                                                {/* {showTourConnections &&
                                                    <div className="onboarding-back">
                                                        <Link to="/onboarding">
                                                            <i className="fa-solid fa-arrow-left"></i>
                                                            <span>Back</span>
                                                        </Link>
                                                    </div>
                                                }*/}
                                                {cloudCounts.length > 0 && (
                                                    <div className="connections-wrapper">
                                                        <h3>Connections</h3>
                                                        <ul className="accounts">
                                                            {cloudCounts.map((item, i) => (
                                                                <li key={i} className={` ${item.count > 0 ? "show" : ""}`}>{getIcon(item.cloudType, item.cloudType)}<span className={` ${item.count > 1 ? "show" : ""}`}>{item.count}</span></li>
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )}
                                            </div>
                                            {user.userType !== 32 && user.userType !== 64 &&
                                                <div className="col-lg-7 col-md-12">
                                                    {(showFreeStorage || hideBTNs) &&
                                                        <div className={`free-storage no-flex ${radioCheck.isActive ? "" : "not-selected"}`}>
                                                            {/*  <h3 className="sub-heading">Create a new Amove <br/>
                                                                Storage region.</h3>

                                                            <Select
                                                                className={` ${regions !== '' && regions !== null ? "selected" : ''}`}
                                                                showSearch
                                                                optionFilterProp="children"
                                                                onChange={onChangeRegoin}
                                                                placeholder="Select a Region"
                                                                value={selectedRegions}
                                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                                options={regions.map((item) => ({
                                                                    label: item.name, value: item.code,
                                                                }))}
                                                            />
                                                            <a href="#" className={`brand-icon ${selectedRegions === 'Select a Region' || loadingAmove ? 'disabled' : ''}`} onClick={(e) => {
                                                                e.preventDefault();
                                                                setUpNewIDrive()
                                                            }}>
                                                                {loadingAmove &&
                                                                    <Spin indicator={<LoadingOutlined style={{fontSize: 22}} spin/>}/>
                                                                }
                                                                <img src={!isDark ? pageLogoWhite : pageLogo} alt="logo"/></a>

                                                            <span className="red-error fix-2-lines-desktop" title={errorText}>{errorText}</span>*/}
                                                            <ProvisionAmoveStorage connectionPage={true}/>
                                                        </div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                        {/* Connections tour first step  showTourConnections*/}
                                        <div className={`brands-header ${showTourConnections ? "highlighted-tour" : ""}`}>
                                            {/*<div className="brands-header">*/}
                                            <div className="content-wrapper">
                                                <h3 className='sub-heading'>Connect your existing Cloud or <br className="mobile-visible"/> Local Storage</h3>
                                                {/* Show this p tag when brands-header have class highlighted-tour */}
                                                {showTourConnections &&
                                                    <p>Choose a provider to get started</p>
                                                }
                                            </div>
                                            {/* Hide this form tag when brands-header have class highlighted-tour */}
                                            {!showTourConnections &&
                                                <div className="form">
                                                    <div className="form-group">
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Search Cloud Provider"
                                                            value={searchQuery}
                                                            onChange={handleSearchChange}
                                                        />
                                                        <a href="#" className="icon" onClick={(e) => e.preventDefault()}>
                                                            <img src={searchIcon} alt="icon"/>
                                                        </a>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className={`brands-main-wrapper`}>
                                            {/*<ul className="brands highlighted-tour" id="brandNames">*/}
                                            <ul className="brands  highlighted-tour" id="brandNames">
                                                {config.environment === "Dev" &&
                                                    <>
                                                        {/*
                                                    <li>
                                                        <input type="radio" id="boxLogo" data-logo={box} data-width={50} data-name="Box" name="cloud-brand" onChange={onChangeCloud} value="10"/>
                                                        <label htmlFor="boxLogo">
                                                            <img src={box} alt="logo" style={{maxWidth: '50px'}}/>
                                                        </label>
                                                    </li>
                                                    <li>
                                                        <input type="radio" id="googleDriveLogo" data-logo={googleDrive} data-width={150} data-name="Google Drive" name="cloud-brand" onChange={onChangeCloud} value="10"/>
                                                        <label htmlFor="googleDriveLogo">
                                                            <img src={googleDrive} alt="logo" style={{maxWidth: '150px'}}/>
                                                        </label>
                                                    </li>
                                            <li>
                                                <input type="radio" id="ibm" data-logo={ibm} data-width={140} data-name="IBM Cloud" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                <label htmlFor="ibm">
                                                    <img src={ibm} alt="logo" className="ibm" style={{maxWidth: '140px'}}/>
                                                </label>
                                            </li>*/}
                                                    </>
                                                }
                                                {/*    {cloudAccounts.filter((item) => item.internalStorage).length < 1 &&
                                                <li>
                                                    <input type="radio" id="googleDriveLogo" data-logo={googleDrive} data-width={150} data-name="Google Drive" name="cloud-brand" onChange={onChangeCloud} value="10"/>
                                                    <label htmlFor="googleDriveLogo">
                                                        <img src={pageLogo} alt="logo" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>
                                            }*/}
                                                <li data-title="Dropbox" className="active">
                                                    <input type="radio" id="dropBoxLogo" data-logo={dropBox} data-width={130} data-name="Dropbox" name="cloud-brand" onChange={onChangeCloud} value="128"/>
                                                    <label htmlFor="dropBoxLogo">
                                                        <img src={dropBox} alt="logo" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>


                                                <li data-title="AWS" className="active">
                                                    <input type="radio" id="awsLogo" data-logo={awsLogo} data-width={50} data-name="AWS" name="cloud-brand" onChange={onChangeCloud} value="1"/>
                                                    <label htmlFor="awsLogo">
                                                        <img src={awsLogo} alt="logo" style={{maxWidth: '50px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Azure" className="active">
                                                    <input type="radio" id="azureLogo" data-logo={azureLogo} data-width={140} data-name="Azure" name="cloud-brand" onChange={onChangeCloud} value="4"/>
                                                    <label htmlFor="azureLogo">
                                                        <img src={azureLogo} alt="logo" style={{maxWidth: '140px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Google Cloud Provider" className="active">
                                                    <input type="radio" id="googleCloudLogo" data-logo={googleCloudLogo} data-width={144} data-name="GCP" name="cloud-brand" onChange={onChangeCloud} value="16"/>
                                                    <label htmlFor="googleCloudLogo">
                                                        <img src={googleCloudLogo} alt="logo" style={{maxWidth: '144px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Wasabi" className="active">
                                                    <input type="radio" id="wasabiCloudLogo" data-logo={wasabiCloudLogo} data-width={120} data-name="Wasabi" name="cloud-brand" onChange={onChangeCloud} value="2"/>
                                                    <label htmlFor="wasabiCloudLogo">
                                                        <img src={wasabiCloudLogo} alt="logo" style={{maxWidth: '120px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Storj" className="active">
                                                    <input type="radio" id="storjLogo" data-logo={storjLogo} data-width={126} data-name="Storj" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="storjLogo">
                                                        <img src={storjLogo} alt="logo" className="storjLogo" style={{maxWidth: '126px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Backblaze" className="active">
                                                    <input type="radio" id="Backblaze" data-logo={Backblaze} data-width={130} data-name="Backblaze" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="Backblaze">
                                                        <img src={Backblaze} alt="logo" className="Backblaze" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="IDrive" className="active">
                                                    <input type="radio" id="idrive" data-logo={idrive} data-width={120} data-name="IDrive" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="idrive">
                                                        <img src={idrive} alt="logo" className="idrive" style={{maxWidth: '120px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="S3" className="active">
                                                    <input type="radio" id="s3Logo" data-logo={s3Logo} data-width={120} data-name="S3" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="s3Logo">
                                                        <img src={s3Logo} alt="logo" className="s3logo" style={{maxWidth: '120px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Perifery" className="active">
                                                    <input type="radio" id="Perifery" data-logo={perifery} data-width={130} data-name="Perifery" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="Perifery">
                                                        <img src={perifery} alt="logo" className="perifery" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>

                                                <li data-title="DigitalOcean" className="active">
                                                    <input type="radio" id="DigitalOcean" data-logo={DigitalOcean} data-width={161} data-name="DO" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="DigitalOcean">
                                                        <img src={DigitalOcean} alt="logo" className="DigitalOcean" style={{maxWidth: '161px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Seagate" className="active">
                                                    <input type="radio" id="seagateLogo" data-logo={seagateLogo} data-width={130} data-name="Seagate" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="seagateLogo">
                                                        <img src={seagateLogo} alt="logo" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="Amove" className="active">
                                                    <input type="radio" id="amoveLogo" data-logo={pageLogo} data-width={130} data-name="Amove" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="amoveLogo">
                                                        <img src={pageLogo} alt="logo" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>

                                                {/*
                                                <li data-title="Cloudflare" className="active">
                                                    <input type="radio" id="Cloudflare" data-logo={Cloudflare} data-width={130} data-name="Cloudflare" name="cloud-brand" onChange={onChangeCloud} value="64"/>
                                                    <label htmlFor="Cloudflare">
                                                        <img src={Cloudflare} alt="logo" className="Cloudflare" style={{maxWidth: '130px'}}/>
                                                    </label>
                                                </li>*/}
                                                <li data-title="Symply" className="active">
                                                    <input type="radio" id="symplyLogo" data-logo={symply} data-width={140} data-name="Symply" name="cloud-brand" onChange={onChangeCloud} value="8"/>
                                                    <label htmlFor="symplyLogo">
                                                        <img src={symply} alt="logo" className="symplyLogo" style={{maxWidth: '140px'}}/>
                                                    </label>
                                                </li>
                                                <li data-title="NFS Connect" className="active">
                                                    <input type="radio" id="NFS" data-text="NFS Connect" data-logo={NFSNewLogo} data-width={40} data-name="NFS" name="cloud-brand" onChange={onChangeCloud} value="9998"/>
                                                    <label htmlFor="NFS" className="text-logo">
                                                        <img src={NFSNewLogo} alt="logo" style={{maxWidth: '40px'}}/>
                                                        <h3>NFS Connect</h3>
                                                    </label>
                                                </li>
                                                <li data-title="SMB Connect" className="active">
                                                    <input type="radio" id="SMB" data-text="SMB Connect" data-logo={SMBLogo} data-width={40} data-name="SMB" name="cloud-brand" onChange={onChangeCloud} value="9999"/>
                                                    <label htmlFor="SMB" className="text-logo">
                                                        <img src={SMBLogo} alt="logo" style={{maxWidth: '40px'}}/>
                                                        <h3>SMB Connect</h3>
                                                    </label>
                                                </li>

                                                <li data-title="Box" className="active">
                                                    <input type="radio" id="boxLogo" data-logo={box} data-width={50} data-name="Box" name="cloud-brand" onChange={onChangeCloud} value="256"/>
                                                    <label htmlFor="boxLogo" className="text-logo">
                                                        <img src={box} alt="logo" style={{maxWidth: '50px'}}/>{/*<p className="tag">COMING SOON</p>*/}
                                                    </label>
                                                </li>
                                                <li data-title="Google Drive" className="active">
                                                    <input type="radio" id="googleDriveLogo" data-logo={googleDrive} data-width={140} data-name="Google Drive" name="cloud-brand" onChange={onChangeCloud} value="1024"/>
                                                    <label htmlFor="googleDriveLogo" className="text-logo">
                                                        <img src={googleDrive} alt="logo" style={{maxWidth: '140px'}}/>{/*<p className="tag">COMING SOON</p>*/}
                                                    </label>
                                                </li>
                                                <li data-title="One Drive" className="active">
                                                    <input type="radio" id="oneDriveLogo" data-logo={oneDrive} data-width={130} data-name="One Drive" name="cloud-brand" onChange={onChangeCloud} value="512"/>
                                                    <label htmlFor="oneDriveLogo" className="text-logo">
                                                        <img src={oneDrive} alt="logo" style={{maxWidth: '130px'}}/>{/*<p className="tag">COMING SOON</p>*/}
                                                    </label>
                                                </li>
                                                <li data-title="FarmeIO" className="active">
                                                    <input type="radio" id="farmeIOIcon" data-logo={farmeIOIcon} data-width={120} data-name="farmeIO" name="cloud-brand" onChange={onChangeCloud} value="32"/>
                                                    <label htmlFor="farmeIOIcon" className="text-logo">
                                                        <img src={farmeIOIcon} alt="logo" style={{maxWidth: '120px'}}/>
                                                        {/*<p className="tag">COMING SOON</p>*/}
                                                    </label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={`clouds-accounts-overlay ${cloudSidebar ? "sidebar-active" : ""}`} onClick={(e) => {
                                e.preventDefault();
                                setCloudSidebar(false);
                            }}></div>
                            <div className={`right-content ${radioCheck.isActive ? "" : "no-connection"} ${cloudSidebar ? "sidebar-active" : ""}`}>
                                {/* Tour start */}
                                <div className="right-header">
                                    <div className="row">
                                        <div className="col-9">
                                            <h3>Connection</h3>
                                        </div>
                                        <div className="col-3">
                                            <div className="close-icon">
                                                <a href="#" onClick={(e) => {
                                                    e.preventDefault();
                                                    setCloudSidebar(false);
                                                }}><img src={crossBalckIcon} alt="icon"/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="cloud-right-bar tour-start">
                                    <div className={`no-data ${radioCheck.isActive ? "" : "active"}`}>
                                        <img src={`${noData}`} alt="No Data"/>
                                        <p>Select a cloud provider.</p>
                                    </div>
                                    <div className={`selected-brand ${radioCheck.isActive ? "active" : ""}`}>
                                        <div className="brand-body">
                                            <div className="brand-header-image">
                                                <div className="wrapper">
                                                    <img src={radioCheck.logo} alt="Selected Brand" style={{maxWidth: radioCheck.width + 'px'}} className={`cloud-icon-${radioCheck.width}`}/>
                                                    {(radioCheck.text !== "NFS CONNECT" || radioCheck.text !== "SMB CONNECT") &&
                                                        <h3>{radioCheck.text}</h3>
                                                    }
                                                </div>
                                                <div className="tour-tooltip tour-connection-step-1">
                                                    <div className="tour-tooltip-wrapper">
                                                        {/*<a href="#" className="cross">
                                                            <img src={crossBalckIcon} alt="icon"/>
                                                        </a>*/}
                                                        <h3>Connect to your existing cloud account</h3>
                                                        <ul className="steps full">
                                                            <li className="active"><span className="dot"></span></li>
                                                            <li><span className="dot"></span></li>
                                                        </ul>
                                                        <p className="p">Name your cloud account</p>
                                                        <p>Please name your cloud account and then click
                                                            "Connect" to grant access and permissions</p>
                                                        <div className="btn-wrapper">
                                                            <a href="#" className="btn-tour transparent">Previous</a>
                                                            {/*<a href="#" className="btn-tour">Next</a>*/}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <h3>Enter your {radioCheck.value} credentials</h3>
                                            <div className="form" id="add-cloud-fields">
                                                {getCloudFields(radioCheck.type)}

                                                {radioCheck.value === "S3" &&
                                                    <>
                                                        <div className="advance-settings-wrapper-new text-start">
                                                            {!advanceSettings &&
                                                                <a href="#" onClick={(e) => {
                                                                    e.preventDefault()
                                                                    setAdvanceSettings(true)
                                                                }}
                                                                >Advanced Settings</a>
                                                            }

                                                            {advanceSettings &&
                                                                <>
                                                                    <div className={`form-group info-wrapper`}>
                                                                        <Checkbox onChange={(value) => {
                                                                            setUseSecureTransfer(value.target.checked)
                                                                        }} checked={useSecureTransfer}>Use Secure Transfer <br/>(SSL/TLS)<Tooltip
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            placement="topRight" title="Enable secure data transfer using SSL/TLS."
                                                                        >
                                                                            <img src={info} alt="Info"/>
                                                                        </Tooltip></Checkbox>
                                                                    </div>

                                                                    <div className={`form-group`}>
                                                                        <label className={`info-wrapper`}>Signature Version<Tooltip
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            placement="topRight" title="Select the signature version to use for request authentication"
                                                                        >
                                                                            <img src={info} alt="Info"/>
                                                                        </Tooltip></label>
                                                                        <Select
                                                                            value={signatureVersion}
                                                                            onChange={(value) => {
                                                                                setSignatureVersion((value))
                                                                            }}
                                                                            options={[
                                                                                {
                                                                                    value: 0,
                                                                                    label: 'Signature V2',
                                                                                },
                                                                                {
                                                                                    value: 1,
                                                                                    label: 'Signature V4',
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </div>

                                                                    <div className={`form-group`}>
                                                                        <label className={`info-wrapper`}>Addressing Model<Tooltip
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            placement="topRight" title="Choose the addressing model for accessing the bucket"
                                                                        >
                                                                            <img src={info} alt="Info"/>
                                                                        </Tooltip></label>

                                                                        <Select
                                                                            value={addressingModel}
                                                                            onChange={(value) => {
                                                                                setAddressingModel((value))
                                                                            }}
                                                                            options={[
                                                                                {
                                                                                    value: 0,
                                                                                    label: 'Path Style ',
                                                                                },
                                                                                {
                                                                                    value: 1,
                                                                                    label: 'Virtual Hosted Style',
                                                                                }
                                                                            ]}
                                                                        />
                                                                    </div>

                                                                    <div className={`form-group info-wrapper`}>
                                                                        <Checkbox onChange={(value) => {
                                                                            setLocalNetworkStorage(value.target.checked)
                                                                        }} checked={localNetworkStorage}>Local Network Storage<Tooltip
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            placement="topRight" title="Connect to storage on the local network."
                                                                        >
                                                                            <img src={info} alt="Info"/>
                                                                        </Tooltip></Checkbox>
                                                                    </div>
                                                                    <div className={`form-group info-wrapper`}>
                                                                        <Checkbox onChange={(value) => {
                                                                            setSSLCertificateValidation(value.target.checked)
                                                                        }} checked={SSLCertificateValidation}>Disable SSL Certificate <br/>Validation<Tooltip
                                                                            getPopupContainer={trigger => trigger.parentNode}
                                                                            placement="topRight" title="Disable SSL certificate validation for the connection"
                                                                        >
                                                                            <img src={info} alt="Info"/>
                                                                        </Tooltip></Checkbox>
                                                                    </div>
                                                                </>
                                                            }

                                                        </div>
                                                    </>
                                                }

                                                {showError &&
                                                    <div className="form-group">
                                                        <span className="red-error">{errorMessage}</span>
                                                    </div>
                                                }
                                                {(radioCheck.text !== "NFS Connect" && radioCheck.text !== "SMB Connect") &&
                                                    <>
                                                        <div className="form-group submit">
                                                            <Button className="btn-style small btn-30" onClick={addCloudAccount} loading={loading}>Connect</Button>
                                                        </div>
                                                        <div className={`tour-tooltip tour-connection-step-2 ${showTourConnections || showOnlyToolTip ? "active" : ""}`}>
                                                            <div className="tour-tooltip-wrapper">
                                                                <a href="#" className="cross" onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setShowTourConnections(false)
                                                                    setShowOnlyToolTip(false)
                                                                    localStorage.setItem("showOnlyToolTip", "false")
                                                                }}>
                                                                    <img src={crossBalckIcon} alt="icon"/>
                                                                </a>
                                                                <h3>Connect to your existing cloud account</h3>
                                                                <ul className="steps full">
                                                                    <li className="active"><span className="dot"></span></li>
                                                                    <li className="active"><span className="dot"></span></li>
                                                                </ul>
                                                                <p className="p">Enter your {radioCheck.value} credentials</p>
                                                            </div>
                                                        </div>
                                                    </>
                                                }

                                                {(radioCheck.text === "NFS Connect" || radioCheck.text === "SMB Connect") &&
                                                    <div className={`tour-tooltip tour-connection-step-2 ${showTourConnections || showOnlyToolTip ? "active" : ""}`}>
                                                        <div className="tour-tooltip-wrapper">
                                                            <a href="#" className="cross" onClick={(e) => {
                                                                e.preventDefault();
                                                                setShowTourConnections(false)
                                                                setShowOnlyToolTip(false)
                                                                localStorage.setItem("showOnlyToolTip", "false")
                                                            }}>
                                                                <img src={crossBalckIcon} alt="icon"/>
                                                            </a>
                                                            <h3>Connect to your existing local storage</h3>
                                                            <ul className="steps full">
                                                                <li className="active"><span className="dot"></span></li>
                                                                <li className="active"><span className="dot"></span></li>
                                                            </ul>
                                                            <p className="p">Enter your {radioCheck.value} credentials</p>
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal title="Assign Projects" className="manual-user-type create-storage-popup" centered open={isCreateStorage} onOk={() => {
                setIsCreateStorage(false);
            }} onCancel={() => {
                setIsCreateStorage(false);
            }}>
                <div className="manual-user-modal-wrapper">
                    <div className="create-team">
                        <AmoveStorage popup={true}/>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default AddCloudAccount;

