import React, {useState, useRef, useEffect} from "react";
import {useAuth} from "../context/AuthContext";
import {Button, Modal, notification, Select, Spin, Switch, Tooltip} from "antd";
import logoIcon from "../assets/images/icons/logo-icon.svg";
import crossIcon from "../assets/images/icons/cross-black-icon.svg";
import {LoadingOutlined} from "@ant-design/icons";
import pageLogo from "../assets/images/logo.svg";
import pageLogoWhite from "../assets/images/logo-dark.svg";
import {useNavigate} from "react-router-dom";
import toastIcon from "../assets/images/icons/toast-icon.svg";

const {Option} = Select;
const ProvisionAmoveStorage = ({connectionPage = false}) => {
    const {
        isDark,
        regions,
        getRegions,
        getDataWithTokenPOST,
        setEndpoint,
        setShowPopupSupport,
        setPopup500,
        setError499,
        setPopup499,
        sendDataToMixPanel,
        user,
        getCloudAccounts,
        cloudAccounts
    } = useAuth();
    const navigate = useNavigate();
    const dataFetchedRef = useRef(false);
    const [api, contextHolder] = notification.useNotification();
    const [regionsFilter, setRegionsFilter] = useState([]);
    const [storageFilter, setStorageFilter] = useState([]);
    const [amoveStorage, setAmoveStorage] = useState([]);
    const [amoveStorageFiltered, setAmoveStorageFiltered] = useState([]);
    const [selectedRegionsFilter, setSelectedRegionsFilter] = useState("All Regions");
    const [selectedStorageFilter, setSelectedStorageFilter] = useState("All Storages");
    const [selectedRegions, setSelectedRegions] = useState();
    const [selectedProvision, setSelectedProvision] = useState();
    const [createProvision, setCreateProvision] = useState(false);
    const [createRegion, setCreateRegion] = useState(false);
    const [totalBuckets, setTotalBuckets] = useState(0);
    const [totalSize, setTotalSize] = useState(0);
    const [deleteRegion, setDeleteRegion] = useState(false);
    const [regionToDelete, setRegionToDelete] = useState({});
    const [loadingAmove, setLoadingAmove] = useState(false);
    const [showRegion, setShowRegion] = useState(false);
    const [showPageData, setShowPageData] = useState(false);
    const [iDriveUsage, setIDriveUsage] = useState(0);
    const [toggleVersioning, setToggleVersioning] = useState(false);
    const [toggleDefaultEncryption, setToggleDefaultEncryption] = useState(false);
    const [toggleObjectLocking, setToggleObjectLocking] = useState(false);
    const [newBucketName, setNewBucketName] = useState("");
    const [showBucketNameError, setBucketNameError] = useState(false);
    const [selectedBuckets, setSelectedBuckets] = useState([]);
    const [loading, setLoading] = useState(false);
    const [errorText, setErrorText] = useState('');
    const validBucketName = (bucket) => {
        var regularExp = /^(?!^(\d{1,3}\.){3}\d{1,3}$)(^[a-z0-9]([a-z0-9\-]*[a-z0-9])?(?:\.[a-z0-9]([a-z0-9\-]*[a-z0-9])?)*$)/;
        return regularExp.test(bucket);
    }

    const manageProvisionCancel = () => {
        setCreateProvision(false)
    };
    const onChangeProvisions = (value) => {
        if(regions.length === 0){
            getRegions()
        }
        setSelectedProvision(value);
        setSelectedRegions();
        setToggleVersioning(false)
        setToggleDefaultEncryption(false)
        setToggleObjectLocking(false)
        console.log(value)
    };

    const onChangeRegoin = (value) => {
        setSelectedRegions(value);
        console.log(value)
    };
    const setUpNewIDrive = () => {

        var dataToPost =
            {
                "firstname": "",
                "userEmail": "",
                "cloudAccountName": "Amove",
                "bucketTitle": "amovebucket",
                "region": selectedRegions,
                "defaultPassword": "",
                "shared": false,
                "storageTier": parseInt(selectedProvision)
            }
        setLoading(true)
        getDataWithTokenPOST("/api/cloudaccount/idrive_add_storage", dataToPost)
            .then((res) => Promise.all([res.status, res.json()]))
            .then(([status, response]) => {
                if (status === 401) {
                    setEndpoint("/api/cloudaccount/idrive_add_storage")
                    setShowPopupSupport(true)
                    // logout()
                    setLoading(false);
                    return;
                }
                if (status === 500) {
                    setPopup500(true);
                    setLoading(false);
                    return;
                }
                if (status === 499) {
                    var errorType = response.type;
                    setError499(response.errors[errorType][0])
                    setPopup499(true)
                    setLoading(false);
                    return;
                }
                if (status === 200) {
                    api.open({
                        message: `New Amove Storage Created.`,
                        icon: <img src={toastIcon} alt="toastIcon"/>,
                        duration: 5
                    });

                    sendDataToMixPanel('New Amove Storage Created.', user.username, {
                        'App': 'Web App'
                    })
                    setLoading(false);
                    setSelectedProvision();
                    setSelectedRegions();
                    getCloudAccounts(false, false, false)
                }
            })

    };
    return (
        <>
            {contextHolder}

            {(cloudAccounts.filter(account => account.storageTier === 1).length < 1 || cloudAccounts.filter(account => account.storageTier === 2).length < 1 ||
                    cloudAccounts.filter(account => account.storageTier === 4).length < 1) &&
                <div className={`enable-region-wrapper mb-5 ${!connectionPage ? "enable-region-provision " : ""}`}>
                    <div className="new-region-wrapper long-select">
                        <h3>{!connectionPage ? "Provision " : ""} Amove Storage</h3>
                        <Select
                            placeholder="Select a Tier"
                            className="user-select-role"
                            onChange={onChangeProvisions}
                            value={selectedProvision}
                            getPopupContainer={trigger => trigger.parentNode}
                        >
                            {cloudAccounts.filter(account => account.storageTier === 1).length < 1 &&
                                <Option value="1" disabled={true} title="Coming Soon">Perform</Option>
                            }
                            {cloudAccounts.filter(account => account.storageTier === 2).length < 1 &&
                                <Option value="2">Global</Option>
                            }
                            {cloudAccounts.filter(account => account.storageTier === 4).length < 1 &&
                                <Option value="4">Scale</Option>
                            }
                        </Select>
                            <Select
                                className={` ${regions !== '' && regions !== null ? "selected" : ''} ${selectedProvision === '' || selectedProvision === undefined ? "disabled" : ''}`}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select a Region"
                                onChange={onChangeRegoin}
                                value={selectedRegions}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={regions.map((item) => ({
                                    label: item.name, value: item.code,
                                }))}
                            />

                        {/*.filter((item) => {
                                    if (selectedProvision === "4" && item.name === "Virginia") {
                                        return item
                                    } else if (selectedProvision !== "4") {
                                        return item
                                    }
                                })*/}


                      {/*  {selectedProvision === "4" &&
                            <Select
                                className={`no-dropdown ${regions !== '' && regions !== null ? "selected" : ''} ${selectedProvision === '' || selectedProvision === undefined ? "disabled" : ''}`}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select a Region"
                                onChange={onChangeRegoin}
                                value={selectedRegions}
                                defaultValue={"VA"}
                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                options={[{
                                    label: "Default", value: "VA",
                                }]}
                            />
                        }*/}
                        <a href="#" className={`brand-icon force-orange-color ${selectedProvision === undefined ? 'disabled' : ''}`}
                           onClick={(e) => {
                               e.preventDefault();

                               setCreateProvision(true)
                               setUpNewIDrive()
                           }}>
                            {loading &&
                                <Spin indicator={<LoadingOutlined style={{fontSize: 22}} spin/>}/>
                            }
                            {/*<img src={isDark ? pageLogo : pageLogoWhite} alt="logo"/>*/}
                            Provision
                        </a>
                    </div>
                </div>
            }
        </>
    )
}
export default ProvisionAmoveStorage;
